import React from 'react';

import Button from '../Button';
import Select, {
  SelectButtonWrapper,
  SelectClearButton,
  DefaultSelectButtonProps,
  SelectProps,
} from '../Select';
import Icon from '../Icon';
import Input from '../Input';

import cls from './search-select.module.scss';

// these two should be moved into separate files, keeping here for demo
const DefaultSelectButton = ({
  handleToggle,
  handleClose,
  isOpen,
  handleOpen,
  children,
  inputSearchProps,
  disabled,
  clearable,
  activeOption,
  handleOptionSelect,
  setOpenState,
  ...rest
}: DefaultSelectButtonProps & {
  inputSearchProps: JSX.IntrinsicElements['input'];
}) => {
  const handleSearchInputFocus = () => {
    handleOpen();
  };

  return (
    <SelectButtonWrapper>
      <SelectClearButton
        {...{
          clearable,
          activeOption,
          disabled,
          handleOptionSelect,
        }}
      />
      <Button
        className={cls.selectButton}
        disabled={disabled}
        onClick={handleToggle}
        // transparent
        {...rest}
      >
        <div className={cls.value}>{children}</div>
        <div className={cls.buttonIcon}>
          <Icon prefix={'far'} name={'search'}/>
        </div>
      </Button>
      <div>
        <input
          className={cls.searchInput}
          disabled={disabled}
          type="text"
          autoComplete="nope"
          onFocus={handleSearchInputFocus}
          {...inputSearchProps}
        />
      </div>
    </SelectButtonWrapper>
  );
};

DefaultSelectButton.defaultProps = {
  children: 'Search',
  inputSearchProps: {},
};

type SearchProps = {
  inputSearchProps: JSX.IntrinsicElements['input'];
} & SelectProps & {disabled?: boolean};

export function SearchSelect(props: SearchProps) {
  const { inputSearchProps, buttonProps, ...rest } = props;

  return (
    <Select
      ButtonComponent={DefaultSelectButton}
      buttonProps={{ ...buttonProps, inputSearchProps }}
      {...rest}
    />
  );
}

SearchSelect.defaultProps = {
  inputSearchProps: {},
  activeOption: null,
};

export default SearchSelect;
