/* eslint-disable max-len */
import * as React from "react";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";

// import { NavLink } from "@duik/it";
import {
  Button,
  ContainerHorizontal,
  ContainerVertical,
  TopBar,
} from "components";
// import TopBar from "../components/TopBar";
import ContentPanel from "../components/ContentPanel";
import { FellowsList } from "./FellowsList";
import NewFellow from "./NewFellow";
import EditFellow from "./EditFellow";
// import rootRouteMap from "./routeMap";
let data = [
  {
    id: 1,
    name: "user1",
    icon: "icon1",
    createdAt: "11111",
    updatedAt: "11111",
  },
  {
    id: 2,
    name: "user2",
    icon: "icon2",
    createdAt: "11112",
    updatedAt: "11112",
  },
  {
    id: 3,
    name: "user3",
    icon: "icon3",
    createdAt: "11113",
    updatedAt: "11113",
  },
];
const PageUsers = (props) => {
  const {
    match,
    match: { path },
  } = props;
  return (
    <ContainerHorizontal>
      <ContentPanel>
        <ContainerVertical>
          <ContainerHorizontal>
            <Switch>
              <Route
                render={(props) => <FellowsList data={data} {...props} />}
                path={`${path}/list`}
              />
              <Route render={() => <NewFellow />} path={`${path}/create-new`} />
              <Route
                render={(props) => <EditFellow {...props} />}
                path={`${path}/edit/:fellowId`}
              />

              {/* <Redirect to={`${path}/list`} /> */}
            </Switch>
          </ContainerHorizontal>
        </ContainerVertical>
      </ContentPanel>
    </ContainerHorizontal>
  );
};

export default PageUsers;
