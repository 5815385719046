import * as React from 'react';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authActions from '@redux/auth/actions';
import cls from './stryker-sso.module.scss';

const SSORoutes = (props) => {
	const { match, location } = props;
	const search = location.search;
	//Get params
	const [ error, setError ] = React.useState(false);
	const [ authenticating, setAuthenticating ] = React.useState(false);
	const parsed = qs.parse(search);
	const jwt = parsed.jwt;
	if (!jwt) {
		return <Redirect to={'/auth'} />;
	}
	if (!authenticating) {
		setAuthenticating(true);
		props
			.ssoAuthentication(jwt)
			.then((r) => {
				if (r.value.user) {
					window.location.replace('/auth');
				}
			})
			.catch((err) => {
				setError(true);
			});
	}
	return !error ? (
		<div className={cls.ssopage}>
			<h1>AUTHENTICATING...</h1>
			<div className={cls.wrapper}>
				<div className={cls.base}>
					<div className={cls.baseBottom} />
					<div className={cls.lockInsideTop} />
					<div className={cls.lockInsideBottom} />
				</div>
				<div className={cls.lockCircle}>
					<div className={cls.lockCircleInside} />
				</div>
				<div className={cls.lockBox} />
			</div>
		</div>
	) : (
		<div>
			<h1>AUTHENTICATION FAILED</h1>
		</div>
	);
};

export default connect(null, {
	ssoAuthentication: authActions.ssoAuthentication
})(SSORoutes);
