import createCreateInArrayReducer from '../@reducers/createCreateInArrayReducer';
import createGetWithPaginationReducer from '../@reducers/createGetWithPaginationReducer';
import composeReducers from '../@reducers/composeReducers';
import enhanceReducerWithWriteLocalStorage from '../enhanceReducerWithWriteLocalStorage';
import createFlushReducer from '@redux/@reducers/createFlushReducer';

import initialState from './initialState';
import { Snippet, SnippetsState } from './types';
import createUpdateInArrayReducer from '@redux/@reducers/createUpdateInArrayReducer';
import createDeleteInArrayReducer from '@redux/@reducers/createDeleteInArrayReducer';

const NAME = '@snippets';

/* Reducers */
const flushReducer = createFlushReducer(NAME, []);
const updateReducer = createUpdateInArrayReducer<Snippet>(
  NAME,
  initialState,
);
const createReducer = createCreateInArrayReducer<Snippet>(
  NAME,
  initialState,
);
const deleteReducer = createDeleteInArrayReducer<Snippet>(
  NAME,
  initialState,
);
const getReducer = createGetWithPaginationReducer<Snippet>(
  NAME,
  initialState,
);

const snippetsReducer = composeReducers<SnippetsState>(initialState)(
  getReducer,
  flushReducer,
  updateReducer,
  createReducer,
  deleteReducer,
);

export default enhanceReducerWithWriteLocalStorage(NAME)(snippetsReducer);
