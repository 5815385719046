import * as React from "react";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIsOpenControls } from "hooks";
import { Table as DTable } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import "antd/dist/antd.css";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Widget, WidgetContainer, Toggle, Alert, Avatar } from "@duik/it";
import { strToDate, dateFormat } from "helpers/dateFormat";
import { useRedux, useReduxLoading } from "@redux";
import { getUsers, updateUser, deleteUser } from "@redux/users/actions";
import {
  Table,
  Column,
  Button,
  TopBar,
  ContentLoader,
  ScrollArea,
  ContainerHorizontal,
  ContainerVertical,
  FormInputGroup,
  Select,
  Input,
  Icon,
  AlertBox,
} from "components";
import { ColumnsType } from "antd/es/table";
import { USER_ROLE } from "types/general";
import { User, UserInput } from "@redux/users/types";
import ModalAddTerritory from "../components/ModalAddTerritory";
import ButtonDeleteUser from "../components/ButtonDeleteUser";
import PageTitle from "../components/PageTitle";
import cls from "./users-list.module.scss";
import auth from "@/@redux/auth";
// import { columns as dcolumns } from "./columns";

type userTableType = User & {
  key?: string | number;
  company_name?: string;
};

let pagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
};
let checkType: any = "checkbox";
const rowSelection = {
  type: checkType,
  onChange: (selectedRowKeys, selectedRows) => {},
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};
export const UsersList = (props) => {
  const modalAddControls = useIsOpenControls();
  const [currIdx, setCurrIdx] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchTxt, setSearchTxt] = React.useState("");
  const [searchOption, setSearchOption] = React.useState("");

  const authState = useRedux("auth");
  const usersState = useRedux("users");
  // const [users, setUsers] = React.useState(userState.data);
  const territory_options = [
    {
      value: 0,
      label: "Southen California",
    },
    {
      value: 1,
      label: "Nothern California",
    },
    {
      value: 2,
      label: "Easten California",
    },
    {
      value: 3,
      label: "Western California",
    },
  ];
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUsers());
  }, []);

  // React.useEffect(()=>{
  //   if(userState.data.length>0){
  //     setUsers()
  //   }
  // }, [userState.data])

  if (useReduxLoading("users")) {
    return <ContentLoader>Fetching Users</ContentLoader>;
  }

  const onClickDelete = async (id) => {
    await dispatch(deleteUser(id));
  };

  const { path } = props.match;
  let tmpPath: any = path.split("/");
  tmpPath.pop();
  let newPath = "";
  tmpPath.map((elem) => {
    newPath += elem + "/";
  });
  const dcolumns: ColumnsType<userTableType> = [
    {
      key: "fullname",
      title: "NAME",
      dataIndex: "fullname",
      // width: 80,
      render: (_: any, elem: userTableType, index: number) => {
        return (
          <>
            <Avatar
              className={cls.fullname}
              avatarPlaceholder={{
                color: "indigo",
                content: `${elem.first_name[0]} ${elem.last_name[0]}`,
              }}
              name={
                <strong>
                  {elem.first_name} {elem.last_name}
                </strong>
              }
              sm
            />
          </>
        );
      },
      sorter: (a, b) => {
        a.first_name = a.first_name ? a.first_name : "";
        return a.first_name.localeCompare(b.first_name || "");
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "email",
      title: "EMAIL",
      dataIndex: "email",
      // width: 80,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "user_role",
      title: "MANAGER STATUS",
      dataIndex: "user_role",

      sorter: (a, b) => {
        a.user_role = !a.user_role ? "" : a.user_role;
        b.user_role = !b.user_role ? "" : b.user_role;
        return a.user_role.toString().localeCompare(b.user_role.toString());
      },

      sortDirections: ["descend", "ascend"],
      render: (_: any, elem: User, index: number) => {
        const onChangeRole = async () => {
          elem.user_role =
            elem.user_role != 3 ? (elem.user_role == 1 ? 2 : 1) : 3;
          setCurrIdx(index);
          let data = { user_role: elem.user_role };
          await dispatch(updateUser(elem.id, data));
        };

        return usersState.isUpdating && currIdx == index ? (
          <ContentLoader className={cls.toggle_loader}></ContentLoader>
        ) : (
          <Toggle
            checked={
              elem && elem.user_role && elem.user_role != 1 ? true : false
            }
            onChange={onChangeRole}
            disabled={elem.user_role > 2 ? true : false}
          ></Toggle>
        );
      },
    },
    {
      key: "territory",
      title: "TERRITORY",
      dataIndex: "territory",

      sorter: (a, b) => {
        a.territory = !a.territory ? "" : a.territory;
        b.territory = !b.territory ? "" : b.territory;
        return a.territory.toString().localeCompare(b.territory.toString());
      },

      sortDirections: ["descend", "ascend"],
      render: (_: any, elem: User, index: number) => {
        return (
          <span>
            {elem.territory
              ? territory_options[elem.territory].label
              : "Not Defined"}
          </span>
        );
      },
    },
    // {
    //   key: "created_at",
    //   title: "DATE ADDED",
    //   dataIndex: "created_at",
    //   sorter: (a, b) => a.created_at.localeCompare(b.created_at),
    //   sortDirections: ["descend", "ascend"],
    //   render: (_: any, elem: userTableType, index: number) => {
    //     return elem.created_at ? dateFormat(strToDate(elem.created_at)) : "";
    //   },
    // },
    // {
    //   key: "updated_at",
    //   title: "DATE UPDATED",
    //   dataIndex: "updated_at",
    //   sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
    //   sortDirections: ["descend", "ascend"],
    //   render: (_: any, elem: userTableType, index: number) => {
    //     return elem.updated_at ? dateFormat(strToDate(elem.updated_at)) : "";
    //   },
    // },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_: any, elem: any) => {
        return (
          <ContainerHorizontal>
            {/* <Button
              clear
              Component={NavLink}
              to={`edit/${elem.id}`}
              className={cls.marginRight}
            >
              Edit
            </Button> */}
            <ButtonDeleteUser itemId={elem.id} />
          </ContainerHorizontal>
        );
      },
    },
  ];

  // if (!authState.data.user.territory) {
  //   modalAddControls.handleOpen();
  // }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: any,
    sorter: any
  ) => {
    setCurrentPage(pagination.current);
  };

  let searchOptions = dcolumns.map((col) => {
    return { value: col.key, label: col.title };
  });
  // searchOptions = searchOptions.filter((option) => {
  //   let condition = option.value == "operation";
  //   return !condition;
  // });
  searchOptions.pop();

  // searchOptions.unshift({ value: null, label: "All" });
  searchOptions.unshift({ value: "", label: "Select search" });

  let searchOptionIdx = searchOptions.findIndex(
    (x) => x.value === searchOption
  );
  searchOptionIdx = searchOptionIdx < 0 ? 0 : searchOptionIdx;

  const handleSearchTxtChange = () => {};

  // const filterUsers = () => {
  //   setUsers(userState.data)
  // }

  const filteredUser = usersState.data.filter((fellow) => {
    if (searchOption != "") {
      if (searchOption === "fullname") {
        return (
          fellow["first_name"]
            .toLowerCase()
            .includes(searchTxt.toLowerCase()) ||
          fellow["last_name"].toLowerCase().includes(searchTxt.toLowerCase())
        );
      } else if (searchOption === "location") {
        return (
          fellow["fellowship_city"]
            .toLowerCase()
            .includes(searchTxt.toLowerCase()) ||
          fellow["fellowship_state"]
            .toLowerCase()
            .includes(searchTxt.toLowerCase())
        );
      } else {
        return fellow[searchOption]
          .toLowerCase()
          .includes(searchTxt.toLowerCase());
      }
    } else {
      return true;
    }
  });

  return (
    <ContainerVertical>
      {/* <WidgetContainer> */}
      <ScrollArea>
        <PageTitle />
        <ModalAddTerritory {...modalAddControls} />
        <WidgetContainer>
          <Alert
            className={cls.alertMsg}
            // warning
            rightEl={
              <button
                aria-label="Close"
                className="close"
                data-dismiss="alert"
                type="button"
              >
                {/* <Icon prefix={"far"} name={"times"}>
              close
            </Icon> */}
              </button>
            }
          >
            Greenlight is a fellow tracking process for reps. By
            entering information on your fellows, you will be able to track
            their progress over time. This tool will help you follow fellows as
            they move toward their permanent facility and truly ensure that no
            fellow is left behind.
          </Alert>
          <TopBar style={{ direction: "ltr" }}>
            {/* <h1>Top Bar</h1> */}
            <div className={`${cls.searchBox} searchBox`}>
              <Select
                options={searchOptions}
                activeOption={searchOptions[searchOptionIdx]}
                onChange={(option) => {
                  setSearchOption(option.value);
                }}
              />
              <Input
                name="searchTxt"
                type="text"
                className={cls.searchTxt}
                placeholder="Search users..."
                value={searchTxt || ""}
                onChange={(e) => {
                  setSearchTxt(e.target.value);
                }}
                icon={
                  <Icon prefix={"far"} name={"search"}>
                    search
                  </Icon>
                }
                style={{ borderRadius: "50px" }}
              />
            </div>
            <div className={cls.btnGroup}>
              <Button
                primary
                className={cls.yellowBtn}
                Component={NavLink}
                to={`${newPath}create-new`}
              >
                <Icon prefix={"far"} name={"plus-circle"} />
                ADD NEW USER
              </Button>
            </div>
          </TopBar>
          {/* <Widget>{tableEl}</Widget> */}
          {/* <ScrollArea> */}
          <Widget>
            <DTable
              columns={dcolumns}
              dataSource={filteredUser}
              onChange={handleTableChange}
              locale={{
                emptyText: (
                  <span>
                    {/* <img src="https://t4.ftcdn.net/jpg/02/19/09/81/240_F_219098120_ArejXY3xHPrOB5kSrsmJhOuY3mbPlogl.jpg"></img> */}
                    Sorry, we couldn't find any results{" "}
                    {searchTxt ? `matching "${searchTxt}".` : "."}
                  </span>
                ),
              }}
              // rowSelection={rowSelection}
              // scroll={{ x: 1024 }}
            />
          </Widget>
        </WidgetContainer>
      </ScrollArea>
      {/* </WidgetContainer> */}
    </ContainerVertical>
  );
};

export default withRouter(UsersList);
