/**
 * This component is jsut a container with 15px padding :)
 */

import createSimpleComponent from '../createSimpleComponent'

import cls from './simplelist.module.scss'

export const SimpleListRow = createSimpleComponent({
  displayName: 'SimpleListRow',
  className: cls.row,
  Component: 'div',
})
