import enhanceReducerWithWriteLocalStorage from '../enhanceReducerWithWriteLocalStorage'
import initialState from './initialState'

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    // main menu
    case '@app/APP_MENU_OPEN':
      return {
        ...state,
        isAppMenuOpen: true,
      }

    case '@app/APP_MENU_CLOSE':
      return {
        ...state,
        isAppMenuOpen: false,
      }
      // connection menu
    case '@app/CONNECTION_MENU':
      return {
        ...state,
        isConnectionMenuOpen: action.payload,
      }
    case '@app/NAVIGATION_COLLAPSE_TOGGLE':
      return {
        ...state,
        isNavigationCollapsed: !state.isNavigationCollapsed,
      }

    case '@app/NAVIGATION_CLOSE':
    case '@ui/SET_WINDOW_DIMENSION':
      return {
        ...state,
        isMenuOpen: false,
      }

    case '@app/NAVIGATION_TOGGLE':
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      }

    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        isAppMenuOpen: false,
        isMenuOpen: false,
        isConnectionMenuOpen: false,
      }
    default:
      return state
  }
}

export default enhanceReducerWithWriteLocalStorage('@app')(appReducer)
