import React from 'react'
import classnames from 'classnames'

import cls from './nav-icon.module.scss'

import LineLong from './assets/LineLong'
import LineShort from './assets/LineShort'

// @flow
export type NavBurgerProps = JSX.IntrinsicElements['div'] & {
  className?: string,
  isOpen?: boolean,
}

export const NavBurger = ({
  className,
  isOpen,
  ...rest
}: NavBurgerProps) => (
    <div
      className={classnames(cls.wrapper, className, {
        [cls.isOpen]: isOpen,
      })}
      {...rest}
    >
      <LineShort className={cls.a} />
      <LineLong className={cls.b} />
      <LineShort className={cls.c} />
    </div>
  )

NavBurger.defaultProps = {
  className: null,
  isOpen: false,
}

export default NavBurger
