
import { SnippetsState } from './types';

const initialState: SnippetsState = {
  data: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  pagination: {},
};

export default initialState;
