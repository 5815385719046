import * as Yup from "yup";

const PersonalInfoSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .max(50, "Too Long!")
      .required("Firstname is required"),
    lastName: Yup.string()
      .max(50, "Too Long!")
      .required("Lastname is required"),
    oldPassword: Yup.string()
      .min(
        8,
        "Use 6 or more characters with a mix of letters, numbers & symbols."
      )
      .when("newPassword", {
        is: undefined,
        then: Yup.string(),
        otherwise: Yup.string().required("Old Password is required"),
      })
      .when("newPasswordConfirm", {
        is: undefined,
        then: Yup.string(),
        otherwise: Yup.string().required("Old Password is required"),
      }),
    newPassword: Yup.string().when("oldPassword", {
      is: undefined,
      then: Yup.string(),
      otherwise: Yup.string()
        .required("New password is required")
        .min(
          6,
          "Use 6 or more characters with a mix of letters, numbers & symbols."
        )
        .matches(
          /([(?=.*?[a-z])(?=.*?[0-9])/,
          "Use 6 or more characters with a mix of letters, numbers & symbols."
        ),
    }),
    newPasswordConfirm: Yup.string().when("newPassword", {
      is: undefined,
      then: Yup.string(),
      otherwise: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match!")
        .required("Confirm new password is required"),
    }),
  },
  [
    ["oldPassword", "newPassword"],
    ["oldPassword", "newPasswordConfirm"],
  ]
);

export default PersonalInfoSchema;
