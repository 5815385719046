import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, FormikProps } from "formik";
// import FormData from "form-data";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { RouteComponentProps, Redirect, NavLink } from "react-router-dom";
import { useRedux, useReduxLoading } from "@redux";
import ButtonDeleteUser from "../components/ButtonDeleteUser";
import ButtonDeleteHeadshot from "../components/ButtonDeleteHeadshot";
import clientFetch, { imageUpload } from "@redux/@thunker/clientFetch";
import createFetch from "@redux/@thunker/fetch";
import { DropdownMenuPosition } from "@duik/it";
import {
  WidgetContent,
  Widget,
  Input,
  FieldsetInput,
  FieldsetTextarea,
  FormInputGroup,
  Button,
  SelectSearch,
  Select,
  ContentLoader,
  ContainerVertical,
  Checkbox,
} from "components";
import { AppState } from "@redux";
import * as authActions from "@redux/auth/actions";
// import { authState } from "@redux/auth/types";
import { API_URL, USER_ROLE } from "types/general";
import { FellowInput } from "@redux/fellows/types";
import { createFellow, updateFellow } from "@redux/fellows/actions";
import PersonalInfoSchema from "./validatePersonalInfo";

import ScrollArea from "components/ScrollArea";
import PageTitle from "../components/AddPageTitle";
import ImageTabs from "../components/ImageTabs";
import cls from "./user.module.scss";
import { capitalize, isError } from "lodash";
import axios from "axios";

/**
 * Type of Formvalues
 */
type FormValues = Omit<FellowInput, "id"> & {
  id?: number | string;
  territory?: number | string;

};

/**
 * Type of Props
 */
export type PageEditFellowProps = RouteComponentProps<{
  fellowId?: string;
}> & {};

const PageEditFellow = (props: PageEditFellowProps) => {
  const userState = useRedux("fellows");
  const authState = useRedux("auth");
  const fellowsState = useRedux("fellows");


  //NEW TERRITORIES

  const updated_territory_options = [

    { value: 'Bay Area', label: 'Bay Area', },
    { value: 'Bluegrass', label: 'Bluegrass', },
    { value: 'Brass Valley', label: 'Brass Valley', },
    { value: 'Canada', label: 'Canada', },
    { value: 'Outside of U.S.', label: 'Outside of U.S.', },
    { value: 'Delta', label: 'Delta', },
    { value: 'East Florida', label: 'East Florida', },
    { value: 'Empire', label: 'Empire', },
    { value: 'Great Lakes', label: 'Great Lakes', },
    { value: 'Great Plains', label: 'Great Plains', },
    { value: 'Heartland', label: 'Heartland', },
    { value: 'Indiana', label: 'Indiana', },
    { value: 'Liberty', label: 'Liberty', },
    { value: 'National Capital', label: 'National Capital', },
    { value: 'New England', label: 'New England', },
    { value: 'Northern', label: 'Northern', },
    { value: 'North Texas', label: 'North Texas', },
    { value: 'Northwest', label: 'Northwest', },
    { value: 'North Woods', label: 'North Woods', },
    { value: 'Ohio Valley', label: 'Ohio Valley', },
    { value: 'Pacific Coast', label: 'Pacific Coast', },
    { value: 'Pacific Desert', label: 'Pacific Desert', },
    { value: 'Pacific Mountain', label: 'Pacific Mountain', },
    { value: 'Rocky Mountain', label: 'Rocky Mountain', },
    { value: 'Sierra Nevada', label: 'Sierra Nevada', },
    { value: 'South Atlantic', label: 'South Atlantic', },
    { value: 'Southern', label: 'Southern', },
    { value: 'Southern California', label: 'Southern California', },
    { value: 'South Texas', label: 'South Texas', },
    { value: 'Southwest', label: 'Southwest', },
    { value: 'Steel Cities', label: 'Steel Cities', },
    { value: 'Tar Heel', label: 'Tar Heel', },
    { value: 'Virginias', label: 'Virginias', },
    { value: 'West Florida', label: 'West Florida', },
    { value: 'West Texas', label: 'West Texas', },
    { value: 'Windy City', label: 'Windy City', },
  ]

  //LEGACY
  const territory_options = [
    { value: 0, label: 'Akron', },
    { value: 1, label: 'Alaska', },
    { value: 2, label: 'Albany', },
    { value: 3, label: 'Ann Arbor', },
    { value: 4, label: 'Asheville', },
    { value: 5, label: 'Atlanta - North', },
    { value: 6, label: 'Atlanta Split', },
    { value: 7, label: 'Austin South', },
    { value: 8, label: 'Bakersfield', },
    { value: 9, label: 'Baltimore Central', },
    { value: 10, label: 'Baltimore North', },
    { value: 11, label: 'Baltimore South', },
    { value: 12, label: 'Baton Rouge', },
    { value: 13, label: 'Beaumont', },
    { value: 14, label: 'Bend', },
    { value: 15, label: 'Birmingham', },
    { value: 16, label: 'Black Hills', },
    { value: 17, label: 'Boca Raton', },
    { value: 18, label: 'Boise', },
    { value: 19, label: 'Boston North', },
    { value: 20, label: 'Boston West Split', },
    { value: 21, label: 'Bronx-Westchester', },
    { value: 22, label: 'Brooklyn', },
    { value: 23, label: 'Buckeye Valley', },
    { value: 24, label: 'Buffalo', },
    { value: 25, label: 'Central Detroit', },
    { value: 26, label: 'Central Iowa North', },
    { value: 27, label: 'Central Iowa South', },
    { value: 28, label: 'Central Jersey', },
    { value: 29, label: 'Central Minnesota', },
    { value: 30, label: 'Central Pennsylvania', },
    { value: 31, label: 'Central Texas', },
    { value: 32, label: 'Central Valley', },
    { value: 33, label: 'Central Wisconsin', },
    { value: 34, label: 'Charleston Expansion', },
    { value: 35, label: 'Charlotte Split', },
    { value: 36, label: 'Chattanooga', },
    { value: 37, label: 'Chicago Downtown North', },
    { value: 38, label: 'Chicago Downtown South', },
    { value: 39, label: 'Chicago Downtown West', },
    { value: 40, label: 'Chicago North', },
    { value: 41, label: 'Chicago NW', },
    { value: 42, label: 'Chicago South', },
    { value: 43, label: 'Chicago SW', },
    { value: 44, label: 'Chicago West', },
    { value: 45, label: 'Chico', },
    { value: 46, label: 'Cincinnati', },
    { value: 47, label: 'Cincinnati Metro', },
    { value: 48, label: 'Cleveland Downtown', },
    { value: 49, label: 'Cleveland East', },
    { value: 50, label: 'Cleveland West', },
    { value: 51, label: 'College Station', },
    { value: 52, label: 'Columbia MO', },
    { value: 53, label: 'Columbia SC', },
    { value: 54, label: 'Columbus North', },
    { value: 55, label: 'Columbus South', },
    { value: 56, label: 'Dallas East Split', },
    { value: 57, label: 'Dayton West', },
    { value: 58, label: 'DC Metro', },
    { value: 59, label: 'Denver East', },
    { value: 60, label: 'Denver West', },
    { value: 61, label: 'Detroit', },
    { value: 62, label: 'DFW North', },
    { value: 63, label: 'DFW South', },
    { value: 64, label: 'DFW Split', },
    { value: 65, label: 'DFW West', },
    { value: 66, label: 'DT Houston', },
    { value: 67, label: 'East Bay', },
    { value: 68, label: 'East Houston', },
    { value: 69, label: 'El Paso Split', },
    { value: 70, label: 'Erie, PA', },
    { value: 71, label: 'Eugene', },
    { value: 72, label: 'Evansville', },
    { value: 73, label: 'Fargo, ND', },
    { value: 74, label: 'Fox Valley', },
    { value: 75, label: 'Fremont', },
    { value: 76, label: 'Fresno', },
    { value: 77, label: 'Ft. Wayne', },
    { value: 78, label: 'Grand Rapids', },
    { value: 79, label: 'Greater DC', },
    { value: 80, label: 'Greater Miami Split', },
    { value: 81, label: 'Greensboro, NC', },
    { value: 82, label: 'Greenville/Augusta', },
    { value: 83, label: 'Hampton Roads', },
    { value: 84, label: 'Harrisburg', },
    { value: 85, label: 'Hattiesburg', },
    { value: 86, label: 'Hawaii Split', },
    { value: 87, label: 'Hill Country', },
    { value: 88, label: 'Houston North', },
    { value: 89, label: 'Houston South', },
    { value: 90, label: 'Houston SW', },
    { value: 91, label: 'Hudson Valley', },
    { value: 92, label: 'Huntsville Split', },
    { value: 93, label: 'Indiana Shores', },
    { value: 94, label: 'Indianapolis Metro', },
    { value: 95, label: 'Indianapolis So.', },
    { value: 96, label: 'Inland Empire North', },
    { value: 97, label: 'Inland Empire South', },
    { value: 98, label: 'Inland Empire West', },
    { value: 99, label: 'Iowa City', },
    { value: 100, label: 'Jackson', },
    { value: 101, label: 'Jacksonville, FL', },
    { value: 102, label: 'Jersey Shore', },
    { value: 103, label: 'Jonesboro Expansion', },
    { value: 104, label: 'Kansas City', },
    { value: 105, label: 'Kansas City South', },
    { value: 106, label: 'Kearney, NE Split', },
    { value: 107, label: 'Knoxville South', },
    { value: 108, label: 'LA North', },
    { value: 109, label: 'Lake Charles', },
    { value: 110, label: 'Lansing', },
    { value: 111, label: 'Las Vegas North', },
    { value: 112, label: 'Las Vegas South', },
    { value: 113, label: 'Lehigh Valley', },
    { value: 114, label: 'Lexington', },
    { value: 115, label: 'Little Rock Split', },
    { value: 116, label: 'Long Beach', },
    { value: 117, label: 'Long Beach South', },
    { value: 118, label: 'Long Island East', },
    { value: 119, label: 'Long Island West', },
    { value: 120, label: 'Los Angeles Downtown', },
    { value: 121, label: 'Los Angeles South', },
    { value: 122, label: 'Los Angeles West', },
    { value: 123, label: 'Louisville Metro', },
    { value: 124, label: 'Lubbock Split', },
    { value: 125, label: 'Maine', },
    { value: 126, label: 'Manhattan', },
    { value: 127, label: 'Manhattan Expansion', },
    { value: 128, label: 'MD-DE Shore', },
    { value: 129, label: 'Memphis', },
    { value: 130, label: 'Milwaukee Central', },
    { value: 131, label: 'Milwaukee North', },
    { value: 132, label: 'Minneapolis North', },
    { value: 133, label: 'Mobile', },
    { value: 134, label: 'Montana East', },
    { value: 135, label: 'Montana West', },
    { value: 136, label: 'Montgomery', },
    { value: 137, label: 'N. Mississippi', },
    { value: 138, label: 'Naples/Ft. Myers split', },
    { value: 139, label: 'Nashville North', },
    { value: 140, label: 'Nashville South', },
    { value: 141, label: 'NE Kansas', },
    { value: 142, label: 'NE Texas', },
    { value: 143, label: 'New Hampshire', },
    { value: 144, label: 'New Mexico Split', },
    { value: 145, label: 'New Orleans North Shore', },
    { value: 146, label: 'New Orleans South Shore', },
    { value: 147, label: 'Newark North', },
    { value: 148, label: 'Newark South', },
    { value: 149, label: 'Newark West', },
    { value: 150, label: 'No. Colorado', },
    { value: 151, label: 'North Austin', },
    { value: 152, label: 'North Bay', },
    { value: 153, label: 'North Central Indiana', },
    { value: 154, label: 'Northern Arizona', },
    { value: 155, label: 'Northern Virginia', },
    { value: 156, label: 'NW Arkansas', },
    { value: 157, label: 'NW Indiana', },
    { value: 158, label: 'Oakland', },
    { value: 159, label: 'Ogden', },
    { value: 160, label: 'Oklahoma City East', },
    { value: 161, label: 'Oklahoma City South', },
    { value: 162, label: 'Oklahoma City West', },
    { value: 163, label: 'Omaha Split', },
    { value: 164, label: 'Orange County North', },
    { value: 165, label: 'Orange County South', },
    { value: 166, label: 'Orlando', },
    { value: 167, label: 'Oshkosh', },
    { value: 168, label: 'Palm Beach', },
    { value: 169, label: 'Palm Springs', },
    { value: 170, label: 'Pasadena', },
    { value: 171, label: 'Peninsula', },
    { value: 172, label: 'Pensacola', },
    { value: 173, label: 'Peoria, IL', },
    { value: 174, label: 'Philadelphia Downtown', },
    { value: 175, label: 'Philadelphia North Split', },
    { value: 176, label: 'Philadelphia West', },
    { value: 177, label: 'Phoenix Central', },
    { value: 178, label: 'Phoenix East', },
    { value: 179, label: 'Phoenix South', },
    { value: 180, label: 'Phoenix West', },
    { value: 181, label: 'Pittsburgh Central', },
    { value: 182, label: 'Pittsburgh East', },
    { value: 183, label: 'Pittsburgh So.', },
    { value: 184, label: 'Portland', },
    { value: 185, label: 'Portland West', },
    { value: 186, label: 'Providence', },
    { value: 187, label: 'Provo', },
    { value: 188, label: 'Quad Cities', },
    { value: 189, label: 'Queens', },
    { value: 190, label: 'Raleigh-Durham Split', },
    { value: 191, label: 'Reno', },
    { value: 192, label: 'Richmond Split', },
    { value: 193, label: 'Rio Grande Valley Split', },
    { value: 194, label: 'Roanoke', },
    { value: 195, label: 'Rochester', },
    { value: 196, label: 'Sacramento', },
    { value: 197, label: 'Sacramento West', },
    { value: 198, label: 'Saginaw', },
    { value: 199, label: 'Salt Lake City', },
    { value: 200, label: 'San Antonio Split', },
    { value: 201, label: 'San Diego Central', },
    { value: 202, label: 'San Diego North', },
    { value: 203, label: 'San Diego South', },
    { value: 204, label: 'San Fernando Valley', },
    { value: 205, label: 'San Francisco', },
    { value: 206, label: 'Sandhills NC', },
    { value: 207, label: 'Santa Barbara', },
    { value: 208, label: 'Sarasota', },
    { value: 209, label: 'Savannah', },
    { value: 210, label: 'Scranton', },
    { value: 211, label: 'SE Idaho', },
    { value: 212, label: 'Seattle Central', },
    { value: 213, label: 'Seattle East', },
    { value: 214, label: 'Seattle North', },
    { value: 215, label: 'Seattle South', },
    { value: 216, label: 'Shenadoah Valley', },
    { value: 217, label: 'Shreveport Monroe', },
    { value: 218, label: 'Sioux Falls, SD', },
    { value: 219, label: 'Siouxland', },
    { value: 220, label: 'So. Colorado', },
    { value: 221, label: 'South Bay', },
    { value: 222, label: 'South Bend', },
    { value: 223, label: 'South Georgia', },
    { value: 224, label: 'South Jersey Split', },
    { value: 225, label: 'Southeast MO', },
    { value: 226, label: 'Southern CT', },
    { value: 227, label: 'Southern Maryland', },
    { value: 228, label: 'Southern Minnesota', },
    { value: 229, label: 'Southwest Virginia', },
    { value: 230, label: 'Space Coast', },
    { value: 231, label: 'Spokane', },
    { value: 232, label: 'Springfield', },
    { value: 233, label: 'Springfield, MO', },
    { value: 234, label: 'St Louis Downtown', },
    { value: 235, label: 'St Louis East', },
    { value: 236, label: 'St Louis West', },
    { value: 237, label: 'St Paul', },
    { value: 238, label: 'Syracuse', },
    { value: 239, label: 'Tacoma', },
    { value: 240, label: 'Tallahassee', },
    { value: 241, label: 'Tampa', },
    { value: 242, label: 'Tidewater Split', },
    { value: 243, label: 'Toledo', },
    { value: 244, label: 'Traverse City', },
    { value: 245, label: 'Tucson', },
    { value: 246, label: 'Tulsa North', },
    { value: 247, label: 'Tulsa South', },
    { value: 248, label: 'Tuscaloosa', },
    { value: 249, label: 'Vermont', },
    { value: 250, label: 'West Virginia North', },
    { value: 251, label: 'West Virginia South Split', },
    { value: 252, label: 'Western Colorado', },
    { value: 253, label: 'Western MA', },
    { value: 254, label: 'Western Minnesota', },
    { value: 255, label: 'Wichita Split', },
    { value: 256, label: 'Wilmington, NC', },
    { value: 257, label: 'Winsboro', },
    { value: 258, label: 'Yakima', },
    { value: 259, label: 'OTHER', },
  ];


  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(getUsers());
  // }, []);
  // if (useReduxLoading("users")) {
  //   return <ContentLoader>Fetching Users</ContentLoader>;
  // }

  const [isLoading, setIsLoading] = React.useState(false);
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const [keyword, setKword] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const [tabName, setTabName] = React.useState("basic");
  const [tabTitle, setTabTitle] = React.useState("Fellow's basic info");
  const [headshot, setHeadshot] = React.useState(undefined);
  const [headshotUrl, setHeadshotUrl] = React.useState("");
  const [isImgUploading, setIsImgUploading] = React.useState(false);

  const [isHome, goHome] = React.useState(false);
  const [sectionName, goTo] = React.useState('');

  const [tabDescription, setTabDescription] =
    React.useState(`Get started by entering your fellow's contact information, so we
  have a record of who they are and where their fellowship is
  located`);
  const { fellowId } = props.match.params;

  const fellow = fellowsState.data.find((item) => `${item.id}` === fellowId);
  const [isFoundPosition, setIsFoundPosition] = React.useState(fellow && fellow.found_position === "Yes");
  const [isErrors, setIsErrors] = React.useState(true);
  const [buttonLabel, setButtonLabel] = React.useState("CREATE FELLOW");
  const [isConfirmation, setIsConfirmation] = React.useState(false);
  const [submitErrors, setSubmitErrors] = React.useState({ found_position: false, territory: false });


  React.useEffect(() => {
    const tmp = fellowsState.data.find((item) => `${item.id}` === fellowId);
    if (tmp) {
      if (tmp.headshot) {
        setHeadshotUrl(tmp.headshot);
      } else {
        setHeadshotUrl("");
      }
    }
  }, [fellowsState]);

  if (fellowsState.data.length === 0) {
    // props.history.push("/app/fellows/list");

    return <Redirect to={`${props.match.url}/fellows/list`} />;
  }



  // Inital formvalues
  const initialValues: FormValues = {
    first_name: fellow.first_name,
    last_name: fellow.last_name,
    email: fellow.email,
    phone: fellow.phone,
    headshot: fellow.headshot,
    international: fellow.international,
    specialty: fellow.specialty,
    fellowship_program_name: fellow.fellowship_program_name,
    fellowship_city: fellow.fellowship_city,
    fellowship_state: fellow.fellowship_state,
    fellowship_country: fellow.fellowship_country,
    medical_school: fellow.medical_school,
    residency: fellow.residency,
    hometown: fellow.hometown,
    hobbies: fellow.hobbies,
    family_info: fellow.family_info,
    npi_number: fellow.npi_number,
    publication_links: fellow.publication_links,
    product_landscape: fellow.product_landscape,
    product_video_platform: fellow.product_video_platform,
    important_competitive_history: fellow.important_competitive_history,
    cross_divisional_leverage: fellow.cross_divisional_leverage,
    other_products_used: fellow.other_products_used,
    pain_points: fellow.pain_points,
    additional_notes: fellow.additional_notes,
    practice_name: fellow.practice_name,
    practice_city: fellow.practice_city,
    practice_state: fellow.practice_state,
    practice_country: fellow.practice_country,
    equipment_requests: fellow.equipment_requests,
    territory: fellow.territory,
    found_position: fellow.found_position,
    practice_start_date: fellow.practice_start_date

  };

  const handleTabClick = (tab) => {
    setTabName(tab);
    switch (tab) {
      case "basic":
        setTabTitle("Fellow's basic info");
        setTabDescription(`Get started by entering your fellow's contact information, so we
        have a record of who they are and where their fellowship is
        located`);
        break;
      case "fellow":
        setTabTitle("Fellow info");
        setTabDescription(
          `As you follow up with your fellows and identify they are moving, update their information, so we can track where they're planning to go. This stage update must be approved.`
        );
        break;
      case "practice":
        setTabTitle("Fellowship / Practice info");
        setTabDescription(
          `Once your fellow has actually moved to their post fellowship facility, confirm their new information and make any changes to their profile. This stage update must be approved.`
        );
        break;
      default:
        setTabTitle("Basic info");
        setTabDescription(`Get started by entering your fellow's contact information, so we
        have a record of who they are and where their fellowship is
        located`);
        break;
    }
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == "done") {
      setHeadshot(file);
    } else if (status == "removed") {
      setHeadshot(undefined);
    }
  };

  // Handle update
  const handleCreate = async (data) => {
    const basicInfo: any = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      //international: data.international,
      specialty: data.specialty,
      fellowship_program_name: data.fellowship_program_name,
      fellowship_city: data.fellowship_city,
      fellowship_state: data.fellowship_state,
      //fellowship_country: data.fellowship_country,
      territory: data.territory,
      found_position: data.found_position
    };

    const fellowInfo = {
      //medical_school: data.medical_school,
      //residency: data.residency,
      //hometown: data.hometown,
      //hobbies: data.hobbies,
      //family_info: data.family_info,
      npi_number: data.npi_number,
      //publication_links: data.publication_links,
      //product_landscape: data.product_landscape,
      other_products_used: data.other_products_used,
      //product_video_platform: data.product_video_platform,
      //important_competitive_history: data.important_competitive_history,
      //cross_divisional_leverage: data.cross_divisional_leverage,
      //pain_points: data.pain_points,
      //additional_notes: data.additional_notes,
    };

    const fellowshipInfo = {
      practice_name: data.practice_name,
      practice_city: data.practice_city,
      practice_state: data.practice_state,
      //practice_country: data.practice_country,
      practice_start_date: data.practice_start_date,
      //equipment_requests: data.equipment_requests,
    };
    let updateData = { basicInfo, fellowInfo, fellowshipInfo };
    if (headshot) {
      setIsImgUploading(true);
      const formData = new FormData();
      formData.append("file", headshot);
      let imageUrl = await imageUpload("fellows/image-upload", formData);
      basicInfo.headshot = imageUrl.data;
      setIsImgUploading(false);
      setHeadshot(null);
    }


    dispatch(updateFellow(fellow.id, updateData, (success) => {
      if (success) {

        isHome && window.location.assign("/app/fellows/list");
        //handleTabClick(sectionName);

      }

    }));

  };

  return (
    <ContainerVertical>
      <ScrollArea>
        <PageTitle subTitle={"EDIT A FELLOW"} />
        <div className={cls.registerWrapper}>
          <WidgetContent className={cls.fixedWidth}>
            <div className={cls.tabHeader}>
              <h1 className={cls.tabTitle}>{tabTitle}</h1>
              <p className={cls.tabDescription}>{tabDescription}</p>
              <ImageTabs isCreateNew={false} isFoundPosition={isFoundPosition && !isErrors && !submitErrors.found_position && !submitErrors.territory} tabName={tabName} onClickTab={handleTabClick} />
            </div>

            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={PersonalInfoSchema}
              onSubmit={(data) => {

                handleCreate(data);
              }}
            >
              {({
                errors,
                touched,
                setErrors,
                setTouched,
                validateForm,
                submitForm,
                handleChange,
                handleSubmit,
                handleBlur,
                setValues,
                values,
              }: FormikProps<FormValues>) => {
                setIsErrors(Object.keys(errors).length !== 0);
                return (
                  <form onSubmit={handleSubmit}>
                    <FormInputGroup className={cls.formInputGroup}>
                      {tabName === "basic" && (
                        <>
                          <FieldsetInput
                            label="First name*"
                            name="first_name"
                            className={cls.inputField}
                            onChange={(e) => {

                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);
                            }}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.first_name && touched.first_name
                                ? errors.first_name
                                : null
                            }
                            value={values.first_name}
                          />
                          <FieldsetInput
                            label="Last name*"
                            name="last_name"
                            className={cls.inputField}
                            onChange={(e) => {

                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);

                            }}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.last_name && touched.last_name
                                ? errors.last_name
                                : null
                            }
                            value={values.last_name}
                          />
                          <FieldsetInput
                            label="Email*"
                            name="email"
                            type="email"
                            className={cls.inputField}
                            value={values.email}
                            onChange={(e) => {

                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);

                            }}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.email && touched.email
                                ? errors.email
                                : null
                            }
                          />
                          <FieldsetInput
                            label="Phone*"
                            name="phone"
                            className={cls.inputField}
                            onChange={(e) => {
                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);

                            }}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.phone && touched.phone
                                ? errors.phone
                                : null
                            }
                            value={values.phone}
                          />
                          {/* Headshot here */}
                          {/* <Dropzone
                            initialFiles={headshot ? [headshot] : null}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                            inputContent={`Drag and drop your photo here, or browser`}
                            onChangeStatus={handleChangeStatus}
                            accept="image/*"
                          /> */}
                          {/*  <Checkbox
                            name={"international"}
                            onChange={handleChange}
                            label={<>International</>}
                            className={cls.checkbox}
                            checked={values.international}
                          /> */}
                          <FieldsetInput
                            label="Specialty*"
                            name="specialty"
                            className={cls.inputField}
                            onChange={(e) => {
                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);

                            }}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.specialty && touched.specialty
                                ? errors.specialty
                                : null
                            }
                            value={values.specialty}
                          />
                          <FieldsetInput
                            label="Fellowship program name*"
                            name="fellowship_program_name"
                            className={cls.inputField}
                            onChange={(e) => {

                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);

                            }}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.fellowship_program_name &&
                                touched.fellowship_program_name
                                ? errors.fellowship_program_name
                                : null
                            }
                            value={values.fellowship_program_name}
                          />
                          <FieldsetInput
                            label="Fellowship city*"
                            name="fellowship_city"
                            className={cls.inputField}
                            onChange={(e) => {
                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);

                            }}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.fellowship_city && touched.fellowship_city
                                ? errors.fellowship_city
                                : null
                            }
                            value={values.fellowship_city}
                          />

                          {values.international ? (
                            <>
                              {" "}
                              <FieldsetInput
                                label="Fellowship state"
                                name="fellowship_state"
                                className={cls.inputField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMessage={
                                  errors.fellowship_state &&
                                    touched.fellowship_state
                                    ? errors.fellowship_state
                                    : null
                                }
                                value={values.fellowship_state}
                              />
                              <FieldsetInput
                                label="Fellowship country"
                                name="fellowship_country"
                                className={cls.inputField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMessage={
                                  errors.fellowship_country &&
                                    touched.fellowship_country
                                    ? errors.fellowship_country
                                    : null
                                }
                                value={values.fellowship_country}
                              />
                            </>
                          ) : (
                            <FieldsetInput
                              label="Fellowship state*"
                              name="fellowship_state"
                              className={cls.inputField}
                              onChange={(e) => {
                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                              onBlur={handleBlur}
                              errorMessage={
                                errors.fellowship_state && touched.fellowship_state
                                  ? errors.fellowship_state
                                  : null
                              }
                              value={values.fellowship_state}
                            />
                          )}
                          <FieldsetInput
                            label={<span dangerouslySetInnerHTML={{ __html: "NPI NUMBER | FIND YOUR NPI NUMBER ON: <a target='_blank' href='https://NPIDB.org/NPI-LOOKUP/'>NPIDB.ORG/NPI-LOOKUP/</a>*" }}></span>}
                            name="npi_number"
                            onChange={(e) => {
                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);

                            }}
                            errorMessage={
                              errors.npi_number && touched.npi_number
                                ? errors.npi_number
                                : null
                            }
                            onBlur={handleBlur}
                            value={values.npi_number}
                          />

                          <label>TERRITORY*</label>

                          <Select
                            options={updated_territory_options}
                            activeOption={[...updated_territory_options, ...territory_options].find(op => op.value == values.territory)}
                            defaultOption={{
                              value: "",
                              label: "Select Territory",
                            }}

                            onChange={(option) => {
                              setValues({
                                ...values,
                                territory: option.value,
                              });

                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);

                            }}

                          />

                          {submitErrors.territory && (
                            <p style={{ color: '#e6492d', marginTop: '6px', lineHeight: '1.375rem' }}>This field is required.</p>)
                          }

                          <FieldsetTextarea
                            label="Please list the Stryker products your fellow has had exposure to:"
                            name="other_products_used"
                            onChange={(e) => {
                              handleChange(e);
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);
                            }}
                            onBlur={handleBlur}
                            value={values.other_products_used}
                          />

                          <label>Has the fellow found a position?*</label>
                          <Select
                            options={[{ value: 'Yes', label: 'Yes' }, { value: "No", label: 'No' }]}
                            activeOption={{ value: values.found_position, label: values.found_position }}
                            onChange={(option) => {
                              setValues({
                                ...values,
                                found_position: option.value,
                              });
                              setIsFoundPosition(option.value === "Yes");
                              setButtonLabel(option.value === "Yes" ? "CONTINUE TO POSITION DETAILS" : 'CREATE AND SAVE FELLOW');
                              validateForm();
                              setIsErrors(Object.keys(errors).length !== 0);
                            }}
                          />
                          {submitErrors.found_position && (
                            <p style={{ color: '#e6492d', marginTop: '6px', lineHeight: '1.375rem' }}>This field is required.</p>)
                          }                        </>
                      )}

                      {tabName === "fellow" && (
                        <>
                          <label className={cls.groupLabel}>
                            <b>Background</b>
                          </label>
                          <FieldsetInput
                            label="Medical school"
                            name="medical_school"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.medical_school}
                          />
                          <FieldsetInput
                            label="Residency"
                            name="residency"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.residency}
                          />
                          <label className={cls.groupLabel}>
                            <b>Personal info</b>
                          </label>
                          <FieldsetInput
                            label="Hometown"
                            name="hometown"
                            className={cls.inputField}
                            value={values.hometown}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FieldsetInput
                            label="Hobbies"
                            name="hobbies"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.hobbies}
                          />
                          <FieldsetInput
                            label="Family Information"
                            name="family_info"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.family_info}
                          />
                          <FieldsetInput
                            label={<span dangerouslySetInnerHTML={{ __html: "NPI NUMBER | FIND YOUR NPI NUMBER ON: <a target='_blank' href='https://NPIDB.org/NPI-LOOKUP/'>NPIDB.ORG/NPI-LOOKUP/</a>" }}></span>}
                            name="npi_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.npi_number}
                          />
                          <label className={cls.groupLabel}>
                            <b>Research Interests & Publications</b>
                          </label>
                          <FieldsetTextarea
                            label="Links to publications this fellow has published"
                            name="publication_links"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.publication_links}
                          />

                          <label className={cls.groupLabel}>
                            <b>Program Info</b>
                          </label>
                          <FieldsetInput
                            label="Current Product landscape"
                            name="product_landscape"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.product_landscape}
                          />
                          <FieldsetInput
                            label="Current video platform at current program"
                            name="product_video_platform"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.product_video_platform}
                          />
                          <FieldsetInput
                            label="Important competitive history"
                            name="important_competitive_history"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.important_competitive_history}
                          />

                          <FieldsetInput
                            label="Other Stryker Products Used"
                            name="other_products_used"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.other_products_used}
                          />
                          <FieldsetInput
                            label="Pain points"
                            name="pain_points"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pain_points}
                          />

                          <FieldsetTextarea
                            label="Additional notes"
                            name="additional_notes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.additional_notes}
                          />
                        </>
                      )}

                      {tabName === "practice" && (
                        <>
                          <label className={cls.groupLabel}>
                            <b>Practice info</b>
                          </label>
                          <FieldsetInput
                            label="Fellow's hospital placement"
                            name="practice_name"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_name}
                          />
                          <FieldsetInput
                            label="Fellow's placement city"
                            name="practice_city"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_city}
                          />
                          <FieldsetInput
                            label="Fellow's placement state"
                            name="practice_state"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_state}
                          />

                          <FieldsetInput
                            label="Fellow's placement country"
                            name="practice_country"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_country}
                          />
                          <FieldsetInput
                            label="Fellow's start date"
                            name="practice_start_date"
                            type='Date'
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_start_date}
                          />
                          {/*
                          <label className={cls.groupLabel}>
                            <b>Equipment requests</b>
                          </label>
                          <FieldsetInput
                            label="What equipment, if any, are they requesting at their facility?"
                            name="equipment_requests"
                            className={cls.inputField}
                            value={values.equipment_requests}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          /> */}
                        </>
                      )}

                      <br />
                      <FormInputGroup direction={"horizontal"}>
                        {tabName === "basic" && (
                          <>
                            <div>
                              <Button
                                Component={NavLink}
                                to={"/app/fellows/list"}
                                className={`${cls.defaultFont} ${cls.cancelBackButton}`}

                              >
                                {`CANCEL`}
                              </Button>
                            </div>
                            <div className={cls.btnGroup}>
                              <Button
                                className={cls.yellowBtn}
                                primary
                                type="submit"
                                onClick={() => {
                                  let isNo: boolean = values.found_position === "No" || !values.found_position;
                                  if (isNo) {

                                    goHome(true);

                                  } else {

                                    goTo('practice');
                                    goHome(true);

                                  }
                                  validateForm().then((errs) => {
                                    goHome(true)
                                    setSubmitErrors({ found_position: errs.found_position ? true : false, territory: errs.territory ? true : false });

                                    if (
                                      Object.keys(errs).length === 0 &&
                                      errs.constructor === Object
                                    ) {
                                      // submitForm();
                                    } else {
                                      setErrors(errs);
                                      let fieldList: any = {};
                                      Object.keys(errs).map((err) => {
                                        fieldList[err] = true;
                                      });
                                      setTouched({ ...touched, ...fieldList });
                                      setTabName("basic");
                                    }
                                  });
                                }}
                              >
                                {`SAVE AND UPDATE FELLOW`}
                              </Button>
                              {/*  <ButtonDeleteUser
                                itemId={fellowId}
                                btnTitle={"DELETE RECORD"}
                                className={`${cls.cancelBackButton} ${cls.btnDelete}`}
                              /> */}
                              {/* <Button
                                // isLoading={userState.isCreating}
                                className={cls.greyBtn}
                                primary
                              >
                                {`DELETE RECORD >`}
                              </Button> */}
                            </div>
                          </>
                        )}
                        {tabName === "fellow" && (
                          <>
                            <Button
                              className={`${cls.defaultFont} ${cls.cancelBackButton} `}
                              onClick={() => {
                                handleTabClick("basic");
                                goTo('')
                              }}
                            >
                              {`GO BACK`}
                            </Button>
                            <Button
                              isLoading={userState.isCreating}
                              className={cls.yellowBtn}
                              primary
                              type="submit"
                              onClick={() => {

                                let isFound: Boolean = values.found_position === "Yes"
                                if (isFound) {
                                  goTo('practice');
                                  setIsFoundPosition(true);
                                } else {

                                  setIsFoundPosition(false);
                                }


                                validateForm().then((errs) => {
                                  if (
                                    Object.keys(errs).length === 0 &&
                                    errs.constructor === Object
                                  ) {
                                    // submitForm();
                                  } else {
                                    setErrors(errs);
                                    let fieldList: any = {};
                                    Object.keys(errs).map((err) => {
                                      fieldList[err] = true;
                                    });
                                    setTouched({ ...touched, ...fieldList });
                                    setTabName("basic");
                                  }
                                });
                              }}
                            >
                              {buttonLabel}
                            </Button>
                          </>
                        )}
                        {tabName === "practice" && (
                          <>
                            <Button
                              className={`${cls.defaultFont} ${cls.cancelBackButton}`}
                              onClick={() => {
                                handleTabClick("basic");
                                goTo('');
                              }}
                            >
                              {`GO BACK`}
                            </Button>
                            <Button
                              isLoading={userState.isUpdating || isImgUploading}
                              className={cls.yellowBtn}
                              primary
                              type="submit"
                              onClick={() => {
                                // validateForm();
                                //goTo('');

                                goHome(true);
                                validateForm().then((errs) => {
                                  goHome(true)
                                  if (
                                    Object.keys(errs).length === 0 &&
                                    errs.constructor === Object
                                  ) {
                                    // submitForm();
                                  } else {
                                    setErrors(errs);
                                    let fieldList: any = {};
                                    Object.keys(errs).map((err) => {
                                      fieldList[err] = true;
                                    });
                                    setTouched({ ...touched, ...fieldList });
                                    //setTabName("basic");
                                  }
                                });
                              }}
                            >
                              {`SAVE AND UPDATE FELLOW`}
                            </Button>
                          </>
                        )}
                      </FormInputGroup>
                    </FormInputGroup>
                  </form>
                );
              }}
            </Formik>
          </WidgetContent>
        </div>
      </ScrollArea>
    </ContainerVertical>
  );
};

export default PageEditFellow;
