// Libraries
import React from "react";
import { connect, useSelector } from "react-redux";
import { Route, Switch, Redirect, matchPath } from "react-router-dom";
import AccountSettings from "./AccountSettings";
import VerifyEmail from "./components/VerifyEmail";
import Navigation from "./components/Navigation";
import { USER_ROLE } from "../../types/general";
// pages
import PageFellows from "./Fellows";
import PageUsers from "./Users";
import PagePersonalInformation from "./AccountSettings/PersonalInformation";

import { ContainerHorizontal, ContainerVertical } from "components";
import TopBar from "./components/TopBar";
import ContentPanel from "./components/ContentPanel";
import rootRouteMap from "./routeMap";

const hasMatchPaths = (pathname: string, paths: string[]) => {
  const matchObjects = paths
    .map((item) =>
      matchPath(pathname, {
        path: item,
        exact: true,
        strict: true,
      })
    )
    .filter((item) => !!item);
  return matchObjects.length > 0;
};

const Dashboard = ({ match, location }) => {
  const auth = useSelector((state) => state.auth);

  const isPublicPage = hasMatchPaths(location.pathname, [
    `${match.path}/schemas/:schemaId/public`,
  ]);

  if ((!auth.data || !auth.data.user || !auth.data.user.id) && !isPublicPage) {
    const from = location.pathname + encodeURI(location.search);
    return <Redirect to={`/auth?from=${from}`} />;
  }

  type LinkObject = {
    text: string,
    to: string,
    icon: React.ReactNode,
    rightEl?: React.ReactNode,
    asLink?: boolean,
    count?: React.ReactNode,
  };

  const assetLinks: LinkObject[] = [
    {
      text: "All Assets",
      to: "/assets",
      // icon: <Icon prefix={"far"} name={"th-large"} />,
      // rightEl: "→",
      asLink: false,
    },

    // {
    //   text: "Issued Assets",
    //   to: "/assets-checkin",
    //   // icon: <Icon prefix={"far"} name={"th-large"} />,
    //   // rightEl: "→",
    //   asLink: false,
    // },
    // {
    //   text: "Available Assets",
    //   to: "/assets-checkout",
    //   // icon: <Icon prefix={"far"} name={"th-large"} />,
    //   // rightEl: "→",
    //   asLink: false,
    // },
  ];

  const userLinks: LinkObject[] = [
    {
      text: "Users ",
      to: "/users",
      // icon: <Icon prefix={"far"} name={"th-large"} />,
      // rightEl: "→",
      asLink: false,
    },
    // {
    //   text: "Permission Groups",
    //   to: "/permission-groups",
    //   // icon: <Icon prefix={"far"} name={"th-large"} />,
    //   // rightEl: "→",
    //   asLink: false,
    // },
  ];
  const reportLinks: LinkObject[] = [
    // {
    //   text: "Activity Report",
    //   to: "/activity-report",
    //   // icon: <Icon prefix={"far"} name={"th-large"} />,
    //   // rightEl: "→",
    //   asLink: false,
    // },
    {
      text: "Assets History",
      to: "/assets-history",
      // icon: <Icon prefix={"far"} name={"th-large"} />,
      // rightEl: "→",
      asLink: false,
    },
  ];
  const companyLinks: LinkObject[] = [
    {
      text: "Companies",
      to: "/companies",
      // icon: <Icon prefix={"far"} name={"th-large"} />,
      // rightEl: "→",
      asLink: false,
    },
  ];
  const settingLinks: LinkObject[] = [
    {
      text: "Categories",
      to: "/categories",
      // icon: <Icon prefix={"far"} name={"th-large"} />,
      // rightEl: "→",
      asLink: false,
    },
    // {
    //   text: "Status Labels",
    //   to: "/status-labels",
    //   // icon: <Icon prefix={"far"} name={"th-large"} />,
    //   // rightEl: "→",
    //   asLink: false,
    // },
  ];

  const myAssetsLinks: LinkObject[] = [
    {
      text: "My Assets",
      to: "/my-assets",
      asLink: false,
    },
  ];

  const myAssetsHistory: LinkObject[] = [
    {
      text: "My Assets Report",
      to: "/my-history",
      asLink: false,
    },
  ];

  const links =
    auth.data.user.user_role == USER_ROLE.SUPERUSER
      ? [
          {
            title: "Assets",
            links: assetLinks,
          },
          {
            title: "Users",
            links: userLinks,
          },
          {
            title: "Reports",
            links: reportLinks, //reportLinks
          },
          {
            title: "Companies",
            links: companyLinks,
          },
          {
            title: "General Settings",
            links: settingLinks,
          },
        ]
      : auth.data.user.user_role == USER_ROLE.EMPLOYER
      ? [
          {
            title: "Assets",
            links: [...assetLinks, ...myAssetsLinks],
          },
          {
            title: "Users",
            links: userLinks,
          },
          {
            title: "Reports",
            links: reportLinks, //reportLinks
          },
          {
            title: "General Settings",
            links: settingLinks,
          },
        ]
      : [
          {
            title: "Assets",
            links: myAssetsLinks,
          },
          {
            title: "Reports",
            links: myAssetsHistory,
          },
        ];

  return (
    <>
      {/* {!auth.data.user.account.confirmed && <VerifyEmail />} */}
      <ContainerHorizontal>
        {/* <Navigation match={match} links={links} /> */}
        <ContentPanel>
          <ContainerVertical>
            <TopBar rootRouteMap={rootRouteMap} />
            <ContainerHorizontal>
              <Switch>
                {/* <Route component={Home} exact path={`${match.url}`} strict /> */}

                <>
                  <Route
                    component={PagePersonalInformation}
                    path={`${match.path}/personal`}
                  />

                  <Route
                    component={PageFellows}
                    path={`${match.path}/fellows`}
                  />
                  <Route component={PageUsers} path={`${match.path}/users`} />
                  <Redirect to={`${match.url}/fellows/list`} /> 
                </>
              </Switch>
            </ContainerHorizontal>
          </ContainerVertical>
        </ContentPanel>
      </ContainerHorizontal>
    </>
  );
};

export default Dashboard;
