// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import { Icon, Button } from 'components';

import cls from './modal.module.scss';

type ModalType = {
  className?: string;
  children?: React.ReactNode;
  isOpen?: Boolean;
  handleClose?: () => void;
};

const Modal = ({
  className,
  children,
  isOpen,
  handleClose,
  ...rest
}: ModalType) => {
  const appRoot = document.body;

  return isOpen && appRoot
    ? ReactDOM.createPortal(
        <div className={cls.modalWrapper}>
          <div className={classnames(className, cls.wrapper)} {...rest}>
            <div>{children}</div>
            <Button
              className={cls.closeButton}
              clear
              iconOnly
              onClick={handleClose}
              icon={
                <Icon prefix={'far'} name={'fa-solid fa-xmark'} className={cls.closeIcon}/>
              }
            />
          </div>
          <div className={cls.outer} onClick={handleClose} />
        </div>,
        appRoot,
      )
    : null;
};

Modal.defaultProps = {
  className: null,
  children: null,
  isOpen: false,
};

export default Modal;
