// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authActions from '@redux/auth/actions';
import { Formik, FormikProps } from 'formik';
import { WidgetContent, Input, FormInputGroup, Button } from 'components';

import { AppState } from '@redux';
import { authState } from '@redux/auth/types';

import SimpleContainer from '../components/SimpleContainer';

import PwdResetSchema from './passwordResetReq';

/**
 * Type of Formvalues
 */
interface FormValues {
  email: string;
}

/**
 * Type of props
 */
type PagePasswordResetProps = {
  passwordResetRequest: Function; // password reset request action
  auth: authState; // store
  /**
  * from Router
  */
  location: Object;
};

const PagePasswordResetReq = ({
  passwordResetRequest,
  auth,
  location,
}: PagePasswordResetProps) => {
  const [hasRequested, setHasRequested] = React.useState(false);

  // Inital formvalues
  const initialValues: FormValues = {
    email: '',
  };

  const handleClickReset = (data, actions) => {
    passwordResetRequest(data)
      .then(res => {
        setHasRequested(true);
        return res;
      })
      .catch(err => {
        if (err.status === 404 && err.error === 'invalid_email') {
          actions.setFieldError('email', err.message);
        }
      });
  };

  return (
    <React.Fragment>
      <SimpleContainer>
        {hasRequested ? (
          <WidgetContent>
            <h2>You have requested a password reset</h2>
            <p>If email exist, you can check your email for a reset link!</p>
          </WidgetContent>
        ) : (
          <WidgetContent>
            <h2>Password reset</h2>
            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={PwdResetSchema}
              onSubmit={handleClickReset}
            >
              {({
                errors,
                touched,
                handleChange,
                handleSubmit,
                values,
                handleBlur,
                setErrors,
              }: FormikProps<FormValues>) => (
                <form onSubmit={handleSubmit}>
                  <FormInputGroup>
                    <Input
                      label="E-mail address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="your@email.com"
                      errorMessage={
                        errors.email && touched.email ? errors.email : null
                      }
                    />

                    <Button isLoading={auth.isLoading} primary type="submit">
                      Reset Password
                    </Button>
                  </FormInputGroup>
                </form>
              )}
            </Formik>
          </WidgetContent>
        )}
      </SimpleContainer>

      <p>
        {'Want to log in? '}
        <Link to={"/auth/sign-in" + location['search']}>Go to login page.</Link>
      </p>
    </React.Fragment>
  );
};

export default connect(
  (state: AppState) => ({
    auth: state.auth,
  }),
  {
    passwordResetRequest: authActions.passwordResetRequest,
  },
)(PagePasswordResetReq);
