// @flow
import * as React from 'react'
import classnames from 'classnames'

import cls from './divider.module.scss'

export type DividerProps = {
  className?: string,
  direction?: 'vertical' | 'horizontal' | string,
  margin?: boolean,
  vertical?: boolean,
}

export const Divider = ({
  className,
  direction,
  vertical,
  margin,
  ...rest
}: DividerProps) => (
    <div
      className={classnames({
        [cls.vertical]: vertical || direction === 'vertical',
        [cls.horizontal]: !(vertical || direction === 'vertical'),
        [cls.margin]: margin,
      }, className)}
      {...rest}
    />
  )

Divider.defaultProps = {
  className: null,
  direction: 'horizontal',
  margin: false,
  vertical: false,
}

export default Divider
