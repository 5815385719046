import * as React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import cls from "./style.module.scss";
import { AppConfig, APP_PLAN_COLOR } from "data/apps";
import { App } from "@redux/apps/types";

import Widget from "components/Widget";
import Icon from "components/Icon";
import Badge from "components/Badge";
import Button from "components/Button";
import FormInputGroup from "components/FormInputGroup";

type TabsPageProps = {
  tabName: string;
  onClickTab: Function;
  title?: any;
};

const TabsPage = ({ title, tabName, onClickTab }: TabsPageProps) => {
  return (
    <div className={cls.main}>
      {tabName === "basic" && (
        <>
          <div
            className={`${cls.tabItem} ${cls.selected}`}
            onClick={() => {
              onClickTab("basic");
            }}
          >
            <img src={"/assets/images/basic_info_select.png"} />
            <p>STAGE 1</p>
          </div>
          <div
            className={`${cls.tabItem}`}
            onClick={() => {
              onClickTab("fellow");
            }}
          >
            <img src={"/assets/images/fellow_info_unselect.png"} />
            <p>STAGE 2</p>
          </div>
          <div
            className={`${cls.tabItem} `}
            onClick={() => {
              onClickTab("practice");
            }}
          >
            <img
              src={"/assets/images/practice_info_unselect.png"}
              style={{ marginBottom: "10px" }}
            />
            <p>STAGE 3</p>
          </div>
        </>
      )}
      {tabName === "fellow" && (
        <>
          <div
            className={`${cls.tabItem} `}
            onClick={() => {
              onClickTab("basic");
            }}
          >
            <img src={"/assets/images/basic_info_unselect.png"} />
            <p>STAGE 1</p>
          </div>
          <div
            className={`${cls.tabItem} ${cls.selected}`}
            onClick={() => {
              onClickTab("fellow");
            }}
          >
            <img src={"/assets/images/fellow_info_select.png"} />
            <p>STAGE 2</p>
          </div>
          <div
            className={`${cls.tabItem} `}
            onClick={() => {
              onClickTab("practice");
            }}
          >
            <img
              src={"/assets/images/practice_info_unselect.png"}
              style={{ marginBottom: "10px" }}
            />
            <p>STAGE 3</p>
          </div>
        </>
      )}
      {tabName === "practice" && (
        <>
          <div
            className={`${cls.tabItem} `}
            onClick={() => {
              onClickTab("basic");
            }}
          >
            <img src={"/assets/images/basic_info_unselect.png"} />
            <p>STAGE 1</p>
          </div>
          <div
            className={`${cls.tabItem}`}
            onClick={() => {
              onClickTab("fellow");
            }}
          >
            <img src={"/assets/images/fellow_info_unselect.png"} />
            <p>STAGE 2</p>
          </div>
          <div
            className={`${cls.tabItem} ${cls.selected}`}
            onClick={() => {
              onClickTab("practice");
            }}
          >
            <img
              src={"/assets/images/practice_info_select.png"}
              style={{ marginBottom: "10px" }}
            />
            <p>STAGE 3</p>
          </div>
        </>
      )}
    </div>
  );
};
export default TabsPage;
