import * as Yup from "yup";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(50, "Too Long!")
    .trim()
    // .matches(/^[aA-zZ]+$/, "Only characters accepted")
    .required("First name is required"),
  last_name: Yup.string()
    .max(50, "Too Long!")
    .trim()
    // .matches(/^[aA-zZ ]+$/, "Only characters and spaces accepted")
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address!")
    .required("Email is required."),
  phone: Yup.string().trim().required("Phone number is required."),
  specialty: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("Specialty is required."),
  fellowship_program_name: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("Program name is required."),
  fellowship_city: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("City is required."),
  fellowship_state: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("City is required."),
  npi_number: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("NPI number is required."),
  territory: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("Territory is required"),
  found_position: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("This field is required"),

});

export default PersonalInfoSchema;
