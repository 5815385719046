// import './icomoon/style.scss';
import './icon.scss';
// import '../../vars.scss';
// import '../../font.scss';

import * as React from 'react';
import classnames from 'classnames';

import { AnyTag, WithTagProps } from '../utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { IconPrefix,IconName } from '@fortawesome/fontawesome-common-types';

type IconProps = React.PropsWithChildren<{
  className?: string;
  ml?: boolean;
  mr?: boolean;
  mui?: boolean;
  prefix?: IconPrefix;
  name?: string;
  size?: string;
  style?:any;
}>;

export function Icon<Tag extends AnyTag<'i'>>(
  props: WithTagProps<Tag, IconProps> & JSX.IntrinsicElements['i'],
);

export function Icon({ className, children, Component, ml, mr, mui, prefix, name, style, ...rest }) {
  return (
      (prefix) ?  <><FontAwesomeIcon icon={[prefix as IconPrefix, name as IconName]} style={{marginLeft: '5px', marginRight: '5px', ...(style || {})}} {...rest}/>{'  '}</>
      :
    <Component
      className={classnames(mui ? 'material-icons' : 'uikon', className, {
        ml,
        mr,
      })}
      {...rest}
    >
      {children}
    </Component>
  );
}

Icon.defaultProps = {
  className: '',
  mr: false,
  ml: false,
  Component: 'i',
};

export default Icon;
