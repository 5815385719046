import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, FormikProps } from "formik";
// import FormData from "form-data";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { RouteComponentProps, Redirect, NavLink } from "react-router-dom";
import { useRedux, useReduxLoading } from "@redux";
import clientFetch, { imageUpload } from "@redux/@thunker/clientFetch";
import createFetch from "@redux/@thunker/fetch";
import ButtonDeleteUser from "../components/ButtonDeleteUser";

import { DropdownMenuPosition } from "@duik/it";
import {
  WidgetContent,
  Widget,
  Input,
  FieldsetInput,
  FieldsetTextarea,
  FormInputGroup,
  Button,
  SelectSearch,
  Select,
  ContentLoader,
  ContainerVertical,
  Checkbox,
} from "components";
import { AppState } from "@redux";
import * as authActions from "@redux/auth/actions";
// import { authState } from "@redux/auth/types";
import { API_URL, USER_ROLE } from "types/general";
import { FellowInput } from "@redux/fellows/types";
import { createFellow, updateFellow } from "@redux/fellows/actions";
import PersonalInfoSchema from "./validatePersonalInfo";

import ScrollArea from "components/ScrollArea";
import PageTitle from "../components/AddPageTitle";
import ImageTabs from "../components/ImageTabs";
import cls from "./user.module.scss";
import { capitalize } from "lodash";
import axios from "axios";

/**
 * Type of Formvalues
 */
type FormValues = Omit<FellowInput, "id"> & {
  id?: number | string;
  territory: number | string;

};

/**
 * Type of Props
 */
export type PageNewUserProps = RouteComponentProps<{}> & {};

const PageNewUser = () => {
  const userState = useRedux("fellows");
  const authState = useRedux("auth");
  const fellowsState = useRedux("fellows");
  const territory_options = [
    { value: 'Bay Area', label: 'Bay Area', },
    { value: 'Bluegrass', label: 'Bluegrass', },
    { value: 'Brass Valley', label: 'Brass Valley', },
    { value: 'Canada', label: 'Canada', },
    { value: 'Outside of U.S.', label: 'Outside of U.S.', },
    { value: 'Delta', label: 'Delta', },
    { value: 'East Florida', label: 'East Florida', },
    { value: 'Empire', label: 'Empire', },
    { value: 'Great Lakes', label: 'Great Lakes', },
    { value: 'Great Plains', label: 'Great Plains', },
    { value: 'Heartland', label: 'Heartland', },
    { value: 'Indiana', label: 'Indiana', },
    { value: 'Liberty', label: 'Liberty', },
    { value: 'National Capital', label: 'National Capital', },
    { value: 'New England', label: 'New England', },
    { value: 'Northern', label: 'Northern', },
    { value: 'North Texas', label: 'North Texas', },
    { value: 'Northwest', label: 'Northwest', },
    { value: 'North Woods', label: 'North Woods', },
    { value: 'Ohio Valley', label: 'Ohio Valley', },
    { value: 'Pacific Coast', label: 'Pacific Coast', },
    { value: 'Pacific Desert', label: 'Pacific Desert', },
    { value: 'Pacific Mountain', label: 'Pacific Mountain', },
    { value: 'Rocky Mountain', label: 'Rocky Mountain', },
    { value: 'Sierra Nevada', label: 'Sierra Nevada', },
    { value: 'South Atlantic', label: 'South Atlantic', },
    { value: 'Southern', label: 'Southern', },
    { value: 'Southern California', label: 'Southern California', },
    { value: 'South Texas', label: 'South Texas', },
    { value: 'Southwest', label: 'Southwest', },
    { value: 'Steel Cities', label: 'Steel Cities', },
    { value: 'Tar Heel', label: 'Tar Heel', },
    { value: 'Virginias', label: 'Virginias', },
    { value: 'West Florida', label: 'West Florida', },
    { value: 'West Texas', label: 'West Texas', },
    { value: 'Windy City', label: 'Windy City', },
  ];

  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(getUsers());
  // }, []);
  // if (useReduxLoading("users")) {
  //   return <ContentLoader>Fetching Users</ContentLoader>;
  // }

  const [isLoading, setIsLoading] = React.useState(false);
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const [keyword, setKword] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const [tabName, setTabName] = React.useState("basic");
  const [tabTitle, setTabTitle] = React.useState("Fellow's basic info");
  const [headshot, setHeadshot] = React.useState(undefined);
  const [isImgUploading, setIsImgUploading] = React.useState(false);

  const [fellowCreated, setFellowCreated] = React.useState(false);
  const [currentFellow, setCurrentFellow] = React.useState({});
  const [currentId, setCurrentId] = React.useState();
  const [isHome, goHome] = React.useState(false);
  const [sectionName, goTo] = React.useState('');
  const [buttonLabel, setButtonLabel] = React.useState("CREATE FELLOW");
  const [isConfirmation, setIsConfirmation] = React.useState(false);
  const [submitErrors, setSubmitErrors] = React.useState({ found_position: false, territory: false });



  const [tabDescription, setTabDescription] =
    React.useState(`Get started by entering your fellow's contact information, so we
  have a record of who they are and where their fellowship is
  located`);

  const [isFoundPosition, setIsFoundPosition] = React.useState(false);
  const [isErrors, setIsErrors] = React.useState(true);
  React.useEffect(() => {
    if (!userState.isCreating) {
      //handleTabClick("fellow");
    } else {

    }
  }, [userState.isCreating]);


  // Inital formvalues
  const initialValues: FormValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    //international: false,
    specialty: "",
    fellowship_program_name: "",
    fellowship_city: "",
    fellowship_state: "",
    //fellowship_country: "",
    //medical_school: "",
    //residency: "",
    //hometown: "",
    //hobbies: "",
    //family_info: "",
    npi_number: "",
    found_position: '',
    //publication_links: "",
    //product_landscape: "",
    //product_video_platform: "",
    //important_competitive_history: "",
    //cross_divisional_leverage: "",
    other_products_used: "",
    //pain_points: "",
    //additional_notes: "",
    practice_name: "",
    practice_city: "",
    practice_state: "",
    practice_country: "",
    practice_start_date: "",
    //equipment_requests: "",
    territory: "",
    created_by: authState.data.user.email


  };

  const handleTabClick = (tab) => {
    setTabName(tab);
    switch (tab) {
      case "basic":
        setTabTitle("Fellow's basic info");
        setTabDescription(`Get started by entering your fellow's contact information, so we
        have a record of who they are and where their fellowship is
        located`);
        break;
      case "fellow":
        setTabTitle("Fellow info");
        setTabDescription(
          `As you follow up with your fellows and identify they are moving, update their information, so we can track where they're planning to go. This stage update must be approved.`
        );
        break;
      case "practice":
        setTabTitle("Fellowship / Practice info");
        setTabDescription(
          `Once your fellow has actually moved to their post fellowship facility, confirm their new information and make any changes to their profile. This stage update must be approved.`
        );
        break;
      default:
        setTabTitle("Basic info");
        setTabDescription(`Get started by entering your fellow's contact information, so we
        have a record of who they are and where their fellowship is
        located`);
        break;
    }
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == "done") {
      setHeadshot(file);
    } else if (status == "removed") {
      setHeadshot(undefined);
    }
  };

  // Handle update
  const handleCreate = async (data, { resetForm }) => {

    // if (tabName === "basic") {
    //   setTabName("fellow");
    //   return;
    // }

    // 'data' is all the form values.

    const basicInfo: any = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      //international: data.international,
      specialty: data.specialty,
      fellowship_program_name: data.fellowship_program_name,
      fellowship_city: data.fellowship_city,
      fellowship_state: data.fellowship_state,
      //fellowship_country: data.fellowship_country,
      territory: data.territory,
      found_position: data.found_position,
      created_by: authState.data.user.email
    };

    const fellowInfo = {
      //medical_school: data.medical_school,
      //residency: data.residency,
      //hometown: data.hometown,
      //hobbies: data.hobbies,
      //family_info: data.family_info,
      npi_number: data.npi_number,
      //publication_links: data.publication_links,
      //product_landscape: data.product_landscape,
      other_products_used: data.other_products_used,
      //product_video_platform: data.product_video_platform,
      //important_competitive_history: data.important_competitive_history,
      //cross_divisional_leverage: data.cross_divisional_leverage,
      //pain_points: data.pain_points,
      //additional_notes: data.additional_notes,
    };

    const fellowshipInfo = {
      practice_name: data.practice_name,
      practice_city: data.practice_city,
      practice_state: data.practice_state,
      //practice_country: data.practice_country,
      practice_start_date: data.practice_start_date,
      //equipment_requests: data.equipment_requests,
    };
    let updateData = { basicInfo, fellowInfo, fellowshipInfo };
    //console.log("~~~data: ", headshot);
    if (headshot) {
      setIsImgUploading(true);
      const formData = new FormData();
      formData.append("file", headshot);
      let imageUrl = await imageUpload("fellows/image-upload", formData);
      //console.log("~~~~image url: ", imageUrl);
      basicInfo.headshot = imageUrl.data;
      setIsImgUploading(false);
      setHeadshot(undefined);
    }



    if (fellowCreated) {
      dispatch(updateFellow(currentId, updateData, () => { }));

      isHome && window.location.assign("/app/fellows/list");
    } else if (isFoundPosition && tabName === "basic") {
      handleTabClick("practice");

    } else {
      dispatch(createFellow(updateData,
        (r) => {
          if (r) {
            // handleTabClick("fellow");
            /* setCurrentFellow(r);
            setFellowCreated(true);
            setCurrentId(r.id);

            window.location.assign("/app/fellows/list"); */
            if (!isFoundPosition) {
              setIsConfirmation(true);
              setTabTitle("Fellow Added");
              setTabDescription(`Thank you for submitting your fellow's information. Please enter his job details by clicking "Edit" on your existing entry once they have finalized their position`);
            } else {
              window.location.assign("/app/fellows/list");

            }
          }
        }));

    }

    /*  if (sectionName == "practice") {
       handleTabClick(sectionName);
     } */
  };


  return (
    <ContainerVertical>
      <ScrollArea>
        <PageTitle subTitle={"ADD A FELLOW"} />
        {isConfirmation ? (
          <div className={cls.registerWrapper}>
            <WidgetContent className={cls.fixedWidth}>
              <div className={cls.tabHeader}>
                <h1 className={cls.tabTitle}>{tabTitle}</h1>
                <p className={cls.tabDescription}>{tabDescription}</p>

              </div>
              <div style={{ width: 250, marginLeft: 'auto', marginTop: 20 }} className={cls.btnGroup}>

                <Button
                  primary
                  className={cls.yellowBtn}
                  Component={NavLink}
                  to={`./list`}
                >

                  GO BACK TO HOME PAGE
                </Button>
              </div>
            </WidgetContent>

          </div>


        ) :
          (<div className={cls.registerWrapper}>
            <WidgetContent className={cls.fixedWidth}>
              <div className={cls.tabHeader}>
                <h1 className={cls.tabTitle}>{tabTitle}</h1>
                <p className={cls.tabDescription}>{tabDescription}</p>
                <ImageTabs isCreateNew={true} isFoundPosition={isFoundPosition && !isErrors && !submitErrors.found_position && !submitErrors.territory} tabName={tabName} onClickTab={handleTabClick} />
              </div>

              <br />
              <Formik
                initialValues={initialValues}
                validationSchema={PersonalInfoSchema}
                // onReset
                onSubmit={handleCreate}
              >
                {({
                  errors,
                  touched,
                  setErrors,
                  setTouched,
                  validateForm,
                  resetForm,
                  submitForm,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setValues,
                  values,
                }: FormikProps<FormValues>) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <FormInputGroup className={cls.formInputGroup}>
                        {tabName === "basic" && (
                          <>
                            <FieldsetInput
                              label="First name*"
                              name="first_name"
                              className={cls.inputField}
                              onChange={(e) => {

                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);
                              }}
                              onBlur={handleBlur}
                              errorMessage={
                                errors.first_name && touched.first_name
                                  ? errors.first_name
                                  : null
                              }
                              value={values.first_name}
                            />
                            <FieldsetInput
                              label="Last name*"
                              name="last_name"
                              className={cls.inputField}
                              onChange={(e) => {

                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                              onBlur={handleBlur}
                              errorMessage={
                                errors.last_name && touched.last_name
                                  ? errors.last_name
                                  : null
                              }
                              value={values.last_name}
                            />
                            <FieldsetInput
                              label="Email*"
                              name="email"
                              type="email"
                              className={cls.inputField}
                              value={values.email}
                              onChange={(e) => {

                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                              onBlur={handleBlur}
                              errorMessage={
                                errors.email && touched.email
                                  ? errors.email
                                  : null
                              }
                            />
                            <FieldsetInput
                              label="Phone*"
                              name="phone"
                              className={cls.inputField}
                              onChange={(e) => {
                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                              onBlur={handleBlur}
                              errorMessage={
                                errors.phone && touched.phone
                                  ? errors.phone
                                  : null
                              }
                              value={values.phone}
                            />
                            {/* Headshot here */}
                            {/* <Dropzone
                            initialFiles={headshot ? [headshot] : null}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                            inputContent={`Drag and drop your photo here, or browser`}
                            onChangeStatus={handleChangeStatus}
                            accept="image/*"
                          /> */}
                            {/*  <Checkbox
                            name={"international"}
                            onChange={handleChange}
                            label={<>International</>}
                            className={cls.checkbox}
                            checked={values.international}
                          /> */}
                            <FieldsetInput
                              label="Specialty*"
                              name="specialty"
                              className={cls.inputField}
                              onChange={(e) => {
                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                              onBlur={handleBlur}
                              errorMessage={
                                errors.specialty && touched.specialty
                                  ? errors.specialty
                                  : null
                              }
                              value={values.specialty}
                            />
                            <FieldsetInput
                              label="Fellowship program name*"
                              name="fellowship_program_name"
                              className={cls.inputField}
                              onChange={(e) => {

                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                              errorMessage={
                                errors.fellowship_program_name && touched.fellowship_program_name
                                  ? errors.fellowship_program_name
                                  : null
                              }
                              onBlur={handleBlur}
                              value={values.fellowship_program_name}
                            />
                            <FieldsetInput
                              label="Fellowship city*"
                              name="fellowship_city"
                              className={cls.inputField}
                              onChange={(e) => {
                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                              onBlur={handleBlur}
                              /*  errorMessage={
                                 errors.fellowship_city && touched.fellowship_city
                                   ? errors.fellowship_city
                                   : null
                               } */
                              errorMessage={
                                errors.fellowship_city && touched.fellowship_city
                                  ? errors.fellowship_city
                                  : null
                              }
                              value={values.fellowship_city}
                            />

                            {values.international ? (
                              <>
                                {" "}
                                <FieldsetInput
                                  label="Fellowship state"
                                  name="fellowship_state"
                                  className={cls.inputField}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errorMessage={
                                    errors.fellowship_state &&
                                      touched.fellowship_state
                                      ? errors.fellowship_state
                                      : null
                                  }
                                  value={values.fellowship_state}
                                />
                                <FieldsetInput
                                  label="Fellowship country"
                                  name="fellowship_country"
                                  className={cls.inputField}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errorMessage={
                                    errors.fellowship_country &&
                                      touched.fellowship_country
                                      ? errors.fellowship_country
                                      : null
                                  }
                                  value={values.fellowship_country}
                                />
                              </>
                            ) : (
                              <FieldsetInput
                                label="Fellowship state*"
                                name="fellowship_state"
                                className={cls.inputField}
                                onChange={(e) => {
                                  handleChange(e);
                                  validateForm();
                                  setIsErrors(Object.keys(errors).length !== 0);

                                }}
                                onBlur={handleBlur}
                                value={values.fellowship_state}
                                errorMessage={
                                  errors.fellowship_state && touched.fellowship_state
                                    ? errors.fellowship_state
                                    : null
                                }
                              />
                            )}
                            <FieldsetInput
                              label={<span dangerouslySetInnerHTML={{ __html: "NPI NUMBER | FIND YOUR NPI NUMBER ON: <a target='_blank' href='https://NPIDB.org/NPI-LOOKUP/'>NPIDB.ORG/NPI-LOOKUP/</a>*" }}></span>}
                              name="npi_number"
                              onChange={(e) => {
                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                              errorMessage={
                                errors.npi_number && touched.npi_number
                                  ? errors.npi_number
                                  : null
                              }
                              onBlur={handleBlur}
                              value={values.npi_number}
                            />

                            <label>TERRITORY*</label>

                            <Select
                              options={territory_options}
                              activeOption={territory_options[values.territory]}
                              defaultOption={{
                                value: "",
                                label: "Select Territory",
                              }}

                              onChange={(option) => {
                                setValues({
                                  ...values,
                                  territory: option.value,
                                });

                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);

                              }}
                            />
                            {submitErrors.territory && (
                              <p style={{ color: '#e6492d', marginTop: '6px', lineHeight: '1.375rem' }}>This field is required.</p>)
                            }
                            <FieldsetTextarea
                              label="Please list the Stryker products your fellow has had exposure to:"
                              name="other_products_used"
                              onChange={(e) => {
                                handleChange(e);
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);
                              }}
                              onBlur={handleBlur}
                              value={values.other_products_used}
                            />

                            <label>Has the fellow found a position?*</label>
                            <Select
                              options={[{ value: 'Yes', label: 'Yes' }, { value: "No", label: 'No' }]}
                              activeOption={{ value: values.found_position, label: values.found_position }}
                              onChange={(option) => {
                                setValues({
                                  ...values,
                                  found_position: option.value,
                                });
                                setIsFoundPosition(option.value === "Yes");
                                setButtonLabel(option.value === "Yes" ? "CONTINUE TO POSITION DETAILS" : 'CREATE AND SAVE FELLOW');
                                validateForm();
                                setIsErrors(Object.keys(errors).length !== 0);
                              }}
                            />
                            {submitErrors.found_position && (
                              <p style={{ color: '#e6492d', marginTop: '6px', lineHeight: '1.375rem' }}>This field is required.</p>)
                            }
                          </>
                        )}

                        {tabName === "fellow" && (
                          <>
                            <label className={cls.groupLabel}>
                              <b>Background</b>
                            </label>
                            <FieldsetInput
                              label="Medical school"
                              name="medical_school"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.medical_school}
                            />
                            <FieldsetInput
                              label="Residency"
                              name="residency"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.residency}
                            />
                            <label className={cls.groupLabel}>
                              <b>Personal info</b>
                            </label>
                            <FieldsetInput
                              label="Hometown"
                              name="hometown"
                              className={cls.inputField}
                              value={values.hometown}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <FieldsetInput
                              label="Hobbies"
                              name="hobbies"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.hobbies}
                            />
                            <FieldsetInput
                              label="Family Information"
                              name="family_info"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.family_info}
                            />
                            <FieldsetInput
                              label={<span dangerouslySetInnerHTML={{ __html: "NPI NUMBER | FIND YOUR NPI NUMBER ON: <a target='_blank' href='https://NPIDB.org/NPI-LOOKUP/'>NPIDB.ORG/NPI-LOOKUP/</a>" }}></span>}
                              name="npi_number"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.npi_number}
                            />
                            <label className={cls.groupLabel}>
                              <b>Research Interests & Publications</b>
                            </label>
                            <FieldsetTextarea
                              label="Links to publications this fellow has published"
                              name="publication_links"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.publication_links}
                            />

                            <label className={cls.groupLabel}>
                              <b>Program Info</b>
                            </label>
                            <FieldsetInput
                              label="Current Product landscape"
                              name="product_landscape"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.product_landscape}
                            />
                            <FieldsetInput
                              label="Current video platform at current program"
                              name="product_video_platform"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.product_video_platform}
                            />
                            <FieldsetInput
                              label="Important competitive history"
                              name="important_competitive_history"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.important_competitive_history}
                            />
                            <FieldsetInput
                              label="Other Stryker Products Used"
                              name="other_products_used"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.other_products_used}
                            />
                            <FieldsetInput
                              label="Pain points"
                              name="pain_points"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.pain_points}
                            />

                            <FieldsetTextarea
                              label="Additional notes"
                              name="additional_notes"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.additional_notes}
                            />
                          </>
                        )}

                        {tabName === "practice" && (
                          <>
                            <label className={cls.groupLabel}>
                              <b>Practice info</b>
                            </label>
                            <FieldsetInput
                              label="Fellow's hospital placement"
                              name="practice_name"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.practice_name}
                            />
                            <FieldsetInput
                              label="Fellow's placement city"
                              name="practice_city"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.practice_city}
                            />
                            <FieldsetInput
                              label="Fellow's placement state"
                              name="practice_state"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.practice_state}
                            />

                            <FieldsetInput
                              label="Fellow's placement country"
                              name="practice_country"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.practice_country}
                            />
                            <FieldsetInput
                              label="Fellow's start date"
                              name="practice_start_date"
                              type='Date'
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.practice_start_date}
                            />
                            {/*
                          <label className={cls.groupLabel}>
                            <b>Equipment requests</b>
                          </label>
                          <FieldsetInput
                            label="What equipment, if any, are they requesting at their facility?"
                            name="equipment_requests"
                            className={cls.inputField}
                            value={values.equipment_requests}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          /> */}
                          </>
                        )}

                        <br />
                        <FormInputGroup direction={"horizontal"}>
                          {tabName === "basic" && (
                            <>
                              <Button
                                Component={NavLink}
                                to={"/app/fellows/list"}
                                className={cls.cancelBackButton}
                              >
                                {`CANCEL`}
                              </Button>
                              <Button
                                isLoading={userState.isCreating}
                                className={`${cls.yellowBtn} ${cls.defaultFont}`}
                                primary
                                type="submit"
                                onClick={() => {
                                  /*  if (isFoundPosition) {
                                     handleTabClick("practice");
                                   } else {
                                     goHome(true);
                                   } */

                                  validateForm().then((errs) => {
                                    setSubmitErrors({ found_position: errs.found_position ? true : false, territory: errs.territory ? true : false });

                                    if (
                                      Object.keys(errs).length === 0 &&
                                      errs.constructor === Object
                                    ) {
                                      // submitForm();
                                      //setTabName('fellow'); 
                                    } else {
                                      setErrors(errs);
                                      let fieldList: any = {};
                                      Object.keys(errs).map((err) => {
                                        fieldList[err] = true;
                                      });
                                      setTouched({ ...touched, ...fieldList });
                                      // setTabName("basic");
                                    }
                                  });
                                }}
                              >
                                {/* {`SAVE & CONTINUE >`} */}
                                {/* {`Save & EXIT`} */}
                                {/*  {`CREATE FELLOW`} */}
                                {buttonLabel}
                              </Button>
                            </>
                          )}
                          {tabName === "fellow" && (
                            <>
                              <div>
                                <Button
                                  // Component={NavLink}
                                  className={`${cls.defaultFont} ${cls.cancelBackButton}`}
                                  onClick={() => {
                                    handleTabClick("basic");
                                  }}

                                >
                                  {`GO BACK`}
                                </Button>
                              </div>
                              <div className={cls.btnGroup}>
                                <Button
                                  isLoading={userState.isCreating}
                                  className={`${cls.yellowBtn} ${cls.defaultFont}`}

                                  type="submit"
                                  onClick={() => {
                                    goHome(true);
                                    validateForm().then((errs) => {
                                      if (
                                        Object.keys(errs).length === 0 &&
                                        errs.constructor === Object
                                      ) {
                                        // submitForm();
                                      } else {
                                        setErrors(errs);
                                        let fieldList: any = {};
                                        Object.keys(errs).map((err) => {
                                          fieldList[err] = true;
                                        });
                                        setTouched({ ...touched, ...fieldList });
                                        setTabName("practice");
                                      }
                                    });
                                  }}
                                >
                                  {`SAVE`}
                                </Button>
                                <div>
                                  <Button
                                    isLoading={userState.isCreating}
                                    className={`${cls.cancelBackButton} ${cls.defaultFont}`}
                                    type="submit"
                                    onClick={() => {
                                      goHome(false);
                                      goTo("practice");
                                      validateForm().then((errs) => {
                                        if (
                                          Object.keys(errs).length === 0 &&
                                          errs.constructor === Object
                                        ) {
                                          // submitForm();
                                        } else {
                                          setErrors(errs);
                                          let fieldList: any = {};
                                          Object.keys(errs).map((err) => {
                                            fieldList[err] = true;
                                          });
                                          setTouched({ ...touched, ...fieldList });
                                          setTabName("practice");
                                        }
                                      });

                                    }}
                                  >
                                    {`CONTINUE TO STAGE 3`}
                                  </Button>
                                </div>
                              </div>


                            </>
                          )}
                          {tabName === "practice" && (
                            <>
                              <Button
                                // Component={NavLink}
                                className={`${cls.defaultFont} ${cls.cancelBackButton}`}
                                onClick={() => {
                                  handleTabClick("basic");
                                }}
                              >
                                {`BACK TO FELLOWSHIP DETAILS`}
                              </Button>
                              <Button
                                isLoading={userState.isCreating || isImgUploading}
                                className={`${cls.yellowBtn} ${cls.defaultFont}`}
                                primary
                                type="submit"
                                onClick={() => {
                                  // validateForm();
                                  goHome(true);
                                  validateForm().then((errs) => {
                                    if (
                                      Object.keys(errs).length === 0 &&
                                      errs.constructor === Object
                                    ) {
                                      // submitForm();
                                    } else {
                                      setErrors(errs);
                                      let fieldList: any = {};
                                      Object.keys(errs).map((err) => {
                                        fieldList[err] = true;
                                      });
                                      setTouched({ ...touched, ...fieldList });
                                      setTabName("basic");
                                    }
                                  });
                                }}
                              >
                                {`SAVE & RETURN TO HOME`}
                              </Button>
                            </>
                          )}
                        </FormInputGroup>
                      </FormInputGroup>
                    </form>
                  );
                }}
              </Formik>
            </WidgetContent>
          </div>)}
      </ScrollArea>
    </ContainerVertical >
  );
};

export default PageNewUser;
