import React, { ReactType } from 'react';
import { Button, Select, SelectProps, Option } from 'components';
import { DropdownItem } from '@duik/it';
import MultiSelectValues from '../MultiSelectValues';
import cls from './select-multi.module.scss';

export type SearchSelectProps = SelectProps & {
  inputSearchProps?: JSX.IntrinsicElements['input'];
  handleOptionSelect?: (option: Option, name?: string) => void;
  handleOptionDeselect?: (option: Option) => void;
  activeOptions?: Option[];
  onDark?: boolean;
};

export const OptionComponent = ({
  item,
  onClick,
}: {
  item: Option;
  onClick: () => void;
}) => (
  <DropdownItem
    onClick={onClick}
    // transparent
  >
    <div>
      {item.prefix && <span className={cls.optionPrefix}>{item.prefix}</span>}{' '}
      {item.label}
    </div>
  </DropdownItem>
);

export function MultiSelectPills(props: SearchSelectProps) {
  const {
    onDark,
    activeOptions,
    handleOptionDeselect,
    handleOptionSelect,
    inputSearchProps,
    ...rest
  } = props;
  return (
    <Select
      ButtonComponent={MultiSelectValues}
      buttonProps={{
        inputSearchProps,
        handleOptionDeselect,
        activeOptions,
        onDark,
      }}
      OptionComponent={OptionComponent}
      closeOnSelect={false}
      {...rest}
      onChange={handleOptionSelect}
    />
  );
}

MultiSelectPills.defaultProps = {
  inputSearchProps: {},
  activeOptions: [],
  onDark: false,
  handleOptionDeselect: () => {},
};

export default MultiSelectPills;
