import * as React from 'react';

import CheckboxHeader, { CheckboxHeaderProps } from 'components/CheckboxHeader';

export type ToggleHeaderProps = CheckboxHeaderProps;

export const ToggleHeader = (props: ToggleHeaderProps) => (
  <CheckboxHeader {...props} toggle />
);

export default ToggleHeader;
