// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as authActions from "@redux/auth/actions";
import { Formik } from "formik";
// import qs from 'query-string';
import { WidgetContent, Input, FormInputGroup, Button } from "components";

import SimpleContainer from "../components/SimpleContainer";

import cls from "./stryker-signin.module.scss";
import { ContainerVertical } from "@duik/it";

type PageSignInProps = {
  /**
   * injected rdx action
   */
  signIn: Function,
  /**
   * rdx store
   */
  auth: Object,
  /**
   * From router
   */
  history: any,
  location: Object,
  JFApp?: Boolean,
};

class PageSignIn extends React.PureComponent<PageSignInProps> {
  handleLogin = (data) => {
    const isPopup = this.props.history.location.pathname.match(
      /\/sign-inpopup/g
    );
    const { signIn } = this.props;
    signIn(data).then((r) => {
      if (isPopup && window.opener) {
        window.opener.postMessage({ user: r.value.user }, "*");
        window.close();
      }
    });
  };

  render() {
    const { auth, JFApp, location } = this.props;
    const JFAppContainerStyle = JFApp
      ? {
          margin: "0px",
          width: "524px",
          maxWidth: "100%",
          height: "290px",
          padding: "5px",
        }
      : {};
    const JFAppWidgetStyle = JFApp
      ? {
          padding: "10px",
        }
      : {};
    return (
      <React.Fragment>
        <SimpleContainer style={JFAppContainerStyle}>
          <WidgetContent style={JFAppWidgetStyle}>
            <div className={cls.header}>
              <div>
                <h2>Sign In</h2>
              </div>
              <div>
                {"Don't have an account yet? "}
                <Link
                  to={"/auth/sign-up" + (!JFApp && location.search)}
                  target={JFApp ? "_blank" : ""}
                >
                  Sign Up!
                </Link>
                {/* {JFApp && (
                  <p>
                    Did you{" "}
                    <Link to={"/auth/password-reset"} target="_blank">
                      forget your password?
                    </Link>
                  </p>
                )} */}
              </div>
            </div>
            <br />

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={this.handleLogin}
            >
              {({ handleChange, handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <FormInputGroup>
                    <Input
                      label="E-mail address"
                      name="email"
                      onChange={handleChange}
                      placeholder="your@email.com"
                      value={values.email}
                    />
                    <Input
                      autoComplete="new-password"
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                    />

                    <Button isLoading={auth.isLoading} primary type="submit">
                      Sign In
                    </Button>
                  </FormInputGroup>
                </form>
              )}
            </Formik>
          </WidgetContent>
          {/* <WidgetContent>
            <ContainerVertical className={cls.contactInfo}>
              <h5>Customer Service</h5>
              <p>support@stryker.com</p>
              <p>+1 203 532 0400</p>
            </ContainerVertical>
          </WidgetContent> */}
        </SimpleContainer>
        {/* {!JFApp && (
          <p>
            Did you <Link to="/auth/password-reset">forget your password?</Link>
          </p>
        )} */}
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  {
    signIn: authActions.signIn,
  }
)(PageSignIn);
