import React from "react";
import classnames from "classnames";
import cls from "./edit-table.module.scss";
import { Button } from "components";
export type LinkDataItem = {
  label: React.ReactNode;
  field: string;
  props?: JSX.IntrinsicElements["th"] | JSX.IntrinsicElements["td"];
};

export type EditTableProps = JSX.IntrinsicElements["table"] & {
  data: any[];
  columns: LinkDataItem[];
  large?: boolean;
  className?: string;
  trProps?: {
    onClick: (item: LinkDataItem) => void;
  } & Omit<JSX.IntrinsicElements["tr"], "onClick">;
  handleClickLabel?: (name: string) => void;
  activeColName?: string;
  activeColOrder?: "ASC" | "DESC";
  inContainer?: boolean;
  onEdit?: (i) => void;
};

export class EditTable<LinkDataItem> extends React.Component<EditTableProps> {
  render() {
    const {
      data,
      columns,
      large,
      className,
      trProps,
      handleClickLabel,
      activeColName,
      activeColOrder,
      inContainer,
      onEdit = (i) => {
        console.log(`edit for ${i} clicked`);
      },
      ...rest
    } = this.props;

    const displayCol = (item, field, index) => {
      if (field == "edit") {
        return (
          <Button primary onClick={() => onEdit(index)}>
            EDIT
          </Button>
        );
      }
      return item.hasOwnProperty(field) ? item[field] : "";
    };
    return (
      <table
        className={classnames(cls.table, className, {
          [cls.large]: large,
          [cls.inContainer]: inContainer,
        })}
        {...rest}
      >
        <thead>
          <tr>
            {columns.map(({ props, label, field }, index) => {
              const hasLabelClick = handleClickLabel;
              return (
                <th
                  key={index}
                  {...props}
                  className={classnames(props && props.className, {
                    [cls.click]: hasLabelClick,
                    [cls.activeCol]: false,
                  })}
                  onClick={
                    hasLabelClick ? () => handleClickLabel!(field!) : undefined
                  }
                >
                  {label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const onClickTr =
              trProps && trProps.onClick
                ? () => {
                    trProps.onClick!(item);
                  }
                : undefined;
            return (
              <tr key={index} {...trProps} onClick={onClickTr}>
                {columns.map((column, cindex) => (
                  <td key={cindex} {...column.props}>
                    {displayCol(item, column.field, index)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default EditTable;
