/*
 *
 * handles outside click events
 * e.g. we create custom Dropdown for select
 * we wrap the list with this component and add onOutsideClick
 * passing a close function
 *
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ClassAndChildrenProps } from '../utils';

import cls from './OutsideClickHandler.module.scss';

export type OutsideClickHandlerProps = ClassAndChildrenProps & {
  onOutsideScroll?: boolean;
  onWindowResize?: boolean;
  onOutsideClick?: EventListenerOrEventListenerObject | null;
  style?: React.CSSProperties;
};

class OutsideClickHandler extends React.PureComponent<
  OutsideClickHandlerProps & JSX.IntrinsicElements['div']
> {
  containerRef = React.createRef<HTMLDivElement>();

  static defaultProps = {
    children: null,
    onOutsideClick: null,
    onOutsideScroll: true,
    onWindowResize: true,
    className: null,
  };

  static propTypes = {
    children: PropTypes.node,
    /**
     * handler to be called when outside click event occurs
     * will receive event as a single param
     */
    onOutsideClick: PropTypes.func,
    /**
     * Whether the handler will be triggered on outer scroll as well
     */
    onOutsideScroll: PropTypes.bool,
    /**
     * Whether the handler will be triggered window resize
     */
    onWindowResize: PropTypes.bool,
    className: PropTypes.string,
  };

  componentDidMount() {
    const { onOutsideClick } = this.props;
    if (onOutsideClick) {
      this.bindListeners();
    }
  }

  componentDidUpdate(prevProps: OutsideClickHandlerProps) {
    const { onOutsideClick } = this.props;
    if (
      typeof onOutsideClick === 'function' &&
      prevProps.onOutsideClick !== onOutsideClick
    ) {
      this.bindListeners();
    } else if (typeof onOutsideClick !== 'function') {
      this.removeListeners();
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  handleOuterActions = (e: Event): void => {
    const { onOutsideClick } = this.props;
    if (typeof onOutsideClick === 'function') {
      e.preventDefault();
      onOutsideClick(e);
    }
  };

  handleOutsideClick = (e: Event): void => {
    const { onOutsideClick } = this.props;
    if (typeof onOutsideClick !== 'function') {
      return;
    }

    const containerEl = this.containerRef.current;

    const isDescendantOfRoot =
      !!e.target && containerEl && containerEl.contains(e.target as Node);
    if (!isDescendantOfRoot) {
      onOutsideClick(e);
    }
  };

  bindListeners = (): void => {
    const { onOutsideScroll, onWindowResize } = this.props;
    if (typeof document !== 'undefined') {
      // `useCapture` flag is set to true so
      // that a `stopPropagation` in the children will
      // not prevent all outside click handlers from firing
      document.addEventListener('click', this.handleOutsideClick, true);
      if (onOutsideScroll) {
        window.addEventListener('scroll', this.handleOuterActions, true);
      }
      if (onWindowResize) {
        window.addEventListener('resize', this.handleOuterActions, true);
      }
    }
  };

  removeListeners = (): void => {
    const { onOutsideScroll, onWindowResize } = this.props;
    if (typeof document !== 'undefined') {
      document.removeEventListener('click', this.handleOutsideClick, true);
      if (onOutsideScroll) {
        window.removeEventListener('scroll', this.handleOuterActions, true);
      }
      if (onWindowResize) {
        window.removeEventListener('resize', this.handleOuterActions, true);
      }
    }
  };

  render() {
    const {
      children,
      // onOutsideClick,
      // onOutsideScroll,
      // onWindowResize,
      className,
      style,
    } = this.props;

    return (
      <div
        className={classnames(cls.wrapper, className)}
        ref={this.containerRef}
        style={style}
      >
        {children}
      </div>
    );
  }
}

export default OutsideClickHandler;
