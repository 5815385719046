import * as React from 'react';
import { Icon } from 'components';
import { Button, OpenStateControls } from '@duik/it';

export const DropdownButtonConfig = (props: OpenStateControls) => {
  return ( !props['disable'] &&
    <Button onClick={props.handleToggle} clear>
      <Icon prefix={'far'} name={'cog'}/>
    </Button>
  );
};
