const RouteMap = {
  app: {
    routes: {
      assets: {
        title: "Assets",
      },
      users: {
        title: "Users",
      },
      categories: {
        title: "Categories",
      },
      companies: {
        title: "Companies",
      },
      settings: {
        title: "General Settings",
        routes: {
          param: {
            title: "Users Groups",
          },
        },
      },
      personal: {
        title: "Personal Settings",
      },
    },
  },
};

export default RouteMap;
