import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, FormikProps } from "formik";
// import FormData from "form-data";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { RouteComponentProps, Redirect, NavLink } from "react-router-dom";
import { useRedux, useReduxLoading } from "@redux";
import clientFetch, { imageUpload } from "@redux/@thunker/clientFetch";
import createFetch from "@redux/@thunker/fetch";
import { DropdownMenuPosition } from "@duik/it";
import {
  WidgetContent,
  Widget,
  Input,
  FieldsetInput,
  FieldsetTextarea,
  FormInputGroup,
  Button,
  SelectSearch,
  Select,
  ContentLoader,
  ContainerVertical,
  Checkbox,
} from "components";
import { AppState } from "@redux";
import * as authActions from "@redux/auth/actions";
// import { authState } from "@redux/auth/types";
import { API_URL, USER_ROLE } from "types/general";
import { FellowInput } from "@redux/fellows/types";
import { createFellow, updateFellow } from "@redux/fellows/actions";
import PersonalInfoSchema from "./validatePersonalInfo";

import ScrollArea from "components/ScrollArea";
import PageTitle from "../components/AddPageTitle";
import ImageTabs from "../components/ImageTabs";
import cls from "./user.module.scss";
import { capitalize } from "lodash";
import axios from "axios";

/**
 * Type of Formvalues
 */
type FormValues = Omit<FellowInput, "id"> & {
  id?: number | string;
};

/**
 * Type of Props
 */
export type PageEditFellowProps = RouteComponentProps<{
  fellowId?: string;
}> & {};

const PageEditFellow = (props: PageEditFellowProps) => {
  const userState = useRedux("fellows");
  const authState = useRedux("auth");
  const fellowsState = useRedux("fellows");
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(getUsers());
  // }, []);
  // if (useReduxLoading("users")) {
  //   return <ContentLoader>Fetching Users</ContentLoader>;
  // }

  const [isLoading, setIsLoading] = React.useState(false);
  const [companyOptions, setCompanyOptions] = React.useState([]);
  const [keyword, setKword] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  const [tabName, setTabName] = React.useState("basic");
  const [tabTitle, setTabTitle] = React.useState("Basic info");
  const [headshot, setHeadshot] = React.useState(undefined);
  const [isImgUploading, setIsImgUploading] = React.useState(false);

  const [
    tabDescription,
    setTabDescription,
  ] = React.useState(`Get started by entering your fellow's contact information, so we
  have a record of who they are and where their fellowship is
  located`);

  React.useEffect(() => {}, []);

  if (fellowsState.data.length === 0) {
    // props.history.push("/app/fellows/list");

    return <Redirect to={`${props.match.url}/fellows/list`} />;
  }

  const { fellowId } = props.match.params;

  const fellow = fellowsState.data.find((item) => `${item.id}` === fellowId);

  // Inital formvalues
  const initialValues: FormValues = {
    first_name: fellow.first_name,
    last_name: fellow.last_name,
    email: fellow.email,
    phone: fellow.phone,
    international: fellow.international,
    fellowship_program_name: fellow.fellowship_program_name,
    fellowship_city: fellow.fellowship_city,
    fellowship_state: fellow.fellowship_state,
    fellowship_country: fellow.fellowship_country,
    medical_school: fellow.medical_school,
    residency: fellow.residency,
    hometown: fellow.hometown,
    hobbies: fellow.hobbies,
    family_info: fellow.family_info,
    publication_links: fellow.publication_links,
    product_landscape: fellow.product_landscape,
    product_video_platform: fellow.product_video_platform,
    important_competitive_history: fellow.important_competitive_history,
    cross_divisional_leverage: fellow.cross_divisional_leverage,
    pain_points: fellow.pain_points,
    additional_notes: fellow.additional_notes,
    practice_name: fellow.practice_name,
    practice_city: fellow.practice_city,
    practice_state: fellow.practice_state,
    practice_country: fellow.practice_country,
    equipment_requests: fellow.equipment_requests,
  };

  const handleTabClick = (tab) => {
    setTabName(tab);
    switch (tab) {
      case "basic":
        setTabTitle("Basic info");
        setTabDescription(`Get started by entering your fellow's contact information, so we
        have a record of who they are and where their fellowship is
        located`);
        break;
      case "fellow":
        setTabTitle("Fellow info");
        setTabDescription(
          `As you follow up with your fellows and identify they are moving, update their information, so we can track where they're planning to go. This stage update must be approved.`
        );
        break;
      case "practice":
        setTabTitle("Fellowship / Practice info");
        setTabDescription(
          `Once your fellow has actually moved to their post fellowship facility, confirm their new information and make any changes to their profile. This stage update must be approved.`
        );
        break;
      default:
        setTabTitle("Basic info");
        setTabDescription(`Get started by entering your fellow's contact information, so we
        have a record of who they are and where their fellowship is
        located`);
        break;
    }
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
    if (status == "done") {
      console.log("file: ", file);
      setHeadshot(file);
    } else if (status == "removed") {
      console.log("~~~removed~~~");
      setHeadshot(undefined);
    }
  };

  // Handle update
  const handleCreate = async (data) => {
    const basicInfo: any = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      international: data.international,
      fellowship_program_name: data.fellowship_program_name,
      fellowship_city: data.fellowship_city,
      fellowship_state: data.fellowship_state,
      fellowship_country: data.fellowship_country,
    };

    const fellowInfo = {
      medical_school: data.medical_school,
      residency: data.residency,
      hometown: data.hometown,
      hobbies: data.hobbies,
      family_info: data.family_info,
      publication_links: data.publication_links,
      product_landscape: data.product_landscape,
      product_video_platform: data.product_video_platform,
      important_competitive_history: data.important_competitive_history,
      cross_divisional_leverage: data.cross_divisional_leverage,
      pain_points: data.pain_points,
      additional_notes: data.additional_notes,
    };

    const fellowshipInfo = {
      practice_name: data.practice_name,
      practice_city: data.practice_city,
      practice_state: data.practice_state,
      practice_country: data.practice_country,
      equipment_requests: data.equipment_requests,
    };
    let updateData = { basicInfo, fellowInfo, fellowshipInfo };
    if (headshot) {
      setIsImgUploading(true);
      const formData = new FormData();
      formData.append("file", headshot);
      let imageUrl = await imageUpload("fellows/image-upload", formData);
      console.log("~~~~image url: ", imageUrl);
      basicInfo.headshot = imageUrl.data;
      setIsImgUploading(false);
    }
    dispatch(updateFellow(fellow.id, updateData,()=>{}));
  };

  return (
    <ContainerVertical>
      <ScrollArea>
        <PageTitle />
        <div className={cls.registerWrapper}>
          <WidgetContent className={cls.fixedWidth}>
            <div className={cls.tabHeader}>
              <h1 className={cls.tabTitle}>{tabTitle}</h1>
              <p className={cls.tabDescription}>{tabDescription}</p>
              <ImageTabs tabName={tabName} onClickTab={handleTabClick} />
            </div>

            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={PersonalInfoSchema}
              onSubmit={(data) => {
                if (tabName === "basic") {
                  setTabName("fellow");
                  return;
                }

                handleCreate(data);
              }}
            >
              {({
                errors,
                touched,
                setErrors,
                setTouched,
                validateForm,
                submitForm,
                handleChange,
                handleSubmit,
                handleBlur,
                setValues,
                values,
              }: FormikProps<FormValues>) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FormInputGroup className={cls.formInputGroup}>
                      {tabName === "basic" && (
                        <>
                          <FieldsetInput
                            label="First name*"
                            name="first_name"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.first_name && touched.first_name
                                ? errors.first_name
                                : null
                            }
                            value={values.first_name}
                          />
                          <FieldsetInput
                            label="Last name*"
                            name="last_name"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.last_name && touched.last_name
                                ? errors.last_name
                                : null
                            }
                            value={values.last_name}
                          />
                          <FieldsetInput
                            label="Email*"
                            name="email"
                            type="email"
                            className={cls.inputField}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.email && touched.email
                                ? errors.email
                                : null
                            }
                          />
                          <FieldsetInput
                            label="Phone*"
                            name="phone"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.phone && touched.phone
                                ? errors.phone
                                : null
                            }
                            value={values.phone}
                          />
                          {/* Headshot here */}
                          <Dropzone
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                            inputContent={`Drag and drop your photo here, or browser`}
                            onChangeStatus={handleChangeStatus}
                            accept="image/*"
                          />
                          <Checkbox
                            name={"international"}
                            onChange={handleChange}
                            label={<>International</>}
                            className={cls.checkbox}
                            checked={values.international}
                          />
                          <FieldsetInput
                            label="Fellowship program name*"
                            name="fellowship_program_name"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.fellowship_program_name &&
                              touched.fellowship_program_name
                                ? errors.fellowship_program_name
                                : null
                            }
                            value={values.fellowship_program_name}
                          />
                          <FieldsetInput
                            label="Fellowship city*"
                            name="fellowship_city"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.fellowship_city && touched.fellowship_city
                                ? errors.fellowship_city
                                : null
                            }
                            value={values.fellowship_city}
                          />

                          {values.international ? (
                            <>
                              {" "}
                              <FieldsetInput
                                label="Fellowship state"
                                name="fellowship_state"
                                className={cls.inputField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMessage={
                                  errors.fellowship_state &&
                                  touched.fellowship_state
                                    ? errors.fellowship_state
                                    : null
                                }
                                value={values.fellowship_state}
                              />
                              <FieldsetInput
                                label="Fellowship country"
                                name="fellowship_country"
                                className={cls.inputField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMessage={
                                  errors.fellowship_country &&
                                  touched.fellowship_country
                                    ? errors.fellowship_country
                                    : null
                                }
                                value={values.fellowship_country}
                              />
                            </>
                          ) : (
                            <FieldsetInput
                              label="Fellowship state"
                              name="fellowship_state"
                              className={cls.inputField}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errorMessage={
                                errors.fellowship_state &&
                                touched.fellowship_state
                                  ? errors.fellowship_state
                                  : null
                              }
                              value={values.fellowship_state}
                            />
                          )}
                        </>
                      )}

                      {tabName === "fellow" && (
                        <>
                          <label className={cls.groupLabel}>
                            <b>Background</b>
                          </label>
                          <FieldsetInput
                            label="Medical school"
                            name="medical_school"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.medical_school}
                          />
                          <FieldsetInput
                            label="Residency"
                            name="residency"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.residency}
                          />
                          <label className={cls.groupLabel}>
                            <b>Personal info</b>
                          </label>
                          <FieldsetInput
                            label="Hometown"
                            name="hometown"
                            className={cls.inputField}
                            value={values.hometown}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FieldsetInput
                            label="Hobbies"
                            name="hobbies"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.hobbies}
                          />
                          <FieldsetInput
                            label="Family Information"
                            name="family_info"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.family_info}
                          />
                          <label className={cls.groupLabel}>
                            <b>Research Interests & Publications</b>
                          </label>
                          <FieldsetTextarea
                            label="Links to publications this fellow has published"
                            name="publication_links"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.publication_links}
                          />

                          <label className={cls.groupLabel}>
                            <b>Program Info</b>
                          </label>
                          <FieldsetInput
                            label="Current Product landscape"
                            name="product_landscape"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.product_landscape}
                          />
                          <FieldsetInput
                            label="Current video platform at current program"
                            name="product_video_platform"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.product_video_platform}
                          />
                          <FieldsetInput
                            label="Important competitive history"
                            name="important_competitive_history"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.important_competitive_history}
                          />
                          <FieldsetInput
                            label="Cross divisional leverage"
                            name="cross_divisional_leverage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cross_divisional_leverage}
                          />
                          <FieldsetInput
                            label="Pain points"
                            name="pain_points"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pain_points}
                          />

                          <FieldsetTextarea
                            label="Additional notes"
                            name="additional_notes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.additional_notes}
                          />
                        </>
                      )}

                      {tabName === "practice" && (
                        <>
                          <label className={cls.groupLabel}>
                            <b>Practice info</b>
                          </label>
                          <FieldsetInput
                            label="Practice name"
                            name="practice_name"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_name}
                          />
                          <FieldsetInput
                            label="City"
                            name="practice_city"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_city}
                          />
                          <FieldsetInput
                            label="State"
                            name="practice_state"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_state}
                          />
                          <FieldsetInput
                            label="Country"
                            name="practice_country"
                            className={cls.inputField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.practice_country}
                          />
                          <label className={cls.groupLabel}>
                            <b>Equipment requests</b>
                          </label>
                          <FieldsetInput
                            label="What equipment, if any, are they requesting at their facility?"
                            name="equipment_requests"
                            className={cls.inputField}
                            value={values.equipment_requests}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </>
                      )}

                      <br />
                      <FormInputGroup direction={"horizontal"}>
                        {tabName === "basic" && (
                          <>
                            <Button
                              Component={NavLink}
                              to={"/app/fellows/list"}
                            >
                              {`< CANCEL`}
                            </Button>
                            <Button
                              // isLoading={userState.isCreating}
                              className={cls.yellowBtn}
                              primary
                              type="submit"
                            >
                              {`SAVE & EXIT >`}
                            </Button>
                          </>
                        )}
                        {tabName === "fellow" && (
                          <>
                            <Button
                              // Component={NavLink}
                              onClick={() => {
                                handleTabClick("basic");
                              }}
                            >
                              {`< Go Back`}
                            </Button>
                            <Button
                              isLoading={userState.isCreating}
                              className={cls.yellowBtn}
                              primary
                              onClick={() => {
                                handleTabClick("practice");
                              }}
                            >
                              {`SAVE & EXIT >`}
                            </Button>
                          </>
                        )}
                        {tabName === "practice" && (
                          <>
                            <Button
                              // Component={NavLink}
                              onClick={() => {
                                handleTabClick("fellow");
                              }}
                            >
                              {`< Go Back`}
                            </Button>
                            <Button
                              isLoading={userState.isUpdating || isImgUploading}
                              className={cls.yellowBtn}
                              primary
                              type="submit"
                              onClick={() => {
                                // validateForm();
                                validateForm().then((errs) => {
                                  if (
                                    Object.keys(errs).length === 0 &&
                                    errs.constructor === Object
                                  ) {
                                    // submitForm();
                                  } else {
                                    setErrors(errs);
                                    let fieldList: any = {};
                                    Object.keys(errs).map((err) => {
                                      fieldList[err] = true;
                                    });
                                    setTouched({ ...touched, ...fieldList });
                                    setTabName("basic");
                                  }
                                });
                              }}
                            >
                              {`SUBMIT >`}
                            </Button>
                          </>
                        )}
                      </FormInputGroup>
                    </FormInputGroup>
                  </form>
                );
              }}
            </Formik>
          </WidgetContent>
        </div>
      </ScrollArea>
    </ContainerVertical>
  );
};

export default PageEditFellow;
