import { useState } from 'react';

export const useIsOpenControls = (defaultState: boolean = false) => {
  const [isVisible, setVisibility] = useState(defaultState);

  const handleClose = () => setVisibility(false);
  const handleOpen = () => setVisibility(true);
  const handleToggle = () => setVisibility(!isVisible);

  return {
    isVisible,
    setVisibility,
    handleClose,
    handleOpen,
    handleToggle,
    isOpen: isVisible,
  };
};
