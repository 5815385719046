import * as Yup from "yup";

const UserSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .matches(/^[aA-zZ]+$/, "Only characters accepted")
    .required("First name is required"),
  last_name: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address!")
    .required("Email address is required"),
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      "Use 8 or more characters with a mix of letters, numbers & symbols."
    )
    .matches(
      /([(?=.*?[a-z])(?=.*?[0-9])/,
      "Use 8 or more characters with a mix of letters, numbers & symbols."
    ),
  passwordconfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match!")
    .required("Password confirm is required"),
});

export default UserSchema;
