import React from 'react';
import classnames from 'classnames';
import cls from './table.module.scss';
import Icon from '../Icon';
import { Omit } from '../utils';

export interface Column<DataItem, ColumnField> {
  label: React.ReactNode;
  props?: JSX.IntrinsicElements['th'] | JSX.IntrinsicElements['td'];
  field?: ColumnField;
  name?: string;
  Component?: (props: { data: DataItem }) => React.ReactNode;
}

export type ITableProps<
  DataItem,
  ColumnField
> = JSX.IntrinsicElements['table'] & {
  data: DataItem[];
  columns: Column<DataItem, ColumnField>[];
  large?: boolean;
  className?: string;
  trProps?: {
    onClick: (item: DataItem) => void;
  } & Omit<JSX.IntrinsicElements['tr'], 'onClick'>;
  handleClickLabel?: (name: string) => void;
  activeColName?: string;
  activeColOrder?: 'ASC' | 'DESC';
  inContainer?: boolean;
};

function renderColumn<DataItem, ColumnField extends keyof DataItem>(
  { Component, field }: Column<DataItem, ColumnField>,
  data: DataItem,
): React.ReactNode {
  if (Component) {
    return Component({ data });
  }

  if (!field) {
    return null;
  }

  return data[field];
}

export class Table<
  DataItem,
  ColumnField extends keyof DataItem
> extends React.Component<ITableProps<DataItem, ColumnField>> {
  static defaultProps = {
    className: null,
  };

  render() {
    const {
      data,
      columns,
      large,
      className,
      trProps,
      handleClickLabel,
      activeColName,
      activeColOrder,
      inContainer,
      ...rest
    } = this.props;

    return (
      <table
        className={classnames(cls.table, className, {
          [cls.large]: large,
          [cls.inContainer]: inContainer,
        })}
        {...rest}
      >
        <thead>
          <tr>
            {columns.map(({ props, name, label }, index) => {
              const hasLabelClick =
                typeof name === 'string' && handleClickLabel;
              const isActive = activeColName === name && !!name;
              return (
                <th
                  key={index}
                  {...props}
                  className={classnames(props && props.className, {
                    [cls.click]: hasLabelClick,
                    [cls.activeCol]: isActive,
                  })}
                  onClick={
                    hasLabelClick ? () => handleClickLabel!(name!) : undefined
                  }
                >
                  {label}
                  {hasLabelClick ? (
                    <Icon prefix={'far'} name={activeColOrder === 'ASC' ? 'chevron-up' : 'chevron-down'} className={cls.sortOrderIcon}/>
                  ) : null}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const onClickTr =
              trProps && trProps.onClick
                ? () => {
                    trProps.onClick!(item);
                  }
                : undefined;
            return (
              <tr key={index} {...trProps} onClick={onClickTr}>
                {columns.map((column, cindex) => (
                  <td key={cindex} {...column.props}>
                    {renderColumn(column, item)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default Table;
