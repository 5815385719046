// @flow
import * as React from 'react'
import classnames from 'classnames'

import cls from './widget-table.module.scss'

type WidgetTableProps = JSX.IntrinsicElements['table'] & {
  children?: React.ReactNode,
  className?: string,
}

const WidgetTable = ({
  children,
  className,
  ...rest
}: WidgetTableProps) => (
    <table
      className={classnames(cls.wrapper, className)}
      {...rest}
    >
      {children}
    </table>
  )

WidgetTable.defaultProps = {
  className: null,
  children: null,
}

export default WidgetTable
