import cls from './container-v.module.scss'
import createSimpleComponent from '../createSimpleComponent';

const ContainerVertical = createSimpleComponent({
  Component: 'div',
  className: cls.wrapper,
  displayName: 'ContainerVertical',
});

export default ContainerVertical;
