import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, FormikProps } from "formik";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Avatar, TextField } from "@duik/it";
import {
  WidgetContent,
  Widget,
  Input,
  FormInputGroup,
  Button,
  Icon,
  ContainerVertical,
  Select,
} from "components";
import { AppState } from "@redux";
import * as authActions from "@redux/auth/actions";
import { authState } from "@redux/auth/types";
import PersonalInfoSchema from "./validatePersonalInfo";

import ScrollArea from "components/ScrollArea";

import cls from "./personal.module.scss";

/**
 * Type of Formvalues
 */
interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  territory: number | string;
}

/**
 * Type of Props
 */
type AppActionsProps = RouteComponentProps & {
  updatePersonalInfo: Function; // Redux action for updating personal information
  auth: authState; // Store
};

const PagePersonalInformation = ({
  updatePersonalInfo,
  auth,
}: AppActionsProps) => {
  const { user } = auth.data;
  const d = useDispatch();
  const territory_options = [
    {value: 0, label: 'Akron',},
    {value: 1, label: 'Alaska',},
    {value: 2, label: 'Albany',},
    {value: 3, label: 'Ann Arbor',},
    {value: 4, label: 'Asheville',},
    {value: 5, label: 'Atlanta - North',},
    {value: 6, label: 'Atlanta Split',},
    {value: 7, label: 'Austin South',},
    {value: 8, label: 'Bakersfield',},
    {value: 9, label: 'Baltimore Central',},
    {value: 10, label: 'Baltimore North',},
    {value: 11, label: 'Baltimore South',},
    {value: 12, label: 'Baton Rouge',},
    {value: 13, label: 'Beaumont',},
    {value: 14, label: 'Bend',},
    {value: 15, label: 'Birmingham',},
    {value: 16, label: 'Black Hills',},
    {value: 17, label: 'Boca Raton',},
    {value: 18, label: 'Boise',},
    {value: 19, label: 'Boston North',},
    {value: 20, label: 'Boston West Split',},
    {value: 21, label: 'Bronx-Westchester',},
    {value: 22, label: 'Brooklyn',},
    {value: 23, label: 'Buckeye Valley',},
    {value: 24, label: 'Buffalo',},
    {value: 25, label: 'Central Detroit',},
    {value: 26, label: 'Central Iowa North',},
    {value: 27, label: 'Central Iowa South',},
    {value: 28, label: 'Central Jersey',},
    {value: 29, label: 'Central Minnesota',},
    {value: 30, label: 'Central Pennsylvania',},
    {value: 31, label: 'Central Texas',},
    {value: 32, label: 'Central Valley',},
    {value: 33, label: 'Central Wisconsin',},
    {value: 34, label: 'Charleston Expansion',},
    {value: 35, label: 'Charlotte Split',},
    {value: 36, label: 'Chattanooga',},
    {value: 37, label: 'Chicago Downtown North',},
    {value: 38, label: 'Chicago Downtown South',},
    {value: 39, label: 'Chicago Downtown West',},
    {value: 40, label: 'Chicago North',},
    {value: 41, label: 'Chicago NW',},
    {value: 42, label: 'Chicago South',},
    {value: 43, label: 'Chicago SW',},
    {value: 44, label: 'Chicago West',},
    {value: 45, label: 'Chico',},
    {value: 46, label: 'Cincinnati',},
    {value: 47, label: 'Cincinnati Metro',},
    {value: 48, label: 'Cleveland Downtown',},
    {value: 49, label: 'Cleveland East',},
    {value: 50, label: 'Cleveland West',},
    {value: 51, label: 'College Station',},
    {value: 52, label: 'Columbia MO',},
    {value: 53, label: 'Columbia SC',},
    {value: 54, label: 'Columbus North',},
    {value: 55, label: 'Columbus South',},
    {value: 56, label: 'Dallas East Split',},
    {value: 57, label: 'Dayton West',},
    {value: 58, label: 'DC Metro',},
    {value: 59, label: 'Denver East',},
    {value: 60, label: 'Denver West',},
    {value: 61, label: 'Detroit',},
    {value: 62, label: 'DFW North',},
    {value: 63, label: 'DFW South',},
    {value: 64, label: 'DFW Split',},
    {value: 65, label: 'DFW West',},
    {value: 66, label: 'DT Houston',},
    {value: 67, label: 'East Bay',},
    {value: 68, label: 'East Houston',},
    {value: 69, label: 'El Paso Split',},
    {value: 70, label: 'Erie, PA',},
    {value: 71, label: 'Eugene',},
    {value: 72, label: 'Evansville',},
    {value: 73, label: 'Fargo, ND',},
    {value: 74, label: 'Fox Valley',},
    {value: 75, label: 'Fremont',},
    {value: 76, label: 'Fresno',},
    {value: 77, label: 'Ft. Wayne',},
    {value: 78, label: 'Grand Rapids',},
    {value: 79, label: 'Greater DC',},
    {value: 80, label: 'Greater Miami Split',},
    {value: 81, label: 'Greensboro, NC',},
    {value: 82, label: 'Greenville/Augusta',},
    {value: 83, label: 'Hampton Roads',},
    {value: 84, label: 'Harrisburg',},
    {value: 85, label: 'Hattiesburg',},
    {value: 86, label: 'Hawaii Split',},
    {value: 87, label: 'Hill Country',},
    {value: 88, label: 'Houston North',},
    {value: 89, label: 'Houston South',},
    {value: 90, label: 'Houston SW',},
    {value: 91, label: 'Hudson Valley',},
    {value: 92, label: 'Huntsville Split',},
    {value: 93, label: 'Indiana Shores',},
    {value: 94, label: 'Indianapolis Metro',},
    {value: 95, label: 'Indianapolis So.',},
    {value: 96, label: 'Inland Empire North',},
    {value: 97, label: 'Inland Empire South',},
    {value: 98, label: 'Inland Empire West',},
    {value: 99, label: 'Iowa City',},
    {value: 100, label: 'Jackson',},
    {value: 101, label: 'Jacksonville, FL',},
    {value: 102, label: 'Jersey Shore',},
    {value: 103, label: 'Jonesboro Expansion',},
    {value: 104, label: 'Kansas City',},
    {value: 105, label: 'Kansas City South',},
    {value: 106, label: 'Kearney, NE Split',},
    {value: 107, label: 'Knoxville South',},
    {value: 108, label: 'LA North',},
    {value: 109, label: 'Lake Charles',},
    {value: 110, label: 'Lansing',},
    {value: 111, label: 'Las Vegas North',},
    {value: 112, label: 'Las Vegas South',},
    {value: 113, label: 'Lehigh Valley',},
    {value: 114, label: 'Lexington',},
    {value: 115, label: 'Little Rock Split',},
    {value: 116, label: 'Long Beach',},
    {value: 117, label: 'Long Beach South',},
    {value: 118, label: 'Long Island East',},
    {value: 119, label: 'Long Island West',},
    {value: 120, label: 'Los Angeles Downtown',},
    {value: 121, label: 'Los Angeles South',},
    {value: 122, label: 'Los Angeles West',},
    {value: 123, label: 'Louisville Metro',},
    {value: 124, label: 'Lubbock Split',},
    {value: 125, label: 'Maine',},
    {value: 126, label: 'Manhattan',},
    {value: 127, label: 'Manhattan Expansion',},
    {value: 128, label: 'MD-DE Shore',},
    {value: 129, label: 'Memphis',},
    {value: 130, label: 'Milwaukee Central',},
    {value: 131, label: 'Milwaukee North',},
    {value: 132, label: 'Minneapolis North',},
    {value: 133, label: 'Mobile',},
    {value: 134, label: 'Montana East',},
    {value: 135, label: 'Montana West',},
    {value: 136, label: 'Montgomery',},
    {value: 137, label: 'N. Mississippi',},
    {value: 138, label: 'Naples/Ft. Myers split',},
    {value: 139, label: 'Nashville North',},
    {value: 140, label: 'Nashville South',},
    {value: 141, label: 'NE Kansas',},
    {value: 142, label: 'NE Texas',},
    {value: 143, label: 'New Hampshire',},
    {value: 144, label: 'New Mexico Split',},
    {value: 145, label: 'New Orleans North Shore',},
    {value: 146, label: 'New Orleans South Shore',},
    {value: 147, label: 'Newark North',},
    {value: 148, label: 'Newark South',},
    {value: 149, label: 'Newark West',},
    {value: 150, label: 'No. Colorado',},
    {value: 151, label: 'North Austin',},
    {value: 152, label: 'North Bay',},
    {value: 153, label: 'North Central Indiana',},
    {value: 154, label: 'Northern Arizona',},
    {value: 155, label: 'Northern Virginia',},
    {value: 156, label: 'NW Arkansas',},
    {value: 157, label: 'NW Indiana',},
    {value: 158, label: 'Oakland',},
    {value: 159, label: 'Ogden',},
    {value: 160, label: 'Oklahoma City East',},
    {value: 161, label: 'Oklahoma City South',},
    {value: 162, label: 'Oklahoma City West',},
    {value: 163, label: 'Omaha Split',},
    {value: 164, label: 'Orange County North',},
    {value: 165, label: 'Orange County South',},
    {value: 166, label: 'Orlando',},
    {value: 167, label: 'Oshkosh',},
    {value: 168, label: 'Palm Beach',},
    {value: 169, label: 'Palm Springs',},
    {value: 170, label: 'Pasadena',},
    {value: 171, label: 'Peninsula',},
    {value: 172, label: 'Pensacola',},
    {value: 173, label: 'Peoria, IL',},
    {value: 174, label: 'Philadelphia Downtown',},
    {value: 175, label: 'Philadelphia North Split',},
    {value: 176, label: 'Philadelphia West',},
    {value: 177, label: 'Phoenix Central',},
    {value: 178, label: 'Phoenix East',},
    {value: 179, label: 'Phoenix South',},
    {value: 180, label: 'Phoenix West',},
    {value: 181, label: 'Pittsburgh Central',},
    {value: 182, label: 'Pittsburgh East',},
    {value: 183, label: 'Pittsburgh So.',},
    {value: 184, label: 'Portland',},
    {value: 185, label: 'Portland West',},
    {value: 186, label: 'Providence',},
    {value: 187, label: 'Provo',},
    {value: 188, label: 'Quad Cities',},
    {value: 189, label: 'Queens',},
    {value: 190, label: 'Raleigh-Durham Split',},
    {value: 191, label: 'Reno',},
    {value: 192, label: 'Richmond Split',},
    {value: 193, label: 'Rio Grande Valley Split',},
    {value: 194, label: 'Roanoke',},
    {value: 195, label: 'Rochester',},
    {value: 196, label: 'Sacramento',},
    {value: 197, label: 'Sacramento West',},
    {value: 198, label: 'Saginaw',},
    {value: 199, label: 'Salt Lake City',},
    {value: 200, label: 'San Antonio Split',},
    {value: 201, label: 'San Diego Central',},
    {value: 202, label: 'San Diego North',},
    {value: 203, label: 'San Diego South',},
    {value: 204, label: 'San Fernando Valley',},
    {value: 205, label: 'San Francisco',},
    {value: 206, label: 'Sandhills NC',},
    {value: 207, label: 'Santa Barbara',},
    {value: 208, label: 'Sarasota',},
    {value: 209, label: 'Savannah',},
    {value: 210, label: 'Scranton',},
    {value: 211, label: 'SE Idaho',},
    {value: 212, label: 'Seattle Central',},
    {value: 213, label: 'Seattle East',},
    {value: 214, label: 'Seattle North',},
    {value: 215, label: 'Seattle South',},
    {value: 216, label: 'Shenadoah Valley',},
    {value: 217, label: 'Shreveport Monroe',},
    {value: 218, label: 'Sioux Falls, SD',},
    {value: 219, label: 'Siouxland',},
    {value: 220, label: 'So. Colorado',},
    {value: 221, label: 'South Bay',},
    {value: 222, label: 'South Bend',},
    {value: 223, label: 'South Georgia',},
    {value: 224, label: 'South Jersey Split',},
    {value: 225, label: 'Southeast MO',},
    {value: 226, label: 'Southern CT',},
    {value: 227, label: 'Southern Maryland',},
    {value: 228, label: 'Southern Minnesota',},
    {value: 229, label: 'Southwest Virginia',},
    {value: 230, label: 'Space Coast',},
    {value: 231, label: 'Spokane',},
    {value: 232, label: 'Springfield',},
    {value: 233, label: 'Springfield, MO',},
    {value: 234, label: 'St Louis Downtown',},
    {value: 235, label: 'St Louis East',},
    {value: 236, label: 'St Louis West',},
    {value: 237, label: 'St Paul',},
    {value: 238, label: 'Syracuse',},
    {value: 239, label: 'Tacoma',},
    {value: 240, label: 'Tallahassee',},
    {value: 241, label: 'Tampa',},
    {value: 242, label: 'Tidewater Split',},
    {value: 243, label: 'Toledo',},
    {value: 244, label: 'Traverse City',},
    {value: 245, label: 'Tucson',},
    {value: 246, label: 'Tulsa North',},
    {value: 247, label: 'Tulsa South',},
    {value: 248, label: 'Tuscaloosa',},
    {value: 249, label: 'Vermont',},
    {value: 250, label: 'West Virginia North',},
    {value: 251, label: 'West Virginia South Split',},
    {value: 252, label: 'Western Colorado',},
    {value: 253, label: 'Western MA',},
    {value: 254, label: 'Western Minnesota',},
    {value: 255, label: 'Wichita Split',},
    {value: 256, label: 'Wilmington, NC',},
    {value: 257, label: 'Winsboro',},
    {value: 258, label: 'Yakima',},
    {value: 259, label: 'OTHER',},
  ];

  // Inital formvalues
  const initialValues: FormValues = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    territory: user.territory,
    // companyName: user.account.name,
  };

  // Handle update
  const handleUpdate = (data, actions) => {
    updatePersonalInfo(data)
      .then((res) => {
        actions.setFieldValue("oldPassword", "");
        actions.setFieldValue("newPassword", "");
        actions.setFieldValue("newPasswordConfirm", "");
      })
      .catch((err) => {
        if (err.status === 409 && err.error === "password_invalid") {
          actions.setFieldError("oldPassword", err.message);
        }
      });
  };
  const handleLogout = () => {
    d(authActions.signOut());
  };

  return (
    <React.Fragment>
      <ScrollArea>
        <div className={cls.registerWrapper}>
          <WidgetContent className={cls.content}>
            <div className={cls.avatarBox}>
              <Avatar
                avatarPlaceholder={{
                  color: "blue",
                  content: `${user.first_name[0]}${user.last_name[0]}`,
                }}
                className={cls.userAvatar}
                name={<></>}
              />
            </div>
            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={PersonalInfoSchema}
              onSubmit={handleUpdate}
            >
              {({
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setValues,
                values,
              }: FormikProps<FormValues>) => (
                <form onSubmit={handleSubmit}>
                  <FormInputGroup>
                    <div className={cls.inputGroup}>
                      <Input
                        label="First name"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.firstName && touched.firstName
                            ? errors.firstName
                            : null
                        }
                        value={values.firstName}
                      />
                    </div>
                    <div className={cls.inputGroup}>
                      <Input
                        label="Last name"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.lastName && touched.lastName
                            ? errors.lastName
                            : null
                        }
                        value={values.lastName}
                      />
                    </div>
                    <div className={cls.inputGroup}>
                      {/* <Input
                        disabled
                        label="Email"
                        placeholder="Email Address"
                        value={values.email}
                      /> */}
                      {/* <Icon prefix={"far"} name={"lock"} /> */}
                    </div>
                    <FormInputGroup className={cls.territory}>
                      <label>TERRITORY</label>
                      <Select
                        options={territory_options}
                        activeOption={territory_options[values.territory]}
                        defaultOption={{
                          value: "",
                          label: "Select role",
                        }}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            territory: option.value,
                          });
                        }}
                      />
                    </FormInputGroup>
                    {/* <div className={cls.inputGroup}>
                      <Input
                        label="Old Password"
                        name="oldPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="··········"
                        type="password"
                        value={values.oldPassword}
                        errorMessage={
                          errors.oldPassword && touched.oldPassword
                            ? errors.oldPassword
                            : null
                        }
                      />
                    </div>
                    <div className={cls.inputGroup}>
                      <Input
                        label="New Password"
                        name="newPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="password"
                        value={values.newPassword}
                        errorMessage={
                          errors.newPassword && touched.newPassword
                            ? errors.newPassword
                            : null
                        }
                      />
                    </div>
                    <div className={cls.inputGroup}>
                      <Input
                        label="Confirm New Password"
                        name="newPasswordConfirm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="password"
                        value={values.newPasswordConfirm}
                        errorMessage={
                          errors.newPasswordConfirm &&
                          touched.newPasswordConfirm
                            ? errors.newPasswordConfirm
                            : null
                        }
                      />
                    </div> */}

                    <br />
                    <div className={cls.btnBox}>
                      <Button
                        clear
                        Component={NavLink}
                        to={"fellows/list"}
                        className={`${cls.btnAction} ${cls.btnCancel}`}

                      >
                        CANCEL
                      </Button>
                      <Button
                        isLoading={auth.isUpdating}
                        className={`${cls.btnAction} ${cls.btnSubmit}`}
                        primary
                        type="submit"
                      >
                        SAVE UPDATES
                      </Button>
                    </div>
                    <hr></hr>
                    <Button
                      clear
                      className={`${cls.btnLogout}`}
                      primary
                      onClick={handleLogout}
                    >
                      LOG OUT
                    </Button>
                  </FormInputGroup>
                </form>
              )}
            </Formik>
          </WidgetContent>
        </div>
      </ScrollArea>
    </React.Fragment>
  );
};

export default connect(
  (state: AppState) => ({
    auth: state.auth,
  }),
  {
    updatePersonalInfo: authActions.updatePersonalInfo,
  }
)(PagePersonalInformation);
