import { AppState } from ".";
import fellows from "./fellows/initialState";
import users from "./users/initialState";
import form from "./form/initialState";
import formsLibrary from "./formsLibrary/initialState";
import app from "./app/initialState";
import apps from "./apps/initialState";
import auth from "./auth/initialState";
import snacks from "./snacks/initialState";
import snippets from "./snippets/initialState";
export const initialState = {
  fellows,
  users,
  apps,
  app,
  snacks,
  auth,
  form,
  formsLibrary,
  snippets,
};
