import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import qs from "query-string";

import { useRedux, AppState } from "@redux";
import ScrollArea from "components/ScrollArea";
import ContainerVertical from "components/ContainerVertical";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import PasswordReset from "./PasswordReset";
import PasswordResetRequest from "./PasswordResetRequest";
import EmailConfirmation from "./EmailConfirmation";
import PageSSOAuth from './SSOAuth';

// import Verify from './Verify';
// import NotVerified from './NotVerified'
// import AccountConfirmation from './AccountConfirmation'

import cls from "./auth.module.scss";

const AuthRoutes = (props) => {
  const { match, location } = props;
  const auth = useRedux("auth");
  const search = location.search;
  //Get params
  const parsed = qs.parse(search);
  const fromURL = parsed.from;

  //Get params of the from page
  let fromParams = fromURL !== undefined ? fromURL.split("?") : [];
  fromParams = fromParams.length > 1 ? fromParams[1] : "";
  fromParams = qs.parse(fromParams);

  const isPopup = location.pathname.match(/\/sign-inpopup/g);

  const { JFApp } = fromParams;
  if (!isPopup && auth.data && auth.data.user && auth.data.user.id) {
    // if (location.pathname.includes("/auth/confirm"))
    //   return (
    //     <ContainerVertical>
    //       <ScrollArea>
    //         <div className={JFApp == 1 ? "" : cls.pageWrapper}>
    //           {JFApp != 1 && (
    //             <img
    //               src="/assets/images/logo-stryker-horizontal.png"
    //               className={cls.logo}
    //             />
    //           )}
    //           <Switch {...props}>
    //             <Route
    //               component={EmailConfirmation}
    //               path={`${match.url}/confirm/:token`}
    //             />
    //             <Redirect to={`${match.url}/sign-in${search}`} />
    //           </Switch>
    //         </div>
    //       </ScrollArea>
    //     </ContainerVertical>
    //   );
    return <Redirect to={fromURL || "/app"} />;
  }
  return (
		<ContainerVertical>
			<ScrollArea>
				<div className={JFApp == 1 ? '' : cls.pageWrapper}>
					<PageSSOAuth />
				</div>
			</ScrollArea>
		</ContainerVertical>
	);
  /* return (
    <ContainerVertical>
      <ScrollArea>
        <div className={JFApp == 1 ? "" : cls.pageWrapper}>
          {JFApp != 1 && (
            <img src="/assets/images/logo.png" className={cls.logo} />
          )}
          <Switch {...props}>
            <Route
              render={(props) => <SignUp {...props} JFApp={JFApp == 1} />}
              path={`${match.url}/sign-up`}
            />

            <Route
              render={(props) => <SignIn {...props} JFApp={JFApp == 1} />}
              path={`${match.url}/sign-in`}
            />
            <Route
              render={(props) => <SignIn {...props} JFApp={JFApp == 1} />}
              path={`${match.url}/sign-inpopup`}
            />
            <Route
              render={(props) => <PasswordResetRequest {...props} />}
              exact
              path={`${match.url}/password-reset`}
            />
            <Route
              render={(props) => <PasswordReset {...props} />}
              path={`${match.url}/password-reset/:email/:resetToken`}
            />
            <Route
              component={EmailConfirmation}
              path={`${match.url}/confirm/:confirmToken`}
            />
            <Redirect to={`${match.url}/sign-in${search}`} />
          </Switch>
        </div>
      </ScrollArea>
    </ContainerVertical>
  ); */
};

export default AuthRoutes;
