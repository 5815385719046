import React from 'react'
import classnames from 'classnames'

import cls from './alert-box.module.scss'

export type AlertBoxProps = JSX.IntrinsicElements['div'] & {
  state?: string,
}

const AlertBox = ({
  className,
  children,
  state,
  ...rest
}: AlertBoxProps) => (
    <div
      className={classnames(cls.box, className, {
        [cls[state]]: state,
      })}
      {...rest}
    >
      {
        children && (
          <div className={cls.content}>
            {children}
          </div>
        )
      }
    </div>
  )

AlertBox.defaultProps = {
  className: null,
  children: null,
  state: null,
}

export default AlertBox
