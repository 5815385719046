import * as React from 'react'
import classnames from 'classnames'

import cls from './top-bar.module.scss'

// @flow
type TopBarProps = JSX.IntrinsicElements['div'] & {
  center?: boolean,
  className?: string,
  children?: React.ReactNode
}

const TopBar = ({
  children,
  className,
  center,
  ...rest
}: TopBarProps) => (
    <div
      className={classnames(cls.wrapper, className, {
        [cls.center]: center,
      })}
      {...rest}
    >
      {children}
    </div>
  )

TopBar.defaultProps = {
  className: null,
  children: null,
  center: false,
}

export default TopBar
