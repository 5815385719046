import * as React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { AppState } from '@redux'

import cls from './nav-link.module.scss'

import { WithTagProps, AnyTag } from '../utils'

export type NavLinkProps = {
  className?: string
  children?: React.ReactNode
  rightEl?: React.ReactNode
  highlighted?: boolean
  icon?: React.ReactNode
  isNavigationCollapsed: boolean
  onDark?: boolean
  Component?:any
  to?:string
}

export type WithNavLinkProps<Tag> =
  | WithTagProps<Tag, NavLinkProps>
  | (JSX.IntrinsicElements['a'] & NavLinkProps)

function NavLink<Tag extends AnyTag<any>>(props: WithNavLinkProps<Tag>): JSX.Element

function NavLink({
  rightEl,
  children,
  className,
  highlighted,
  icon,
  Component,
  isNavigationCollapsed,
  dispatch,
  onDark,
  ...rest
}) {
  return (
    <Component
      className={ classnames(cls.wrapper, className, {
        [cls.highlighted]: highlighted,
        [cls.collapsed]: isNavigationCollapsed,
        [cls.onDark]: onDark,
      }) }
      { ...rest }
    >
      <span className={ cls.text }>
        {icon && <span className={ cls.icon }>{icon}</span>}
        <div className={ cls.textContent }>{children}</div>
      </span>
      {rightEl && <span className={ cls.rightEl }>{rightEl}</span>}
    </Component>
  )
}

NavLink.defaultProps = {
  Component: 'a',
  className: null,
  rightEl: null,
  highlighted: false,
  icon: null,
  children: null,
}

const NavLinkConnected = connect((state: AppState) => ({
  isNavigationCollapsed: state.app.isNavigationCollapsed,
}))(NavLink)

export default NavLinkConnected
