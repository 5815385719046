import cls from './badge.module.scss'
import createSimpleComponent from '../createSimpleComponent';

export const Badge = createSimpleComponent({
  Component: 'span',
  className: cls.wrapper,
  displayName: 'Badge',
});

export default Badge;
