import React from 'react';
import { Button, ButtonPropsBase } from '@duik/it';
import cls from './linkbutton.module.scss';

type buttonType = {
  title?: string;
  link?: string;
};

export const LinkButton = (props: buttonType) => {
  const { title, link } = props;

  return (
    <Button className={cls.btn}>
       <a
        href={link}
        target="_blank"
        rel="noopener noreferrer" 
      >
        {title}
      </a>
    </Button>
  );
};
