
import * as React from 'react'
import classnames from 'classnames'

import cls from './top-bar-title.module.scss'


// @flow
type TopBarTitleProps = JSX.IntrinsicElements['h2'] & {
  children?: React.ReactNode,
  className?: string,
  large?: Boolean
}

const TopBarTitle = ({
  children,
  className,
  large,
  ...rest
}: TopBarTitleProps) => (
    <h2
      className={classnames(cls.wrapper, className, {
        [cls.large]: large,
      })}
      {...rest}
    >
      {children}
    </h2>
  )

TopBarTitle.defaultProps = {
  className: null,
  children: null,
  large: false,
}

export default TopBarTitle
