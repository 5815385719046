/* eslint-disable max-len */
import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  WidgetContent,
  Input,
  FormInputGroup,
  Button,
  Checkbox,
} from "components";
import SimpleContainer from "../components/SimpleContainer";
import * as authActions from "@redux/auth/actions";
import SignupSchema from "./validateSignup";

import cls from "./stryker-signup.module.scss";

type PageSignUpProps = {
  /**
   * inject signup redux function
   */
  signUp: Function,
  /**
   * rdx store
   */
  auth: Object,
  history: Object,
  location: Object,
  JFApp?: Boolean,
};

class PageSignUp extends React.PureComponent<PageSignUpProps> {
  handleSignUp = (data, actions) => {
    const { signUp, history } = this.props;
    signUp(data)
      .then((res) => {
        history.push("/auth/sign-in");
        return res;
      })
      .catch((err) => {
        if (err.status === 409) {
          actions.setFieldError("email", err.error);
        } else if (err.status === 401) {
          actions.setFieldError("password", err.error);
          actions.setFieldError(
            "email",
            "You already have an stryker account with the email address"
          );
        }
      });
  };

  render() {
    const { auth, location } = this.props;
    return (
      <React.Fragment>
        <SimpleContainer>
          <WidgetContent>
            <h2>Create Your Account</h2>
            <br />
            <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                passwordconfirm: "",
                agree_terms: false,
              }}
              validationSchema={SignupSchema}
              onSubmit={this.handleSignUp}
            >
              {({
                errors,
                touched,
                handleChange,
                handleSubmit,
                values,
                handleBlur,
                setErrors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormInputGroup>
                    <Input
                      label="First name"
                      name="firstname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.firstname && touched.firstname
                          ? errors.firstname
                          : null
                      }
                    />
                    <Input
                      label="Last name"
                      name="lastname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.lastname && touched.lastname
                          ? errors.lastname
                          : null
                      }
                    />
                    <Input
                      label="E-mail address"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="your@email.com"
                      errorMessage={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                    <Input
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      errorMessage={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                    <Input
                      label="Password Confirm"
                      name="passwordconfirm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      errorMessage={
                        errors.passwordconfirm && touched.passwordconfirm
                          ? errors.passwordconfirm
                          : null
                      }
                    />
                    <br />
                    <Checkbox
                      name={"agree_terms"}
                      description={
                        errors.agree_terms ? errors.agree_terms : null
                      }
                      onChange={handleChange}
                      color={errors.agree_terms ? "red" : "green"}
                      label={
                        <>
                          I agree to&nbsp;
                          <a href={"https://stryker.com"} target={"_blank"}>
                            Terms of Service
                          </a>
                          &nbsp;and&nbsp;
                          <a href={"https://stryker.com"} target={"_blank"}>
                            Privacy Policy
                          </a>
                        </>
                      }
                    />

                    <br />
                    <Button
                      isLoading={auth.isLoading}
                      className={cls.btnAction}
                      primary
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </FormInputGroup>
                </form>
              )}
            </Formik>
          </WidgetContent>
          {/* <div className={cls.right}>
              <h3 className={cls.headline}>Golemio App</h3>
              <p className={cls.desc}>Prague Administration Dashboard</p>
            </div> */}
        </SimpleContainer>
        {/* <a
          href="https://dev-uv34vdnd.us.auth0.com/login?state=aaa&client=f4P1EARmgPfpJqCJbxKnarp2OholslY9&protocol=oauth2&
    response_type=token&
    redirect_uri=https://localhost:3000&
    scope=openid%20profile%20email"
        >
          SSO
        </a> */}
        <p>
          Already on stryker? Let us take you to{" "}
          <Link to={"/auth/sign-in" + location.search}>Sign In!</Link>
        </p>
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  {
    signUp: authActions.signUp,
  }
)(PageSignUp);
