import initialState from './initialState'


const formReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * reset form errors when changing location
     */
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        errors: {},
      }

    case '@form/ERROR_SET':
      return {
        ...state,
        errors: {
          ...action.payload,
        },
      }
    default: return state
  }
}

export default formReducer
