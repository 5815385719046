// @flow
import * as React from "react";
// import qs from 'query-string';
import { Button } from "@duik/it";
import SimpleContainer from "../components/SimpleContainer";

import cls from "./stryker-ssoauth.module.scss";

class PageSSOAuth extends React.Component {
  handleAuthentication = () => {
    //window.location.href = process.env.REACT_APP_SSO_URL;
    window.location.href = `https://s2sp.simple.science/simplesaml/relay/token.php?target=${window.location.hostname}`;
  };

  render() {
    return (
      <SimpleContainer className={cls.container}>
        <div className={cls.title}>Login</div>
        <div className={cls.description}>
          Enter using your Stryker login.
        </div>

        <Button onClick={this.handleAuthentication} class={cls.ssoAuth}>
          LOG IN VIA STRYKER
        </Button>
      </SimpleContainer>
    );
  }
}

export default PageSSOAuth;
