import * as React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { AppState } from "@redux";

import * as appActions from "@redux/app/actions";

import Breadcrumb, { CrumbRoutes } from "./Breadcrumb";
import UserDropdownConnected from "../UserDropdownConnected";

import {
  Button,
  TopBar,
  TopBarSection,
  NavBurger,
  TopBarTitle,
} from "components";

import cls from "./dashboard-top-bar.module.scss";

type BuildingsTopBarProps = {
  toggleMenu: () => void;
  className?: string;
  isMenuOpen: boolean;
  rightEl?: React.ReactNode;
  rootRouteMap: CrumbRoutes;
  isPopup?: boolean;
};

const BuildingsTopBar = ({
  toggleMenu,
  className,
  isMenuOpen,
  rightEl,
  rootRouteMap,
  isPopup,
}: BuildingsTopBarProps) => (
  <div style={{ height: "115px" }}>
    <TopBar className={classnames(cls.wrapper, className)}>
      {/* {!isPopup && (
        <Button className={cls.menuButton} onClick={toggleMenu} transparent>
          <NavBurger isOpen={isMenuOpen} />
        </Button>
      )} */}
      <TopBarSection className={cls.appNameTitle}>
        <TopBarTitle className={cls.logo}>
          <a href={"/"}>
            <img src="/assets/images/logo.png" className={cls.logoImg} />
          </a>
        </TopBarTitle>
      </TopBarSection>
      <TopBarSection className={cls.actions}>
        {rightEl}
        <UserDropdownConnected isPopup={isPopup} />
      </TopBarSection>
    </TopBar>
    <div className={cls.bottomline}>
      <p>
        <span className={cls.left}>STRYKER ENDOSCOPY | </span>
        <span className={cls.right}>MEDICAL EDUCATION</span>
      </p>
    </div>
    {/* <div>
      <h2>text here</h2>
    </div> */}
  </div>
);

BuildingsTopBar.defaultProps = {
  className: null,
  rightEl: null,
};

export default connect(
  (store: AppState) => ({
    isMenuOpen: store.app.isMenuOpen,
  }),
  {
    toggleMenu: appActions.toggleMenu,
  }
)(BuildingsTopBar);
