import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "@redux";

import classnames from "classnames";

import * as appActions from "@redux/app/actions";

import {
  ScrollArea,
  Button,
  Icon,
  ContainerVertical,
  TopBar,
} from "components";

import { NavPanel } from "@duik/it";

import AppMenu from "./AppMenu";

import cls from "./dashboard-navigation.module.scss";

type BaseNavigationPanelProps = {
  className?: string;
  children: React.ReactNode;
  isMenuOpen: boolean;
  style?: React.CSSProperties;
  title?: string;
  iconName?: string;
  toggleNavigationCollapse: () => void;
  isNavigationCollapsed?: boolean;
};

const BaseNavigationPanel = ({
  className,
  children,
  isMenuOpen,
  style,
  title,
  iconName,
  toggleNavigationCollapse,
  isNavigationCollapsed,
}: BaseNavigationPanelProps) => {
  return (
    <NavPanel
      dark
      className={classnames(cls.wrapper, className, {
        [cls.open]: isMenuOpen,
        [cls.collapsed]: isNavigationCollapsed,
      })}
      style={style}
    >
      <ContainerVertical>
        <TopBar className={cls.topBar}>
          <AppMenu
            isNavigationCollapsed={isNavigationCollapsed}
            title={title}
            iconName={iconName}
          />
        </TopBar>

        <ScrollArea className={cls.content}>
          {children}
          {isNavigationCollapsed && (
            <div
              className={cls.fillExpand}
              onClick={toggleNavigationCollapse}
            />
          )}
        </ScrollArea>
        <div>
          <Button
            className={cls.buttonExpandCollapse}
            clear
            icon={
              <Icon
                prefix={"far"}
                name={isNavigationCollapsed ? "chevron-right" : "chevron-left"}
                style={{ color: "white" }}
              />
            }
            iconOnly
            onClick={toggleNavigationCollapse}
          />
        </div>
        <div
          className={classnames(cls.aboutAppContainer, {
            [cls.collapsed]: isNavigationCollapsed,
          })}
        >
          <div className={cls.copyright}>© SAM 2020</div>
          <div className={cls.copyAbout}>
            Created with love from the team at{" "}
            <a
              href="http://softwaresamgroup.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              SAM
            </a>
          </div>
        </div>
      </ContainerVertical>
    </NavPanel>
  );
};

BaseNavigationPanel.defaultProps = {
  className: null,
};

export default connect(
  (state: AppState) => ({
    isMenuOpen: state.app.isMenuOpen,
    isNavigationCollapsed: state.app.isNavigationCollapsed,
  }),
  {
    toggleNavigationCollapse: appActions.toggleNavigationCollapse,
  }
)(BaseNavigationPanel);
