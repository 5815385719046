import * as React from 'react';
import classnames from 'classnames';
import { AnyT, PropsWithTagProps } from '../utils';

import cls from './widget.module.scss';

type WidgetPropsBase = JSX.IntrinsicElements['div'] & {
  padding?: Boolean;
  margin?: Boolean;
};

export type PaginationItemProps<T extends AnyT> = PropsWithTagProps<
  T,
  WidgetPropsBase & { Component?: T }
>;

const Widget = <T extends AnyT = 'div'>({
  children,
  className,
  padding,
  margin,
  Component = 'div',
  ...rest
}: PaginationItemProps<T>) => (
  <Component
    className={classnames(cls.wrapper, className, {
      [cls.padding]: padding,
      [cls.margin]: margin,
    })}
    {...rest}
  >
    {children}
  </Component>
);

Widget.defaultProps = {
  className: null,
  children: null,
  padding: false,
  margin: false,
};

export default Widget;
