import localStorage from "helpers/localStorage";
import { initialState } from "./initialState";

/**
 * As an initial state,
 * we are merging default initial state and data from the localstorage first
 */

const initReducerState = {
  isInitLoading: true,
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
};

export default () => ({
  ...initialState,
  ...{
    app: {
      ...initialState.app,
      ...(localStorage("@app").get() as object),
      ...initReducerState,
    },
    apps: {
      ...initialState.apps,
      ...(localStorage("@apps").get() as object),
      ...initReducerState,
    },
    auth: {
      ...initialState.auth,
      ...(localStorage("@auth").get() as object),
      ...initReducerState,
    },
  },
});
