// @flow
import * as React from 'react'
import classnames from 'classnames'

import cls from './form-input-group.module.scss'

type FormInputGroupProps = JSX.IntrinsicElements['div'] & {
  className?: string,
  children: React.ReactNode,
  direction?: 'vertical' | 'horizontal',
  horizontal?: boolean,
  largeGap?: boolean,
}

const FormInputGroup = ({
  children,
  className,
  direction,
  horizontal,
  largeGap,
  ...rest
}: FormInputGroupProps) => (
    <div
      className={classnames(className, {
        [cls.horizontal]: direction === 'horizontal' || horizontal,
        [cls.vertical]: !(direction === 'horizontal' || horizontal),
        [cls.largeGap]: largeGap
      })}
      {...rest}
    >
      {children}
    </div>
  )


FormInputGroup.defaultProps = {
  className: null,
  direction: 'vertical',
  horizontal: false,
}

export default FormInputGroup
