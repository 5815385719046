// @flow
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authActions from '@redux/auth/actions';
import { Formik, FormikProps } from 'formik';

import { WidgetContent, Input, FormInputGroup, Button } from 'components';
import { authState } from '@redux/auth/types';
import { AppState } from '@redux';

import SimpleContainer from '../components/SimpleContainer';

import PwdResetSchema from './passwordReset';

interface FormValues {
  newPassword: string;
  newPasswordConfirm: string;
}

interface MatchParams {
  email: string;
  resetToken: string;
}

type PagePasswordResetProps = RouteComponentProps<{
  email: string;
  resetToken: string;
}> & {
  /**
   * injected rdx action
   */
  passwordReset: Function;
  /**
   * rdx store
   */
  auth: authState;
};

const PagePasswordReset = ({
  passwordReset,
  auth,
  match,
  history,
}: PagePasswordResetProps) => {
  const [hasReseted, setHasReseted] = React.useState(false);

  const handleClickReset = data => {
    const { resetToken, email } = match.params;
    passwordReset(resetToken, {
      email,
      password: data.newPassword,
    })
      .then(res => {
        setHasReseted(true);
        setTimeout(() => {
          history.push('/auth/sign-in');
        }, 4000);
        return res;
      })
      .catch(err => {
        console.log('Could not reset password');
      });
  };

  const initialValues: FormValues = {
    newPassword: '',
    newPasswordConfirm: '',
  };

  return (
    <React.Fragment>
      <SimpleContainer>
        {hasReseted ? (
          <WidgetContent>
            <h2>Successfully reset!</h2>
            <p>You will be redirected to login page.</p>
          </WidgetContent>
        ) : (
          <WidgetContent>
            <h2>Set your new password</h2>
            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={PwdResetSchema}
              onSubmit={handleClickReset}
            >
              {({
                errors,
                touched,
                handleChange,
                handleSubmit,
                values,
                handleBlur,
                setErrors,
              }: FormikProps<FormValues>) => (
                <form onSubmit={handleSubmit}>
                  <FormInputGroup>
                    <Input
                      label="New Password"
                      name="newPassword"
                      placeholder="··········"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.newPassword && touched.newPassword
                          ? errors.newPassword
                          : null
                      }
                    />
                    <Input
                      label="Repeat password"
                      name="newPasswordConfirm"
                      placeholder="··········"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.newPasswordConfirm && touched.newPasswordConfirm
                          ? errors.newPasswordConfirm
                          : null
                      }
                    />

                    <Button isLoading={auth.isLoading} primary type="submit">
                      Set New Password
                    </Button>
                  </FormInputGroup>
                </form>
              )}
            </Formik>
          </WidgetContent>
        )}
      </SimpleContainer>

      <p>
        {'Want to log in? '}
        <Link to="/auth/sign-in">Go to login page.</Link>
      </p>
    </React.Fragment>
  );
};

export default connect(
  (state: AppState) => ({
    auth: state.auth,
  }),
  {
    passwordReset: authActions.passwordReset,
  },
)(PagePasswordReset);
