import * as React from "react";
import supportLinks from "./supportLinks.json";
import { useRedux } from "@redux";
import { connect } from "react-redux";
export type FooterProps = {
  location?: object;
  hostory?: object;
};

declare global {
  interface Window {
    supportpalAsyncInit: any;
  }
}
declare var $crisp;
export type FooterState = {};

// export class Footer extends React.Component<FooterProps , FooterState>{
// 	static contextTypes = {
// 		redux: React.PropTypes.object
// 	}
// 	constructor(props){
// 		super(props)
// 		this.findMatch = this.findMatch.bind(this)
// 	}
//
// 	findMatch(path:string){
// 		const keys = Object.keys(supportLinks.pages)
// 		for(let i = 0; i < keys.length; i++) {
// 			let regexp = new RegExp(keys[i], 'g');
// 			if(regexp.test(path)){
// 				return supportLinks.pages[keys[i]]
// 			}
// 		}
// 		return false
// 	}
// 	componentDidMount(){
// 		const auth = useRedux('auth')
// 		const user = auth.data.user;
// 		if(user) {
// 			$crisp.push(["set", "user:email", user.email]);
// 			$crisp.push(["set", "user:nickname", `${user.first_name} ${user.last_name}`]);
// 			$crisp.push(["do", "chat:open"]);
// 			$crisp.push(["on", "chat:opened", (e) => {
// 				$crisp.push(["do", "message:thread:start"])
// 			}])
// 			$crisp.push(["on", "chat:closed", (e) => {
// 				$crisp.push(["do", "message:thread:end"])
// 			}])
// 		}
// 		// window.supportpalAsyncInit = function () {
// 	    //     SupportPal.mount({
// 	    //         "baseURL": "https://support.stryker.us/helpwidget",
// 	    //         "hash": "iAmtJAQaue",
// 	    //         "knowledgebase": {
// 	    //             "enabled": true,
// 	    //             "typeId": "3"
// 	    //         },
// 	    //         "submitTicket": {
// 	    //             "enabled": true,
// 	    //             "departmentId": "1",
// 	    //             "subject": true,
// 	    //             "defaults": {
// 	    //                 "name": "John Doe",
// 	    //                 "email": "john@doe.com",
// 	    //                 "subject": "My Subject",
// 	    //                 "message": "Message Body..."
// 	    //             }
// 	    //         },
// 	    //         "type": "sidebar",
// 	    //         "position": "right",
// 	    //         "colour": "#000000",
// 	    //         "buttonIcon": "bubble2"
// 	    //     });
// 	    //     //find matching page via regex
// 	    //     SupportPal.on('ready', function (el) {
//         //         document.querySelector('supportpal-widget button.dock').addEventListener('click', function(){
//         //             let path = this.props.location.pathname;//findMatch( currentPageUrl, supportLinks ) - does regex of page to find a match on the pages keys and returns the key
// 		// 	        let match = this.findMatch(path)
// 		// 	        if(!match) return false;
//         //             let input:HTMLInputElement = document.querySelector('div.search-container input')
//         //             input.focus()
//         //             input.value = match.search
//         //             let list = document.querySelector('div.widget-container ol.article-list')
//         //             let child = (list == null)? false : list.lastElementChild;
//         //             while (child) {
//         //                 list.removeChild(child);
//         //                 child = list.lastElementChild;
//         //             }
//         //             let links = match.links
//         //             links.forEach( link => {
//         //                 let li  = document.createElement('li'),
//         //                     a = document.createElement('a'),
//         //                     text = document.createTextNode(link.label);
//         //                 // li.setAttribute('class','')
//         //                 a.setAttribute('href',link.url)
//         //                 a.setAttribute('target','_blank')
//         //                 a.appendChild(text);
//         //                 li.appendChild(a)
//         //                 list!= null && list.appendChild(li);
// 		//
//         //             })
//         //         }.bind(this))
//         //     }.bind(this));
// 	    // }.bind(this);
// 		//
// 	    // let src =  "https://support.stryker.us/app/Modules/Ticket/Channels/HelpWidget/Views/js/supportpal.min.js?v=2.5.1%27";
//         // let script = document.createElement('script');
//         // script.src = src;
//         // script.async = true;
//         // document.body.appendChild(script)
//
//
// 	}
//
// 	render(){
// 		return(
// 		  <div>
//
// 		  </div>
// 		)
// 	}
// }
//
// export default Footer;
function Footer() {
  const auth = useRedux("auth");
  // const user = auth.data.user;

  return <div></div>;
}

export default Footer;
