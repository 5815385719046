// @flow
import React from "react";
import { connect } from "react-redux";
import * as appActions from "@redux/app/actions";
import { AppState } from "@redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import OutsideClickHandler from "components/OutsideClickHandler";

import { Icon, TopBarSection, TopBarTitle } from "components";

import cls from "./appMenu.module.scss";

import apps from "./apps";

type AppMenuType = {
  isAppMenuOpen: Boolean;
  setAppMenuClose: () => void;
  setAppMenuOpen: () => void;
  title: React.ReactNode;
  iconName: string;
  isNavigationCollapsed: boolean;
};

const AppMenu = ({
  isAppMenuOpen,
  setAppMenuOpen,
  setAppMenuClose,
  title,
  iconName,
  isNavigationCollapsed,
}: AppMenuType) => {
  return (
    <OutsideClickHandler
      className={cls.appMenuWrapper}
      onOutsideClick={isAppMenuOpen ? setAppMenuClose : null}
    >
      <div
        className={classnames(cls.appSwitchButton, {
          [cls.collapsed]: isNavigationCollapsed,
        })}
      >
        <TopBarSection className={cls.appNameTitle}>
          <TopBarTitle className={cls.title}>
            <img src="/assets/images/logo.png" className={cls.logoImg} />
          </TopBarTitle>
        </TopBarSection>
        <TopBarSection className={cls.hoverText} Component={Link} to="/app">
          Smart Assets Management
        </TopBarSection>
        <TopBarSection
          className={classnames(cls.iconButton, cls.iconWrapper)}
          Component={Link}
          to="/app"
        >
          <Icon prefix={"fad"} name={"stream"} />
        </TopBarSection>
        {/* <button
          className={classnames(cls.iconButton, cls.iconWrapper)}
          onClick={isAppMenuOpen ? setAppMenuClose : setAppMenuOpen}
          type="button"
        >
          <Icon prefix={"fad"} name={"stream"}/>
        </button> */}
      </div>

      <div
        className={classnames(cls.appMenu, {
          [cls.appMenuOpen]: isAppMenuOpen,
        })}
      >
        {
          // apps.map(item => (
          //   <Link
          //     key={item.title}
          //     className={cls.appItem}
          //     to={`/app${item.to}`}
          //   >
          //     <Icon prefix={'far'} name={item.icon} className={cls.icon}/>
          //     <span className={cls.title}>{item.title}</span>
          //   </Link>
          // ))
        }
      </div>
    </OutsideClickHandler>
  );
};

export default connect(
  (state: AppState) => ({
    isAppMenuOpen: state.app.isAppMenuOpen,
  }),
  {
    setAppMenuOpen: appActions.setAppMenuOpen,
    setAppMenuClose: appActions.setAppMenuClose,
  }
)(AppMenu);
