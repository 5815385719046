// for components that just needs styling

import * as React from 'react';
import classnames from 'classnames';

import { AnyTag, WithTagProps } from './utils'

type Props = {
  displayName: string,
  className?: string,
}

type ICreateSimpleComponent = {
  defaultProps?: Props
} & Props

type ComponentProps = React.PropsWithChildren<{
  className?: string,
}>

export function createSimpleComponent<Tag extends AnyTag<'div'>>(
  props: { Component?: Tag } & WithTagProps<Tag, ICreateSimpleComponent>
): (props: any) => JSX.Element;

export function createSimpleComponent({
  displayName,
  className: defaultClassName,
  Component: DefaultComponent,
  defaultProps
}) {

  function foo<Tag extends AnyTag<'div'>>(
    props: { Component?: Tag } & WithTagProps<Tag, ComponentProps>
  ): JSX.Element;

  function foo({
    className, Component, children, ...rest
  }) {
    return (
      <Component
        className={
          classnames(
            defaultClassName,
            className,
          )
        }
        {...rest}
      >
        {children}
      </Component>
    )
  }

  foo.defaultProps = {
    className: null,
    Component: DefaultComponent,
    children: null,
    ...defaultProps,
  }

  foo.displayName = displayName

  return foo

}

export default createSimpleComponent