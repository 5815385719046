import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { DropdownItem } from "@duik/it";
import UserDropdown from "components/UserDropdown";
import * as authActions from "@redux/auth/actions";
import { AppState } from "@redux";
import cls from "./user-dropdown.module.scss";
type Auth = {
  data?: {
    user: {
      first_name?: string;
      last_name?: string;
      icon_url?: string;
      is_trial?: boolean;
    };
  };
};

const getAvatarProps = (auth: Auth) => {
  if (!auth || !auth.data || !auth.data.user) {
    return null;
  }

  const user = auth.data.user;

  const avatarPlaceholder = {
    color: "blue",
    content: "",
  };

  if (user.first_name) {
    avatarPlaceholder.content += user.first_name[0];
  }

  if (user.last_name) {
    avatarPlaceholder.content += user.last_name[0];
  }

  return {
    avatarPlaceholder,
    imgUrl: user.icon_url,
    name: `${user.first_name} ${user.last_name}`,
    className: cls.avatarTxt,
  };
};
type UserDropdownProps = {
  isPopup?: boolean;
};
const UserDropdownC = ({ isPopup = false }: UserDropdownProps) => {
  const authState = useSelector((state: AppState) => state.auth);
  const d = useDispatch();

  const handleLogout = () => {
    d(authActions.signOut());
  };
  return (
    <UserDropdown
      buttonProps={{
        avatarProps: getAvatarProps(authState as any),
      }}
    >
      {!isPopup && (
        <>
          {authState.data.user.user_role === 3 && (
            <DropdownItem Component={Link} to="/app/users/list">
              Manage Users
            </DropdownItem>
          )}
          <DropdownItem Component={Link} to="/app/personal">
            Update Profile
          </DropdownItem>
        </>
      )}
      <DropdownItem onClick={handleLogout}>Sign Out</DropdownItem>
    </UserDropdown>
  );
};

export default UserDropdownC;
