import * as Yup from 'yup';

const PwdResetSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Password is required')
    .min(
      8,
      'Use 8 or more characters with a mix of letters, numbers & symbols.',
    )
    .matches(
      /([(?=.*?[a-z])(?=.*?[0-9])/,
      'Use 8 or more characters with a mix of letters, numbers & symbols.',
    ),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match!')
    .required('Password confirm is required'),
});

export default PwdResetSchema;
