// Libraries
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import Report from "./Report";
import Auth from "./Auth";
import SSO from "./SSO";
import Footer from "./Footer";
const Main = () => (
  <>
    <Route component={Footer} />
    <Switch>
      <Route component={Auth} path="/auth" />
      <Route component={Dashboard} path="/app" />
      <Route component={Report} path="/csv" />
      <Route component={SSO} path="/" />
    </Switch>
  </>
);

export default Main;
