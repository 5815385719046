import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CSVLink } from 'react-csv';
import { useIsOpenControls } from "hooks";
import { TablePaginationConfig } from "antd/es/table";
import "antd/dist/antd.css";
import { WidgetContainer, Avatar } from "@duik/it";
import { strToDate, dateFormat } from "helpers/dateFormat";
import { useRedux, useReduxLoading } from "@redux";
import { getFellows } from "@redux/fellows/actions";
import {
  Button,
  TopBar,
  ContentLoader,
  ScrollArea,
  ContainerHorizontal,
  ContainerVertical,
} from "components";
import { ColumnsType } from "antd/es/table";
import { Fellow } from "@redux/fellows/types";
import PageTitle from "../components/PageTitle";
import cls from "./fellows-list.module.scss";
// import { columns as dcolumns } from "./columns";

type userTableType = Fellow & {
  key?: string | number;
  company_name?: string;
};

let pagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
};
let checkType: any = "checkbox";
const rowSelection = {
  type: checkType,
  onChange: (selectedRowKeys, selectedRows) => {},
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};
export const FellowsList = (props) => {
  const territory_options = [
    {value: 0, label: 'Akron',},
    {value: 1, label: 'Alaska',},
    {value: 2, label: 'Albany',},
    {value: 3, label: 'Ann Arbor',},
    {value: 4, label: 'Asheville',},
    {value: 5, label: 'Atlanta - North',},
    {value: 6, label: 'Atlanta Split',},
    {value: 7, label: 'Austin South',},
    {value: 8, label: 'Bakersfield',},
    {value: 9, label: 'Baltimore Central',},
    {value: 10, label: 'Baltimore North',},
    {value: 11, label: 'Baltimore South',},
    {value: 12, label: 'Baton Rouge',},
    {value: 13, label: 'Beaumont',},
    {value: 14, label: 'Bend',},
    {value: 15, label: 'Birmingham',},
    {value: 16, label: 'Black Hills',},
    {value: 17, label: 'Boca Raton',},
    {value: 18, label: 'Boise',},
    {value: 19, label: 'Boston North',},
    {value: 20, label: 'Boston West Split',},
    {value: 21, label: 'Bronx-Westchester',},
    {value: 22, label: 'Brooklyn',},
    {value: 23, label: 'Buckeye Valley',},
    {value: 24, label: 'Buffalo',},
    {value: 25, label: 'Central Detroit',},
    {value: 26, label: 'Central Iowa North',},
    {value: 27, label: 'Central Iowa South',},
    {value: 28, label: 'Central Jersey',},
    {value: 29, label: 'Central Minnesota',},
    {value: 30, label: 'Central Pennsylvania',},
    {value: 31, label: 'Central Texas',},
    {value: 32, label: 'Central Valley',},
    {value: 33, label: 'Central Wisconsin',},
    {value: 34, label: 'Charleston Expansion',},
    {value: 35, label: 'Charlotte Split',},
    {value: 36, label: 'Chattanooga',},
    {value: 37, label: 'Chicago Downtown North',},
    {value: 38, label: 'Chicago Downtown South',},
    {value: 39, label: 'Chicago Downtown West',},
    {value: 40, label: 'Chicago North',},
    {value: 41, label: 'Chicago NW',},
    {value: 42, label: 'Chicago South',},
    {value: 43, label: 'Chicago SW',},
    {value: 44, label: 'Chicago West',},
    {value: 45, label: 'Chico',},
    {value: 46, label: 'Cincinnati',},
    {value: 47, label: 'Cincinnati Metro',},
    {value: 48, label: 'Cleveland Downtown',},
    {value: 49, label: 'Cleveland East',},
    {value: 50, label: 'Cleveland West',},
    {value: 51, label: 'College Station',},
    {value: 52, label: 'Columbia MO',},
    {value: 53, label: 'Columbia SC',},
    {value: 54, label: 'Columbus North',},
    {value: 55, label: 'Columbus South',},
    {value: 56, label: 'Dallas East Split',},
    {value: 57, label: 'Dayton West',},
    {value: 58, label: 'DC Metro',},
    {value: 59, label: 'Denver East',},
    {value: 60, label: 'Denver West',},
    {value: 61, label: 'Detroit',},
    {value: 62, label: 'DFW North',},
    {value: 63, label: 'DFW South',},
    {value: 64, label: 'DFW Split',},
    {value: 65, label: 'DFW West',},
    {value: 66, label: 'DT Houston',},
    {value: 67, label: 'East Bay',},
    {value: 68, label: 'East Houston',},
    {value: 69, label: 'El Paso Split',},
    {value: 70, label: 'Erie, PA',},
    {value: 71, label: 'Eugene',},
    {value: 72, label: 'Evansville',},
    {value: 73, label: 'Fargo, ND',},
    {value: 74, label: 'Fox Valley',},
    {value: 75, label: 'Fremont',},
    {value: 76, label: 'Fresno',},
    {value: 77, label: 'Ft. Wayne',},
    {value: 78, label: 'Grand Rapids',},
    {value: 79, label: 'Greater DC',},
    {value: 80, label: 'Greater Miami Split',},
    {value: 81, label: 'Greensboro, NC',},
    {value: 82, label: 'Greenville/Augusta',},
    {value: 83, label: 'Hampton Roads',},
    {value: 84, label: 'Harrisburg',},
    {value: 85, label: 'Hattiesburg',},
    {value: 86, label: 'Hawaii Split',},
    {value: 87, label: 'Hill Country',},
    {value: 88, label: 'Houston North',},
    {value: 89, label: 'Houston South',},
    {value: 90, label: 'Houston SW',},
    {value: 91, label: 'Hudson Valley',},
    {value: 92, label: 'Huntsville Split',},
    {value: 93, label: 'Indiana Shores',},
    {value: 94, label: 'Indianapolis Metro',},
    {value: 95, label: 'Indianapolis So.',},
    {value: 96, label: 'Inland Empire North',},
    {value: 97, label: 'Inland Empire South',},
    {value: 98, label: 'Inland Empire West',},
    {value: 99, label: 'Iowa City',},
    {value: 100, label: 'Jackson',},
    {value: 101, label: 'Jacksonville, FL',},
    {value: 102, label: 'Jersey Shore',},
    {value: 103, label: 'Jonesboro Expansion',},
    {value: 104, label: 'Kansas City',},
    {value: 105, label: 'Kansas City South',},
    {value: 106, label: 'Kearney, NE Split',},
    {value: 107, label: 'Knoxville South',},
    {value: 108, label: 'LA North',},
    {value: 109, label: 'Lake Charles',},
    {value: 110, label: 'Lansing',},
    {value: 111, label: 'Las Vegas North',},
    {value: 112, label: 'Las Vegas South',},
    {value: 113, label: 'Lehigh Valley',},
    {value: 114, label: 'Lexington',},
    {value: 115, label: 'Little Rock Split',},
    {value: 116, label: 'Long Beach',},
    {value: 117, label: 'Long Beach South',},
    {value: 118, label: 'Long Island East',},
    {value: 119, label: 'Long Island West',},
    {value: 120, label: 'Los Angeles Downtown',},
    {value: 121, label: 'Los Angeles South',},
    {value: 122, label: 'Los Angeles West',},
    {value: 123, label: 'Louisville Metro',},
    {value: 124, label: 'Lubbock Split',},
    {value: 125, label: 'Maine',},
    {value: 126, label: 'Manhattan',},
    {value: 127, label: 'Manhattan Expansion',},
    {value: 128, label: 'MD-DE Shore',},
    {value: 129, label: 'Memphis',},
    {value: 130, label: 'Milwaukee Central',},
    {value: 131, label: 'Milwaukee North',},
    {value: 132, label: 'Minneapolis North',},
    {value: 133, label: 'Mobile',},
    {value: 134, label: 'Montana East',},
    {value: 135, label: 'Montana West',},
    {value: 136, label: 'Montgomery',},
    {value: 137, label: 'N. Mississippi',},
    {value: 138, label: 'Naples/Ft. Myers split',},
    {value: 139, label: 'Nashville North',},
    {value: 140, label: 'Nashville South',},
    {value: 141, label: 'NE Kansas',},
    {value: 142, label: 'NE Texas',},
    {value: 143, label: 'New Hampshire',},
    {value: 144, label: 'New Mexico Split',},
    {value: 145, label: 'New Orleans North Shore',},
    {value: 146, label: 'New Orleans South Shore',},
    {value: 147, label: 'Newark North',},
    {value: 148, label: 'Newark South',},
    {value: 149, label: 'Newark West',},
    {value: 150, label: 'No. Colorado',},
    {value: 151, label: 'North Austin',},
    {value: 152, label: 'North Bay',},
    {value: 153, label: 'North Central Indiana',},
    {value: 154, label: 'Northern Arizona',},
    {value: 155, label: 'Northern Virginia',},
    {value: 156, label: 'NW Arkansas',},
    {value: 157, label: 'NW Indiana',},
    {value: 158, label: 'Oakland',},
    {value: 159, label: 'Ogden',},
    {value: 160, label: 'Oklahoma City East',},
    {value: 161, label: 'Oklahoma City South',},
    {value: 162, label: 'Oklahoma City West',},
    {value: 163, label: 'Omaha Split',},
    {value: 164, label: 'Orange County North',},
    {value: 165, label: 'Orange County South',},
    {value: 166, label: 'Orlando',},
    {value: 167, label: 'Oshkosh',},
    {value: 168, label: 'Palm Beach',},
    {value: 169, label: 'Palm Springs',},
    {value: 170, label: 'Pasadena',},
    {value: 171, label: 'Peninsula',},
    {value: 172, label: 'Pensacola',},
    {value: 173, label: 'Peoria, IL',},
    {value: 174, label: 'Philadelphia Downtown',},
    {value: 175, label: 'Philadelphia North Split',},
    {value: 176, label: 'Philadelphia West',},
    {value: 177, label: 'Phoenix Central',},
    {value: 178, label: 'Phoenix East',},
    {value: 179, label: 'Phoenix South',},
    {value: 180, label: 'Phoenix West',},
    {value: 181, label: 'Pittsburgh Central',},
    {value: 182, label: 'Pittsburgh East',},
    {value: 183, label: 'Pittsburgh So.',},
    {value: 184, label: 'Portland',},
    {value: 185, label: 'Portland West',},
    {value: 186, label: 'Providence',},
    {value: 187, label: 'Provo',},
    {value: 188, label: 'Quad Cities',},
    {value: 189, label: 'Queens',},
    {value: 190, label: 'Raleigh-Durham Split',},
    {value: 191, label: 'Reno',},
    {value: 192, label: 'Richmond Split',},
    {value: 193, label: 'Rio Grande Valley Split',},
    {value: 194, label: 'Roanoke',},
    {value: 195, label: 'Rochester',},
    {value: 196, label: 'Sacramento',},
    {value: 197, label: 'Sacramento West',},
    {value: 198, label: 'Saginaw',},
    {value: 199, label: 'Salt Lake City',},
    {value: 200, label: 'San Antonio Split',},
    {value: 201, label: 'San Diego Central',},
    {value: 202, label: 'San Diego North',},
    {value: 203, label: 'San Diego South',},
    {value: 204, label: 'San Fernando Valley',},
    {value: 205, label: 'San Francisco',},
    {value: 206, label: 'Sandhills NC',},
    {value: 207, label: 'Santa Barbara',},
    {value: 208, label: 'Sarasota',},
    {value: 209, label: 'Savannah',},
    {value: 210, label: 'Scranton',},
    {value: 211, label: 'SE Idaho',},
    {value: 212, label: 'Seattle Central',},
    {value: 213, label: 'Seattle East',},
    {value: 214, label: 'Seattle North',},
    {value: 215, label: 'Seattle South',},
    {value: 216, label: 'Shenadoah Valley',},
    {value: 217, label: 'Shreveport Monroe',},
    {value: 218, label: 'Sioux Falls, SD',},
    {value: 219, label: 'Siouxland',},
    {value: 220, label: 'So. Colorado',},
    {value: 221, label: 'South Bay',},
    {value: 222, label: 'South Bend',},
    {value: 223, label: 'South Georgia',},
    {value: 224, label: 'South Jersey Split',},
    {value: 225, label: 'Southeast MO',},
    {value: 226, label: 'Southern CT',},
    {value: 227, label: 'Southern Maryland',},
    {value: 228, label: 'Southern Minnesota',},
    {value: 229, label: 'Southwest Virginia',},
    {value: 230, label: 'Space Coast',},
    {value: 231, label: 'Spokane',},
    {value: 232, label: 'Springfield',},
    {value: 233, label: 'Springfield, MO',},
    {value: 234, label: 'St Louis Downtown',},
    {value: 235, label: 'St Louis East',},
    {value: 236, label: 'St Louis West',},
    {value: 237, label: 'St Paul',},
    {value: 238, label: 'Syracuse',},
    {value: 239, label: 'Tacoma',},
    {value: 240, label: 'Tallahassee',},
    {value: 241, label: 'Tampa',},
    {value: 242, label: 'Tidewater Split',},
    {value: 243, label: 'Toledo',},
    {value: 244, label: 'Traverse City',},
    {value: 245, label: 'Tucson',},
    {value: 246, label: 'Tulsa North',},
    {value: 247, label: 'Tulsa South',},
    {value: 248, label: 'Tuscaloosa',},
    {value: 249, label: 'Vermont',},
    {value: 250, label: 'West Virginia North',},
    {value: 251, label: 'West Virginia South Split',},
    {value: 252, label: 'Western Colorado',},
    {value: 253, label: 'Western MA',},
    {value: 254, label: 'Western Minnesota',},
    {value: 255, label: 'Wichita Split',},
    {value: 256, label: 'Wilmington, NC',},
    {value: 257, label: 'Winsboro',},
    {value: 258, label: 'Yakima',},
    {value: 259, label: 'OTHER',},
  ];
  const itemPerPage = 10;
  let currPage = 1;
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const modalAddControls = useIsOpenControls();
  const [currIdx, setCurrIdx] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchTxt, setSearchTxt] = React.useState("");
  const [searchOption, setSearchOption] = React.useState(0);
  const [updatedData, setUpdatedData] = React.useState([]);

  const authState = useRedux("auth");
  const fellowState = useRedux("fellows");
  let filteredUser = fellowState.data
    .filter((fellow) => {
      fellow["updated_at"] = fellow["updated_at"]
        ? dateFormat(strToDate(fellow["updated_at"]))
        : "";
      fellow["created_at"] = fellow["created_at"]
        ? dateFormat(strToDate(fellow["created_at"]))
        : "";
      return (
        fellow["first_name"].toLowerCase().includes(searchTxt.toLowerCase()) ||
        fellow["last_name"].toLowerCase().includes(searchTxt.toLowerCase()) ||
        fellow["email"].toLowerCase().includes(searchTxt.toLowerCase()) || 

        (fellow["territory"]&&
        territory_options[fellow["territory"]].label.toLowerCase().includes(searchTxt.toLowerCase()))
        
        
        ||
        fellow["fellowship_city"]
          .toLowerCase()
          .includes(searchTxt.toLowerCase()) ||
        fellow["fellowship_state"]
          .toLowerCase()
          .includes(searchTxt.toLowerCase()) ||
        fellow["fellowship_program_name"]
          .toLowerCase()
          .includes(searchTxt.toLowerCase()) ||
        fellow["created_at"].toLowerCase().includes(searchTxt.toLowerCase()) ||
        fellow["updated_at"].toLowerCase().includes(searchTxt.toLowerCase())
      );
    })
    .filter((fellow) => {
      return searchOption === 0
        ? true
        : searchOption === 1
        ? fellow["fellowship_city"]
            .toLowerCase()
            .includes(authState.data.user.territory) ||
          fellow["fellowship_state"]
            .toLowerCase()
            .includes(authState.data.user.territory)
        : true;
    })
    .slice(0, itemPerPage * page);

  const dispatch = useDispatch();

  React.useEffect(() => {
    //Get data
    dispatch(getFellows());
    if (!authState.data.user.territory) {
      setTimeout(modalAddControls.handleOpen, 2000);
    }

    //Setup Scroll Listener
    // var tableContent = document.querySelector(".scrollArea");
    // if (!tableContent) return;
    // tableContent.addEventListener("scroll", (event: any) => {
    //   let maxScroll = event.target.scrollHeight - event.target.clientHeight;
    //   let currentScroll = event.target.scrollTop;
    //   if (currentScroll === maxScroll) {

    //   }
    // });
  }, []);

  // React.useEffect(() => {
  //   console.log(
  //     "load more data",
  //     currentPage * itemPerPage,
  //     fellowState.data.length
  //   );
  //   filterUser(currentPage);
  // }, [currentPage]);
  // React.useEffect(()=>{
  //   if(userState.data.length>0){
  //     setUsers()
  //   }
  // }, [userState.data])

  if (useReduxLoading("fellows")) {
    return <ContentLoader>Fetching Fellows</ContentLoader>;
  }

  const { path } = props.match;
  let tmpPath: any = path.split("/");
  tmpPath.pop();
  let newPath = "";
  tmpPath.map((elem) => {
    newPath += elem + "/";
  });
  const dcolumns: ColumnsType<userTableType> = [
    {
      key: "fullname",
      title: "NAME",
      dataIndex: "fullname",
      // width: 80,
      render: (_: any, elem: userTableType, index: number) => {
        return (
          <>
            <Avatar
              className={cls.fullname}
              avatarPlaceholder={{
                color: "blue",
                content: `${elem.first_name[0]}${elem.last_name[0]}`,
              }}
              name={
                <strong>
                  {elem.first_name} {elem.last_name}
                </strong>
              }
              // sm
            />
          </>
        );
      },
      sorter: (a, b) => {
        a.first_name = a.first_name ? a.first_name : "";
        return a.first_name.localeCompare(b.first_name || "");
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "email",
      title: "EMAIL",
      dataIndex: "email",
      // width: 80,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },

    {
      key: "program",
      title: "PROGRAM",
      dataIndex: "fellowship_program_name",
      // width: 80,
      sorter: (a, b) =>
        a.fellowship_program_name.localeCompare(b.fellowship_program_name),
      sortDirections: ["descend", "ascend"],
    },

    {
      key: "location",
      title: "LOCATION",
      dataIndex: "location",
      // width: 80,
      render: (_: any, elem: userTableType, index: number) => {
        return (
          <>
            {elem.international
              ? `${elem.fellowship_city}, ${elem.fellowship_state}, ${elem.fellowship_country}`
              : `${elem.fellowship_city}, ${elem.fellowship_state}`}
          </>
        );
      },
      sorter: (a, b) => {
        a.first_name = a.first_name ? a.first_name : "";
        return a.first_name.localeCompare(b.first_name || "");
      },
      sortDirections: ["descend", "ascend"],
    },

    {
      key: "created_at",
      title: "ADDED",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      sortDirections: ["descend", "ascend"],
      render: (_: any, elem: userTableType, index: number) => {
        return elem.created_at ? dateFormat(strToDate(elem.created_at)) : "";
      },
    },
    {
      key: "updated_at",
      title: "UPDATED",
      dataIndex: "updated_at",
      sorter: (a, b) => {
        a.updated_at = a.updated_at ? a.updated_at : "";
        return a.updated_at.localeCompare(b.updated_at || "");
      },
      sortDirections: ["descend", "ascend"],
      render: (_: any, elem: userTableType, index: number) => {
        return elem.updated_at ? dateFormat(strToDate(elem.updated_at)) : "";
      },
    },
    
  ];

  // if (!authState.data.user.territory) {
  //   modalAddControls.handleOpen();
  // }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: any,
    sorter: any
  ) => {
    setCurrentPage(pagination.current);
  };

  let searchOptions = [
    { value: 0, label: "All" },
    { value: 1, label: "My territory" },
  ];
  // searchOptions = searchOptions.filter((option) => {
  //   let condition = option.value == "operation";
  //   return !condition;
  // });
  // searchOptions.unshift({ value: null, label: "All" });

  let searchOptionIdx = searchOptions.findIndex(
    (x) => x.value === searchOption
  );
  searchOptionIdx = searchOptionIdx < 0 ? 0 : searchOptionIdx;

  const handleSearchTxtChange = () => {};

  // const filterUsers = () => {
  //   setUsers(userState.data)
  // }
  // const filterUser = (page = 1) => {
  //   console.log("filter start");
  //   console.log("searchTxt", searchTxt);

  //   if (filteredUser.length > itemPerPage * page) {
  //     filteredUser = filteredUser.splice(0, itemPerPage * page);
  //   }
  //   console.log("fiter end", searchTxt, filteredUser);
  //   setCurrentPage(page);
  //   setUpdatedData(filteredUser);
  // };

  const handleScroll = async (e) => {
    const element = e.target;
    // if (element.scrollHeight - element.scrollTop != element.clientHeight) {
    if (e.target.scrollHeight - e.target.clientHeight == e.target.scrollTop) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setPage(page + 1);
      }, Math.random() * 2000 + 500);
    }
  };

  return (
    <ContainerVertical>
      {/* <WidgetContainer> */}
      <ScrollArea className={"scrollArea"} onScroll={handleScroll}>
        <PageTitle />
        <WidgetContainer>
          <p
            className={cls.alertMsg}
            // warning
            // rightEl={
            //   <button
            //     aria-label="Close"
            //     className="close"
            //     data-dismiss="alert"
            //     type="button"
            //   >
            //   </button>
            // }
          >
            Greenlight is a fellow tracking process for reps. By
            entering information on your fellows, you will be able to track
            their progress over time. This tool will help you follow fellows as
            they move toward their permanent facility and truly ensure that no
            fellow is left behind.
          </p>
          <TopBar style={{ direction: "ltr" }}>
            {/* <h1>Top Bar</h1> */}
            
            <div className={cls.btnGroup}>
            <CSVLink
                onClick={''}
                className={cls.yellowBtn}
                data={JSON.parse(JSON.stringify(fellowState.data)).map(temp=>{
                  //temp.territory = territory_options.find(o => o.value == temp.territory).label;
                  temp.territory = territory_options.find(o => o.value == temp.territory)?territory_options.find(o => o.value == temp.territory).label:'';
                  return temp;
                })}
                filename={'All-Fellows.csv'}
              >
                <Button className={cls.yellowBtn}>DOWNLOAD CSV</Button>
                
              </CSVLink>
              
            </div>
          </TopBar>
          {/* <Widget>{tableEl}</Widget> */}
          {/* <ScrollArea> */}
          
        </WidgetContainer>
      </ScrollArea>
      {/* </WidgetContainer> */}
    </ContainerVertical>
  );
};

export default withRouter(FellowsList);
