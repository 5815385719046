/* eslint-disable max-len */
import * as React from "react";
import { useDispatch } from "react-redux";

import { useRedux } from "@redux";
import { deleteUser } from "@redux/users/actions";

import { Modal, Button } from "components";

const ModalDeleteUser = ({
  handleClose,
  isOpen,
  itemId,
}: {
  handleClose: () => void;
  isOpen: boolean;
  itemId: string | number;
}) => {
  const userState = useRedux("users");
  const d = useDispatch();
  const handleDeleteUser = async () => {
    await d(deleteUser(itemId));
    handleClose();
  };

  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <h2>{`You are about to delete the user `}</h2>
      <p>This action cannot be reverted.</p>
      <div>
        <Button
          error
          isLoading={userState.isDeleting}
          onClick={handleDeleteUser}
        >
          Yes, I want to delete this user
        </Button>
        &nbsp;&nbsp;
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </Modal>
  );
};

export default ModalDeleteUser;
