import React from 'react';

import cls from './FellowDropButton.module.scss';
import { Dropdown, OpenStateControls, Avatar, AvatarProps } from '@duik/it';
import Icon from '../Icon';

export type IUserProfileButtonProps = React.PropsWithChildren<{
  displayName?: string;
  avatarProps?: AvatarProps;
}>;

type UserProfileButtonComponentProps = OpenStateControls &
  IUserProfileButtonProps;

const UserProfileButtonComponent = ({
  handleToggle,
  avatarProps = {},
}: UserProfileButtonComponentProps) => (
  <button onClick={handleToggle} className={cls.container}>
    <div className={cls.content}>
      <div className={cls.user}>
        {/*  <Avatar {...avatarProps} /> */}

        <i className="fas fa-ellipsis-h"></i>
      </div>
      <Icon prefix={'fas'} name={'caret-down'} />
    </div>
  </button>
);

type UserProfileButtonProps = React.ComponentProps<typeof Dropdown> &
  IUserProfileButtonProps;

export const UserProfileButton = (props: UserProfileButtonProps) => {
  const { displayName, avatarProps, ...rest } = props;
  return <Dropdown ButtonComponent={UserProfileButtonComponent} {...rest} />;
};

export default UserProfileButton;
