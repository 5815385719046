/**
 * This is just a raw example code with outside click handler
 * This should have better styling, menu positioning etc.
 */

import React from 'react';
import classnames from 'classnames';

import cls from './dropdown-menu.module.scss';

export type DropdownMenuProps = JSX.IntrinsicElements['div'] & {
  isOpen?: boolean;
  className?: string;
};

const DropdownMenu = ({ className, isOpen, children }: DropdownMenuProps) => (
  <div
    className={classnames(cls.defaultMenu, className, {
      [cls.menuOpen]: isOpen,
    })}
  >
    {children}
  </div>
);

export default DropdownMenu;
