/* eslint-disable max-len */
import * as React from "react";
import {
  NavLink as RRNavLink,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import { NavSectionTitle, NavLink, NavSection, Icon } from "components";

import BaseNavigationPanel from "../NavigationPanel";

import cls from "./navigation.module.scss";

type LinkObject = {
  text: string;
  to: string;
  icon: React.ReactNode;
  rightEl?: React.ReactNode;
  asLink?: boolean;
  count?: React.ReactNode;
};

const menuLinks: LinkObject[] = [
  {
    text: "Personal information",
    to: "/personal",
    icon: <Icon prefix={"far"} name={"id-badge"} />,
  },
  {
    text: "API Keys",
    to: "/apikeys",
    icon: <Icon prefix={"far"} name={"brackets-curly"} />,
  },
  {
    text: "Billing Portal",
    to: "/subscriptions",
    icon: <Icon prefix={"far"} name={"file-invoice-dollar"} />,
  },
  {
    text: "Upgrade/Add Plans",
    to: "/products",
    icon: <Icon prefix={"far"} name={"store"} />,
  },
];

const contestLinks: LinkObject[] = [
  {
    text: "Contests",
    to: "/contests-settings",
    icon: <Icon prefix={"far"} name={"th-large"} />,
    rightEl: "→",
    asLink: false,
  },
  {
    text: "Leagues",
    to: "/leagues-settings",
    icon: <Icon prefix={"far"} name={"th-large"} />,
    rightEl: "→",
    asLink: false,
  },
];

type NavigationProps = RouteComponentProps & {
  links?: Array<any>;
};

const Navigation = ({ match, location, links }: NavigationProps) => (
  <>
    <BaseNavigationPanel className={cls.nav} title="Settings" iconName="cog">
      {links.map((linkGroup) => (
        <NavSection key={linkGroup.title}>
          <NavSectionTitle>{linkGroup.title}</NavSectionTitle>
          {linkGroup.links.map(({ to, count, text, asLink, ...rest }) => (
            <NavLink
              key={to}
              Component={RRNavLink as any}
              onDark
              rightEl={
                count ? <span className={cls.navCount}>{count}</span> : null
              }
              to={`${match.url}${to}`}
              {...rest}
            >
              {text}
            </NavLink>
          ))}
        </NavSection>
      ))}
    </BaseNavigationPanel>
  </>
);

export default withRouter(Navigation);
