import colors from '../colors.module.scss';

const colorStatus: {
  [key: string]: string;
} = {
  active: colors.green,
  paused: colors.yellow,
  draft: colors.borderColor,
  disabled: colors.bgDark,
  'missing configuration': colors.red,
};

export const getColorByActionStatus = (code?: string) => {
  if (!code || !colorStatus[code]) {
    return null;
  }

  return colorStatus[code];
};

export default getColorByActionStatus;
