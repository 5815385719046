import URI from "urijs";
import fetch from "isomorphic-fetch";
import axios from "axios";

const ensureServerUrl = (
  serverUrl: string,
  input: string,
  query: object = {}
) => {
  if (typeof input !== "string" || URI(input).is("absolute")) {
    return input;
  }
  if (Object.keys(query).length === 0 && query.constructor === Object) {
    return URI(serverUrl + input)
      .normalize()
      .toString();
  } else {
    return URI(serverUrl + input)
      .addSearch(query)
      .normalize()
      .toString();
  }
};

const transformMethod = (args) => {
  let res = {
    ...args,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  console.log("Method: ", ["POST", "PUT"].indexOf(args.method));
  if (["POST", "PUT"].indexOf(args.method) === -1) {
    console.log("not a post method");
    res = {
      ...res,
      credentials: "include",
      headers: {
        ...res.headers,
      },
      body: JSON.stringify(args.body),
      jsonTransform:
        typeof args.jsonTransform === "undefined" ? true : args.jsonTransform,
    };
  } else {
    res = {
      ...res,
      withCredentials: true,
      body: args.body,
      header: {
        ...res.header,
      },
    };
  }
  console.log("~~~res: ", res);
  return res;
};
export const imageUpload = async <R = any>(
  url: string,
  data: any = {},
  apiEndpoint = process.env.REACT_APP_API_URL + "/"
): Promise<R> => {
  const urlTarget = ensureServerUrl(apiEndpoint, url);
  return axios({
    method: "POST",
    url: urlTarget,
    data: data,
    withCredentials: true,
  }).then((res: any) => {
    if (res.status >= 400) {
      return Promise.reject({ status: res.status, res });
    }
    return Promise.resolve(res);
  });
};
// Simple wrapper making isomorphic-fetch universal.
export const clientFetch = async <R = any>(
  url: string,
  options: any = {},
  apiEndpoint = process.env.REACT_APP_API_URL + "/"
): Promise<R> => {
  const urlTarget = ensureServerUrl(apiEndpoint, url, options.query);
  const optionsTransformed = transformMethod(options);
  return fetch(urlTarget, optionsTransformed).then((res) => {
    if (res.status >= 400) {
      if (optionsTransformed.jsonTransform) {
        return res
          .json()
          .catch((err) => Promise.reject({ status: res.status, res: err })) // eslint-disable-line
          .then((err) =>
            Promise.reject({
              // eslint-disable-line
              status: res.status,
              ...err,
            })
          );
      }
      return Promise.reject({ status: res.status, res });
    }

    if (optionsTransformed.jsonTransform) {
      return (
        res
          .json()
          // no JSON
          .catch(() => Promise.resolve())
      );
    }

    return Promise.resolve(res);
  });
};

export default clientFetch;
