// @flow
import * as React from "react";
import classnames from "classnames";
import { connect, useSelector } from "react-redux";
import { AppState } from "@redux";

/**
 * This input listens to reduxStore.form.errors
 * If the input has name property and
 * there is a property with key equal to the name in errors object
 * the error message will be displayed
 *
 * example
 *
 * name: "username"
 *
 * in errors object:
 * {
 *  username: 'Please fill in username',
 *  password: 'Please fill in your password'
 * }
 *
 * the component will render errorMessage  'Please fill in username'
 *
 */

import ContentTitle from "../ContentTitle";

import cls from "./input.module.scss";
import { ContentDesc } from "components";
import TextArea from "antd/lib/input/TextArea";

export type FieldsetTextarea = JSX.IntrinsicElements["textarea"] & {
  wrapperProps?: JSX.IntrinsicElements["div"];
  label?: React.ReactNode;
  desc?: React.ReactNode;
  name?: string;
  className?: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  clear?: boolean;
  errorMessage?: string;
  type?: string;
  isMasked?: boolean;
};

export const FieldsetTextarea = (props: FieldsetTextarea) => {
  const [hidden, setHidden] = React.useState<boolean>(true);

  const errors = useSelector(
    (state: AppState) => state.form && state.form.errors
  );

  const {
    className,
    wrapperProps,
    name,
    desc,
    icon,
    label,
    clear,
    iconPosition,
    errorMessage,
    isMasked,
    type: defaultType,
    onFocus: propOnFocus,
    onBlur: propOnBlur,

    // size
    ...rest
  } = props;

  const getReduxErrorMessage = () => {
    if (errors && errors[name]) {
      return errors[name];
    }

    return null;
  };

  const onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (typeof propOnFocus === "function") {
      propOnFocus(e);
    }
    setHidden(false);
  };

  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (typeof propOnBlur === "function") {
      propOnBlur(e);
    }
    setHidden(true);
  };

  const errMsg = errorMessage || getReduxErrorMessage();

  const type = isMasked && hidden ? "password" : defaultType;

  return (
    <div
      {...wrapperProps}
      className={classnames(wrapperProps.className, cls.formGroup, {
        [cls.clear]: clear,
      })}
    >
      {/* {label && <ContentTitle>{label}</ContentTitle>} */}
      {desc && (
        <ContentDesc>
          <p>{desc}</p>
        </ContentDesc>
      )}
      <div className={classnames(cls.inputWrapper)}>
        {icon && (
          <span
            className={classnames(cls.iconWrapper, {
              [cls.iconWrapperRight]: iconPosition === "right",
            })}
          >
            {icon}
          </span>
        )}
        <fieldset>
          {label && <label>{label}</label>}

          <textarea
            className={classnames(className, cls.input, {
              [cls.errorHighlight]: errorMessage,
            })}
            name={name}
            onBlur={onBlur}
            onFocus={onFocus}
            {...rest}
          ></textarea>
        </fieldset>
      </div>
      {errMsg && <p className={cls.errorMessage}>{errMsg}</p>}
    </div>
  );
};

FieldsetTextarea.defaultProps = {
  wrapperProps: {},
  label: null,
  desc: null,
  name: null,
  className: null,
  icon: null,
  clear: false,
  iconPosition: "left" as "left",
  errorMessage: null,
  type: "text",
};

export default FieldsetTextarea;
