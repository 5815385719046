import snackDefaultOptions from './snackDefaultOptions';
import { Snack, SnackInput } from './types';

export const snackAdd = (snack: SnackInput) => ({
  type: '@snack/ADD',
  payload: {
    ...snackDefaultOptions,
    ...snack,
  },
});

export const snackDelete = (id: Snack['id']) => ({
  type: '@snack/DELETE',
  payload: id,
});
