import * as React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { Link, RouteComponentProps } from "react-router-dom";
import { DropdownMenuPosition } from "@duik/it";
import clientFetch from "@redux/@thunker/clientFetch";
import { Button } from "components";
import {
  Modal,
  ContentLoader,
  SimpleListRow,
  FormInputGroup,
  Input,
  SelectSearch,
} from "components";

import { useRedux, useReduxLoading } from "@redux";
import { Formik, FormikProps } from "formik";
import { updateUserTerritory } from "@redux/auth/actions";
import { USER_ROLE } from "types/general";
import cls from "./itemAdd.module.scss";

import itemAddSchema from "./itemAdd";
import { result } from "lodash";

// @flow
type ModalAddNewLeagueProps = {
  handleClose: () => void;
  isOpen: Boolean;
};

type FormValues = {
  territory: number;
};

const ModalAddNewItem = ({ handleClose, isOpen }: ModalAddNewLeagueProps) => {
  const authState = useRedux("auth");
  const [isLoading, setIsLoading] = React.useState(false);
  const [selOption, setSelOption] = React.useState(0);
  const [itemOptions, setItemOptions] = React.useState([
    {
      value: 0,
      label: "Southen California",
    },
    {
      value: 1,
      label: "Nothern California",
    },
    {
      value: 2,
      label: "Easten California",
    },
    {
      value: 3,
      label: "Western California",
    },
  ]);
  const [itemKeyword, setItemKeyword] = React.useState("");
  const [searchItemResults, setSearchItemResults] = React.useState([]);

  const d = useDispatch();

  const initialValues: FormValues = {
    territory: 0,
  };

  const handleClickAddNewItem = async (data) => {
    console.log("~~~ data ~~~~", data);
    await d(updateUserTerritory(data));
    handleClose();
  };

  React.useEffect(() => {
    //Fetch territory list here later on
  }, []);

  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <h3>Select your territory </h3>
      <Formik
        initialValues={initialValues}
        validationSchema={itemAddSchema}
        onSubmit={handleClickAddNewItem}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setValues,
          handleBlur,
          setErrors,
        }: FormikProps<FormValues>) => {
          const onChangeItemKeyword = (e) => {
            setItemKeyword(e.target.value);
            let result =
              e.target.value == ""
                ? itemOptions
                : itemOptions.filter(
                    (item) =>
                      `${item.label}`
                        .toLowerCase()
                        .indexOf(`${itemKeyword}`.toLowerCase()) > -1
                  );
            setSearchItemResults(result);
          };
          return (
            <form onSubmit={handleSubmit}>
              <FormInputGroup>
                <FormInputGroup>
                  <label>Territories</label>
                  {isLoading ? (
                    <ContentLoader className={cls.smallLoader}></ContentLoader>
                  ) : (
                    <SelectSearch
                      menuPosition={DropdownMenuPosition["bottom-right"]}
                      placeholder={`Select territory`}
                      emptyMessage={`No available territories`}
                      inputSearchProps={{
                        value: itemKeyword,
                        onChange: onChangeItemKeyword,
                      }}
                      defaultOption={itemOptions[values.territory]}
                      activeOption={itemOptions[values.territory]}
                      options={
                        searchItemResults.length > 0
                          ? searchItemResults
                          : itemOptions
                      }
                      onChange={(option) => {
                        setValues({
                          ...values,
                          territory: option.value,
                        });
                      }}
                    />
                  )}
                </FormInputGroup>

                <Button isLoading={authState.isUpdating} primary type="submit">
                  UPDATE TERRITORY
                </Button>
              </FormInputGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModalAddNewItem;
