// @flow
import * as React from 'react';
import classnames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { AppState } from '@redux';

/**
 * This input listens to reduxStore.form.errors
 * If the input has name property and
 * there is a property with key equal to the name in errors object
 * the error message will be displayed
 *
 * example
 *
 * name: "username"
 *
 * in errors object:
 * {
 *  username: 'Please fill in username',
 *  password: 'Please fill in your password'
 * }
 *
 * the component will render errorMessage  'Please fill in username'
 *
 */

import ContentTitle from '../ContentTitle';

import cls from './input.module.scss';
import {ContentDesc} from "components";

export type InputProps = JSX.IntrinsicElements['input'] & {
  wrapperProps?: JSX.IntrinsicElements['div'];
  label?: React.ReactNode;
  desc?: React.ReactNode;
  name?: string;
  className?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  clear?: boolean;
  errorMessage?: string;
  type?: string;
  isMasked?: boolean;
};

export const Input = (props: InputProps) => {
  const [hidden, setHidden] = React.useState<boolean>(true);

  const errors = useSelector((state: AppState) => state.form && state.form.errors);

  const {
    className,
    wrapperProps,
    name,
    desc,
    icon,
    label,
    clear,
    iconPosition,
    errorMessage,
    isMasked,
    type: defaultType,
    onFocus: propOnFocus,
    onBlur: propOnBlur,

    // size
    ...rest
  } = props;

  const getReduxErrorMessage = () => {
    if (errors && errors[name]) {
      return errors[name];
    }

    return null;
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof propOnFocus === 'function') {
      propOnFocus(e);
    }
    setHidden(false);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof propOnBlur === 'function') {
      propOnBlur(e);
    }
    setHidden(true);
  };

  const errMsg = errorMessage || getReduxErrorMessage();

  const type = isMasked && hidden ? 'password' : defaultType;

  return (
    <div
      {...wrapperProps}
      className={classnames(wrapperProps.className, cls.formGroup, {
        [cls.clear]: clear,
      })}
    >
      {label && <ContentTitle>{label}</ContentTitle>}
      {desc && <ContentDesc><p>{desc}</p></ContentDesc>}
      <div className={classnames(cls.inputWrapper)}>
        {icon && (
          <span
            className={classnames(cls.iconWrapper, {
              [cls.iconWrapperRight]: iconPosition === 'right',
            })}
          >
            {icon}
          </span>
        )}
        <input
          autoComplete={type === 'password' ? 'off' : 'on'}
          className={classnames(className, cls.input, {
            [cls.errorHighlight]: errorMessage,
          })}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type}
          {...rest}
        />
      </div>
      {errMsg && <p className={cls.errorMessage}>{errMsg}</p>}
    </div>
  );
};

Input.defaultProps = {
  wrapperProps: {},
  label: null,
  desc: null,
  name: null,
  className: null,
  icon: null,
  clear: false,
  iconPosition: 'left' as 'left',
  errorMessage: null,
  type: 'text',
};

export default Input;
