// @flow
import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import * as authActions from "@redux/auth/actions";
import { WidgetContent, ContentLoader, Button } from "components";
import clientFetch from "@redux/@thunker/clientFetch";
import SimpleContainer from "../components/SimpleContainer";

type PageEmailConfirmationProps = RouteComponentProps<{
  token?: string;
}> & {
  emailConfirmation: Function;
};

const CONFIRMATION = {
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
};

const PageEmailConfirmation = ({
  history,
  match,
  emailConfirmation,
  match: {
    params: { token },
  },
}: PageEmailConfirmationProps) => {
  const [isConfirmed, setConfirmed] = React.useState<string>(
    CONFIRMATION.PENDING
  );

  React.useEffect(() => {
    setConfirmed(CONFIRMATION.PENDING);
    emailConfirmation(token)
      .then((res) => {
        setConfirmed(CONFIRMATION.SUCCESS);
        return res;
      })
      .catch((e) => {
        setConfirmed(CONFIRMATION.ERROR);
        return e;
      });
  }, []);

  return (
    <React.Fragment>
      <SimpleContainer>
        {isConfirmed === CONFIRMATION.PENDING && (
          <ContentLoader>Confirming your email</ContentLoader>
        )}
        {isConfirmed === CONFIRMATION.SUCCESS && (
          <WidgetContent>
            <h2>Successfully confirmed!</h2>
          </WidgetContent>
        )}
        {isConfirmed === CONFIRMATION.ERROR && (
          <WidgetContent>
            <h2>Ooops, something happened!</h2>
          </WidgetContent>
        )}
      </SimpleContainer>
    </React.Fragment>
  );
};

export default connect(null, {
  emailConfirmation: authActions.emailConfirmation,
})(PageEmailConfirmation);
