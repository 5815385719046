import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import {
  Select,
  Option,
  Button,
  Icon,
  ContentTitle,
  ContentDesc,
} from 'components';
import { MultiSelectProps } from '../MultiSelect';

import cls from './multiselect.module.scss';

export function reorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

const getItemStyle = (draggableStyle: React.CSSProperties) => ({
  borderRadius: 4,
  ...draggableStyle,
});

export type MutliSelectDraggableOrderProps<BC, MC> = {
  handleOrderChange: (
    item: {
      value: string;
      from: number;
      to: number;
    },
    newOrderedArray: Option[],
  ) => void;
} & MultiSelectProps<BC, MC>;

export function MutliSelectDraggableOrder<BC, MC>({
  activeOptions: ao,
  handleOptionChange = () => {},
  handleOptionRemove = index => {},
  options,
  placeholder,
  label,
  disabled,
  OptionComponent,
  isLoading,
  selectProps,
  handleOrderChange,
  SelectComponent = Select,
  description,
  newOptionText,
}: MutliSelectDraggableOrderProps<BC, MC>) {
  const activeOptions = ao || [];
  const [isEmptyDisplayed, setIsEmptyDisplayed] = React.useState(
    activeOptions.length === 0,
  );
  const [length, setLength] = React.useState(activeOptions.length);

  React.useEffect(() => {
    if (length < activeOptions.length) {
      // add new is not displayed if length of active options has increased
      // new has been added, we don't want to display add new option again
      setIsEmptyDisplayed(activeOptions.length < 1);
      setLength(activeOptions.length);
    }
  }, [activeOptions.length]);

  const onClickAddNew = () => {
    setIsEmptyDisplayed(true);
  };

  const onClickHideNew = () => {
    setIsEmptyDisplayed(false);
  };

  let newOptionDisplay = 'Add new option'
  if(newOptionText && newOptionText != ''){
    newOptionDisplay = newOptionText
  }
  const shouldRenderEmpty =
    (options.length > 0 && isEmptyDisplayed) || activeOptions.length < 1;

  const onDragEnd = (result: DropResult) => {
    if (typeof handleOrderChange === 'function' && result.destination) {
      handleOrderChange(
        {
          value: result.draggableId,
          from: result.source.index,
          to: result.destination.index,
        },
        reorder(activeOptions, result.source.index, result.destination.index),
      );
    }
  };

  return (
    <div className={cls.wrapper}>
      {label && <ContentTitle>{label}</ContentTitle>}

      {description && <ContentDesc>{description}</ContentDesc>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {providedDroppable => (
            <div ref={providedDroppable.innerRef}>
              {activeOptions.map((item, index) => (
                <Draggable
                  key={item.value}
                  draggableId={`${item.value}`}
                  index={index}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={cls.row}
                      style={getItemStyle(provided.draggableProps.style)}
                    >
                      <SelectComponent
                        activeOption={item}
                        buttonProps={{
                          // transparent: true,
                          disabled,
                        }}
                        className={cls.selectWrapper}
                        onChange={option => handleOptionChange(index, option)}
                        onOutsideScroll={false}
                        OptionComponent={OptionComponent}
                        options={options}
                        {...selectProps}
                      />
                      <div className={cls.addRemoveWrapper}>
                        <Icon prefix={'far'} name={'line-height'}/>
                      </div>
                      <div>
                        <Button
                          clear
                          disabled={disabled}
                          icon={
                            <Icon prefix={'far'} name={'times'}  />
                          }
                          iconOnly
                          onClick={() => handleOptionRemove(index)}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {shouldRenderEmpty && (
        <div className={cls.row}>
          <SelectComponent
            activeOption={null}
            buttonProps={{
              // transparent: true,
              disabled,
            }}
            className={cls.selectWrapper}
            onChange={option =>
              handleOptionChange(activeOptions.length, option)
            }
            OptionComponent={OptionComponent}
            options={options}
            placeholder={placeholder}
            {...selectProps}
          />
          <div className={cls.addRemoveWrapper}>
            <Button
              clear
              disabled={disabled}
              icon={<Icon prefix={'far'} name={'times'} />}
              iconOnly
              onClick={() => onClickHideNew()}
            />
          </div>
        </div>
      )}
      {!shouldRenderEmpty && options.length > 0 && (
        <Button clear onClick={() => onClickAddNew()}>
          {newOptionDisplay}
        </Button>
      )}
    </div>
  );
}

MutliSelectDraggableOrder.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
};

export default MutliSelectDraggableOrder;
