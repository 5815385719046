import React from 'react';

type TextHighlightProps = {
  children?: string;
  highlighted?: string;
};

export const TextHighlight = (props: TextHighlightProps) => {
  const { children, highlighted } = props;
  if (!children) {
    return null;
  }

  if (!highlighted) {
    return <>{children}</>;
  }

  const res = children.split(highlighted);

  const higlightedRes = res.map((item, index) => (
    <React.Fragment key={item}>
      {item}
      {index < res.length - 1 && <mark>{highlighted}</mark>}
    </React.Fragment>
  ));

  return <>{higlightedRes}</>;
};
