import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
  firstname: Yup.string()
    .max(50, "Too Long!")
    .required("First name is required"),
  lastname: Yup.string().max(50, "Too Long!").required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address!")
    .required("Email address is required"),
  agree_terms: Yup.boolean().oneOf([true], "Must accept terms"),
  password: Yup.string()
    .required("Password is required")
    .min(
      8,
      "Use 8 or more characters with a mix of letters, numbers & symbols."
    )
    .matches(
      /([(?=.*?[a-z])(?=.*?[0-9])/,
      "Use 8 or more characters with a mix of letters, numbers & symbols."
    ),
  passwordconfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match!")
    .required("Password confirm is required"),
});

export default SignupSchema;
