import * as Yup from "yup";

const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .matches(/^[aA-zZ]+$/, "Only characters accepted")
    .required("First name is required"),
  last_name: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .matches(/^[aA-zZ ]+$/, "Only characters and spaces accepted")
    .required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address!")
    .required("Email is required."),
  phone: Yup.string().trim().required("Phone number is required."),
  fellowship_program_name: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("Fellowship program name is required"),
  fellowship_city: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("Fellowship city is required"),
  fellowship_state: Yup.string()
    .max(50, "Too Long!")
    .trim()
    .required("Fellowship state is required"),

  international: Yup.boolean(),
  fellowship_country: Yup.string().when("international", {
    is: false,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required("Fellowship country is required"),
  }),
});

export default PersonalInfoSchema;
